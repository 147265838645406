<app-breadcrumb [labels]=breadcrumb fullBC="{{true}}"></app-breadcrumb>
<div class="card-details-container">
    <div class="card-details-wrapper flex-compliance">
        <div class="card-wrapper">
            <nz-card class="compliance-card help-card" [nzBordered]="false">
                <div class="details-card-wrapper">
                    <div class="title">Pre-flight Testing</div>
                    <div class="description">
                        <div class="secBody">
                            <div style="margin: 0 0 0.7rem 0;">To make it easier for you to navigate through our platform, we have divided the ‘Pre-flight testing’ section into two parts: </div>
                            <ol type="1">
                                <li>Evaluating Ads without Campaign information</li>
                                <li>Creating Campaigns and evaluating Ads within the Campaign</li>
                            </ol>
                        </div>    
                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem">Part 1: Evaluating Ads</div>
                            <div class="description">
                                <div class="secBody">
                                    <div>
                                    <ul style="list-style-type:none;">
                                       <li>- As soon as you login, you land on the <b>“Pre-flight”</b> page</li>
                                       <li>- Top right corner has 2 options: <b>Create campaign</b> and <b>Upload creatives</b></li>
                                       <li>- Click on <b>“Upload creatives”</b></li>
                                       <li>- The following information is required to fill in:</li>
                                        <ul style="list-style-type:circle;">
                                            <li>Creative name (mandatory field)</li>
                                            <li>Creative type (mandatory field): Creative types can be either Video Ad or Display Ad.</li>
                                            <li>Select brand (mandatory field): You can select only those Brands that you have access to. The brand selected for the Ad evaluation ensures that the Brand Guidelines are applied for the analysis.</li>
                                            <li>Drag and drop the file or browse files on your computer to select an Ad file to analyze.</li>
                                        </ul>
                                        <li>- Click on <b>“Upload and analyze”</b> so the file is uploaded and put into analysis</li>
                                        <li>- Click on<b> Upload:</b> if you wish to run the analysis later</li>
                                        <li>- Click on<b> Cancel:</b> if you uploaded a wrong file and entered wrong information</li>
                                        </ul>
                                        <b>Please note:</b> The creatives uploaded by you are visible only to you and not to any other team member who has access to the Incivus platform. If you wish to share the creatives and its report with other team members, we recommend creating a campaign and uploading Ads within the campaign for analysis. Campaigns can be shared with other team members who have access to the brands mapped to the creative uploaded for analysis.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem">Part 2: Creating a campaign folder</div>
                            <div class="description">
                                <div class="secBody">
                                    <div>
                                    <ul style="list-style-type:none;">
                                       <li>- As soon as you login, you land on the <b>“Pre-flight”</b> page</li>
                                       <li>- Top right corner has 2 options: <b>Create campaign</b> and <b>Upload creatives</b></li>
                                       <li>- Click on <b>“Create campaign”</b></li>
                                       <li>- Provide the following mandatory information</li>
                                        <ul style="list-style-type:circle;">
                                            <li><b>Campaign name:</b> Ensure there are no special characters in the name you provide. </li>
                                            <li><b>Brand:</b> You can select the brand which you have access to. The Ads uploaded in that Campaign will be mapped to this selected Brand Guidelines. </li>
                                        </ul>
                                        <li>- Click on <b>“create”</b> to create the campaign</li>
                                        <li>- Click on<b> "cancel"</b> to cancel creating this new campaign </li>
                                        <li>- Sharing campaign </li>
                                        <ul style="list-style-type:circle;">
                                        <li>As the author of the campaign, you can share the campaign with other users of your organization who have access to the Brand for which the Campaign folder is created </li>
                                        <li>As the author of the campaign, you can delete the campaign folder if it is empty. If it is not empty, you will not be able to delete the campaign folder</li>
                                        </ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="title">Supported Creatives</div>
                        <br>
                            <div class="secBody">
                            <div class="helpSec">
                                <table>
                                    <tr>
                                        <td class="table-data">Supported image files</td>
                                        <td class="table-data">.jpg , .jpeg, .png</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Supported video files</td>
                                        <td class="table-data">.mp4, .avi, .wmv, .ogg, .webm, .mov</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Max. number of Video Ad files to be uploaded at one time</td>
                                        <td class="table-data">1 file at a time</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Max. number of Video Ads that can be analyzed</td>
                                        <td class="table-data">Configured as part of account setup</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Max. number of Display Ad (Image) files to be uploaded at one time</td>
                                        <td class="table-data">There is no max limit but it is advisable to upload 2 files at a time</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Max. number of Display Ads (Image) that can be analyzed</td>
                                        <td class="table-data">Configured as part of account setup</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Max. file size for Video Ads</td>
                                        <td class="table-data">500 MB</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Max. file size for Display Ads (Images)</td>
                                        <td class="table-data">20 MB</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Min. width and Height</td>
                                        <td class="table-data">Images : 250X250px | Videos: 1200X1200px</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Video Ad length</td>
                                        <td class="table-data">Between 15 seconds to 1.5 minutes</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Video frame rate</td>
                                        <td class="table-data">2 fps</td>
                                    </tr>
                                    <tr>
                                        <td class="table-data">Filename specifications</td>
                                        <td class="table-data">Avoid special characters, including language specific characters in filenames.</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem">A/B Testing</div>
                            <div class="description">
                                <div class="secBody">
                                    <div>
                                    <p>
                                        So, you want to discover what truly works for your audience since you need data as evidence before you make a final decision. One of the most common methods of gathering this data is through A/B testing. 
                                        A/B testing, at its most basic, is a way to compare two versions of something to figure out which performs better. In our case it's about comparing 2 versions or sub-edits of an Ad to see which one will capture your audience’s attention and deliver for your marketing objectives.
                                    </p>
                                    <p>
                                        A/B testing feature permissions are required on the Incivus Platform so you can test 2 versions of an Ad. If you do not see “A/B Testing” as a menu item in the left-hand navigation, you do not have permission to use this feature. Please reach out to your system admin or write to Incivus at <a href="mailTo:'support@incivus.ai'"> support@incivus.ai </a>for further assistance. 
                                    </p>
                                    <p>Note: A/B testing is available only for pre-flight Ads. </p>
                                    <p>Steps to using the A/B Testing feature:</p>
                                    <ul style="list-style-type:none;">
                                       <li>- Pre-requisite to A/B Testing is to have the 2 versions of the Ad already analyzed on the platform </li>
                                       <li>- Click on <b>“A/B Testing”</b> on the left-hand navigation </li>
                                       <li>- On the landing page of A/B Testing, you can filter the Ads displayed based on the type of the Ad. Select display ad or video ads from the filter to compare </li>
                                       <li>- You can select the Ads displayed and then hit the <b>“Compare”</b> button on the top-right corner </li>
                                       <li>- The metrics are displayed in a tabular format for the selected Ads </li>
                                       <li>- Click on <b>“save”</b> on the top-right corner to save this A/B test report </li>
                                       <li>- All saved reports are listed in a tabular format in the A/B testing landing page. You can refer to them any time to make go/no-go decisions  </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</div>