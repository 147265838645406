import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CONSTANTS } from '../common/constants';
import { StorageService } from './storage.service';

const AUTH_API = '/api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  ROLES = CONSTANTS.ROLES;
  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'auth/signin',
      {
        username,
        password,
      },
      httpOptions
    );
  }
  redirectIfLoggedIn() {
    let userObj = this.storageService.getUser();
    if (userObj) {
      this.goToNextPage(userObj);
    }
  }

  goToNextPage(userObj: any) {
    let fa = userObj.client?.featureAccess;
    console.log('goToNextPage', userObj);

    if (userObj.roles.includes('INCIVUS_ADMIN')) {
      this.router.navigate(['client', 'list']);
    } else if (userObj.roles.includes('SERVICE_MANAGER')) {
      console.log('service manager login');
      this.router.navigate(['settings', 'service-requests']);
    } else if (userObj.roles.includes('CL_ADMIN')) {
      this.router.navigate(['client', 'company']);
    } else if (userObj.roles.includes(this.ROLES.TRIAL_USER)) {
      console.log(
        'trial user',
        fa,
        fa?.preFlight?.isAvailable ||
          fa?.imageAd?.isAvailable ||
          fa?.videoAd?.isAvailable
      );

      // user
      if (
        fa?.preFlight?.isAvailable ||
        fa?.imageAd?.isAvailable ||
        fa?.videoAd?.isAvailable
      )
        this.router.navigate(['creatives', 'pre-flight', 'list']);
      else if (fa?.inFlight?.isAvailable)
        this.router.navigate(['creatives', 'in-flight', 'list']);
      else if (fa?.postFlight?.isAvailable)
        this.router.navigate(['creatives', 'post-flight', 'list']);
      else if (fa?.serviceRequest)
        this.router.navigate(['client', 'service-requests', 'all']);
      else this.router.navigate(['compare', 'creative']);
    } else {
      // user
      if (userObj.permission.isUpload)
        this.router.navigate(['creatives', 'pre-flight', 'list']);
      else if (
        userObj.permission.isInFlight &&
        userObj.client?.featureAccess?.inFlight?.isAvailable
      )
        this.router.navigate(['creatives', 'in-flight', 'list']);
      else if (
        userObj.permission.isPostFlight &&
        userObj.client?.featureAccess?.postFlight?.isAvailable
      )
        this.router.navigate(['creatives', 'post-flight', 'list']);
      else if (userObj.permission.serviceRequest && fa.serviceRequest)
        this.router.navigate(['client', 'service-requests', 'all']);
      else this.router.navigate(['compare', 'creative']);
    }
  }
}
