import { Location } from "@angular/common";
import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { StorageService } from "src/app/_services/storage.service";
import { AppServices } from "src/app/_services/app.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomValidation } from "src/app/_services/custom-validator.service";
import { capitalizeFirstLetter } from "src/app/_helpers/utils";

@Component({
  selector: "app-add-workbench",
  templateUrl: "./add-workbench.component.html",
  styleUrls: ["./add-workbench.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class AddWorkbenchComponent implements OnInit {
  errorMsg =
    "Any relevant info./Guideline/ to be conveyed to the user when creating a new benchmark.";
  error: any = null;
  clientError: any = null;
  breadcrumb: any = [
    {
      name: "Settings",
      link: null,
    },
    {
      name: "Workbench",
      link: null,
    },
  ];
  checked = true;
  page: number = 1;
  disabled: boolean = true;
  formOptions: any = {
    dataset: ["benchmarks-dataset"],
  };
  id: any = null;
  industry = null;
  client: any = null;
  clientList: any[] = [];
  allClientList: any[] = [];
  showCompanyModal = false;
  benchmarkType = "";

  form: FormGroup = new FormGroup({
    client: new FormControl("", Validators.required),
    
    benchmarkType: new FormControl("", Validators.required),
    status: new FormControl("", Validators.required),
    industry: new FormControl(
      "Transportation and Logistics",
      Validators.required
    ),
    category: new FormControl(""),
    companies: new FormControl([], Validators.required),
    creativeTypes: new FormControl([], Validators.required),
    description: new FormControl("", [
      CustomValidation.wordCountValidator(250),
    ]),
    datasets: new FormControl([], Validators.required),
    refreshSchedule: new FormControl("", Validators.required),
    calculatePercentile: new FormControl(false),
  });

  constructor(
    private storageService: StorageService,
    private location: Location,
    private modal: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppServices
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnInit(): void {
    // fetch the list of companies
    this.allClientList = this.storageService.getClientList() ?? [];
    console.log(
      "add-workbench.component.ts ngOnInit clientList:",
      this.clientList
    );
    this.observeBenchmarkTypeChange();
    this.observeCategoryChange();
    // This is because the same component is used in edit and create mode
    this.checkForDraftForm();
  }

  checkForDraftForm() {
    // Subscribe to the route query parameters
    this.route.params.subscribe((params) => {
      console.log(
        "add-workbench.component.ts [a4dde4] checkForDraftForm: ",
        params
      );
      // Check if data parameter exists and open in edit mode
      if (params["id"]) {
        this.id = params["id"];
        this.fetchWorkbench();
      } else {
        // Else, show Modal to select the client
        this.showCompanyModal = this.client == null;
      }
    });
  }

  fetchWorkbench() {
    this.appService.getBenchmark(this.id).subscribe((response: any) => {
      // the client is actually
      this.form.patchValue({
        ...response,
        status: response?.status?.replace("draft-", ""),
      });
      this.onCompanySelected();
    });
  }

  observeBenchmarkTypeChange() {
    this.form.get("benchmarkType")?.valueChanges.subscribe((data: any) => {
      console.log(
        "add-workbench.component.ts [be007d] observeBenchmarkType: ",
        data
      );
      this.benchmarkType = data;
      // Clear Validations of Category
      let categoryControl = this.form.get("category");
      // clear existing validations
      categoryControl?.clearValidators();
      // add required if
      if ("category" == data)
        categoryControl?.addValidators([Validators.required]);
      categoryControl?.updateValueAndValidity();
      this.form.patchValue({ category: "" });
      if (this.benchmarkType == "category") {
        this.clientList = [];
      }
    });
  }
  observeCategoryChange() {
    this.form.get("category")?.valueChanges.subscribe((data: any) => {
      console.log(
        "add-workbench.component.ts [be007d] observeCategoryChange: ",
        data
      );
      this.benchmarkType = this.form.get("benchmarkType")?.value;
      let category = data;

      if (this.benchmarkType == "category") {
        this.clientList = this.allClientList.filter((item) =>
          item.categories?.includes(category)
        );
      }
    });
  }

  onCreateBenchmarkClick() {
    console.log(
      "add-workbench.component.ts onCreateBenchmarkClick form:",
      this.form.value
    );
  }

  submitForm() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.uploadData("Completed"); // status of edit mode
    }
  }
  saveProgress() {
    this.uploadData("Draft");
  }

  cancel() {
    this.location.back();
  }
  onCompanySelected() {
    this.client = this.form.get("client")?.value;

    console.log("add-workbench.component.ts onCompanySelected() ", this.client);
    // check if client is not null - ideally, it always will be present.
    if (this.client) {
      // check if industry has been set for the client .
      if (!this.client.industry) {
        this.clientError = {
          type: "error",
          message: "Please set the industry in Customer Setup first.",
        };
        return;
      }
      // add breadcrumb
      this.breadcrumb.push({ link: null, name: this.client?.companyName });
      // set the industry
      this.industry = this.client.industry;
      this.clientList = this.allClientList.filter(
        (item) => item.industry == this.industry
      );
      this.form.patchValue({ industry: this.industry });
      this.showCompanyModal = false;
    } else {
      this.clientError = {
        type: "error",
        message: "Error selecting the client. Please try again.",
      };
    }
  }
  nextPage() {
    console.log(
      "add-workbench.component.ts nextPage() ",
      this.form.controls["category"].value
    );
    this.error = null;
    // check if page 1 fields are filled
    //
    if (
      !this.form.controls["benchmarkType"].value ||
      !this.form.controls["status"].value ||
      ("category" == this.form.controls["benchmarkType"].value &&
        !this.form.controls["category"].value) ||
      this.form.controls["creativeTypes"].value?.length == 0
    ) {
      this.form.controls["benchmarkType"].markAsDirty();
      this.form.controls["status"].markAsDirty();
      this.form.controls["category"].markAsDirty();
      this.form.controls["creativeTypes"].markAsDirty();
      this.error = {
        type: "error",
        message:
          "Oops! You might have left one or more mandatory fields empty. Please cross check once and add the necessary information.",
      };
      return;
    }
    if (this.page == 1) this.page++;
  }
  getValidateStatus(control: AbstractControl): "error" | "success" | "" {
    return control && control.invalid && (control.touched || control.dirty)
      ? "error"
      : "success";
  }

  getErrorTip(control: AbstractControl): string {
    return control && control.invalid && (control.touched || control.dirty)
      ? "Uh! Oh! This is a mandatory field. Please provide a value."
      : "";
  }
  prvsPage() {
    console.log("add-workbench.component.ts prvsPage() ");
    // check if page 1 fields are filled
    if (this.form.controls["benchmarkType"]) if (this.page == 2) this.page--;
  }

  // Save Data to the database
  uploadData(saveMode: string) {
    let data: any = this.form.value;
    if ("Draft" == saveMode) data.status = "draft-" + data.status;

    data.clientId = data.client?.id;
    // companyName is passed because the benchmarking middleware maps through companyName instead of clientId
    data.companyName = data.client?.companyName; 
    console.log("add-workbench.component.ts [699eab]: ", data);

    if (this.id == null) {
      this.appService.postBenchmark(data).subscribe(
        (response: any) => {
          console.log(
            "add-workbench.component.ts [8806ba] postBenchmark: ",
            data,
            response
          );
          // If not Draft mode, then redirect to
          if (saveMode != "Draft") {
            this.modal.success({
              nzTitle: "Success",
              nzContent:
                capitalizeFirstLetter(data.benchmarkType) +
                " benchmark created successfully. All ads analysed from here on will have the latest benchmark data.",
              nzMaskClosable: false,
              nzKeyboard: false,
              nzOnOk: () => {
                this.router.navigate(["client", "workbench"]);
              },
            });
          } else {
            this.modal.success({
              nzTitle: "Success",
              nzContent: "Information saved succesfully.",
              nzMaskClosable: false,
              nzKeyboard: false,
              nzOnOk: () => {
                // if (this.id == null)
                this.router.navigate([
                  "client",
                  "workbench",
                  response?.id,
                  "edit",
                ]);
              },
              nzOnCancel: () => {},
            });
          }
        },
        (error) => {
          console.error("add-workbench.component.ts [75f191] : ", error);
          this.modal.error({
            nzTitle: "Error",
            nzContent: "Benchmark creation Failed: " + error.message,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => {},
          });
        }
      );
    } else {
      this.appService.putBenchmark(this.id, data).subscribe({
        next: (response: any) => {
          console.log("add-workbench.component.ts [8cb18e] next: ", response);
        },
        error: (err: any) => {
          console.error(
            "add-workbench.component.ts [76f74a] putBenchmark error: ",
            err
          );
          this.modal.error({
            nzTitle: "Error",
            nzContent: "Updating benchmark failed: " + err?.message,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => {},
          });
        },
      });
    }
  }

  getErrorMessage(controlName: string) {
    const control = this.form.get(controlName);
    if (control && control.errors) {
      const errors = control.errors;

      const firstKey = Object.keys(errors)[0];

      if (typeof errors[firstKey] == "string")
        return errors[firstKey] as string;
      if (firstKey == "required")
        return `Uh! Oh! This is a mandatory field. Please provide a value.`;
      if (firstKey == "wordCountExceeded")
        return `Uh! Oh! Only 250 words are allowed in this field. Please check and enter again.`;
      return firstKey;
      // Add more error handling logic here if needed
    }
    return "";
    // Uh! Oh! Only 250 words are allowed in this field. Please check and enter again.
  }
}
