import { Component, Input } from '@angular/core';
import { StorageService } from 'src/app/_services/storage.service';


@Component({
  selector: 'app-digital-accessibility',
  templateUrl: './digital-accessibility.component.html',
  styleUrls: ['./digital-accessibility.component.less']
})
export class DigitalAccessibilityComponent {
  @Input() public data: any;
  isTrialUser: boolean = false;
  isServiceManager: boolean = false;
  optimizedForColor: string = "";
  optimizedForSound: string = "";
  showDigital: boolean = true;
  checkOptimizedForsound=true;
  isVideoReport:boolean=false;

  constructor(private storage: StorageService) {
    this.data = {}
  }
  

  ngOnInit(): void {
    let user = this.storage.getUser();
     this.isTrialUser = user.roles.includes('TRIAL_USER');
     this.isServiceManager = user.roles.includes('SERVICE_MANAGER');
      this.optimizedForColor = this.data.data.optimizedForColor;
      this.optimizedForSound= this.data.data.optimizedForSound;
      this.isVideoReport= this.data.isVideoReport;
      if(this.optimizedForSound==null){
        this.checkOptimizedForsound=false;
      }
      if((this.isTrialUser || this.isServiceManager) && this.data.data.adCopyVsBgScore==null)
        {
          this.optimizedForColor="No";
        }
  }

  switchBtnClick = () => {
    this.showDigital = !this.showDigital;
  }

}
