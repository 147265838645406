<app-breadcrumb [labels]=breadcrumb fullBC="{{true}}"></app-breadcrumb>

<section>
    <div class="container mt-4">
        <div class="row justify-content-center">
            <div class="col-md-12">

                <div class="w-100 p-3 rounded-3 shadow shadow-lg bg-white">

                    <button
                        class="btn mb-3 btn-light arrowBtn py-3 rounded-4 d-flex align-items-center justify-content-between w-100">
                        <span>Rule Details</span>
                    </button>

                    <div class="mb-4">
                        <table>
                            <tr>
                                <th style="text-align: left ; width: 50%;">Rule Name</th>
                                <td style="text-align: left;">{{apiResponse2?.rule?.name}}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left;">Brand</th>
                                <td style="text-align: left;">{{apiResponse2?.rule?.brandNames?.join(",")}}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left;">Channel</th>
                                <td style="text-align: left;">{{apiResponse2?.rule?.channels?.join(",")}}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left;">Creative Type</th>
                                <td style="text-align: left;">{{apiResponse2?.rule?.creativeTypes}}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="w-100">
                        <div class="w-100 mb-3 d-flex ">
                            <a *ngIf="isDisplayAvailable" [routerLink]="['/custom-rules/show/'+ruleId+'/DISPLAY']"
                                type="button" [ngClass]="{'btnBlue': apiResponse.creativeType === 'DISPLAY'}"
                                class="btn arrowBtn mr-4">Display
                                Ads</a>
                            <a *ngIf="isVideoAvailable" [routerLink]="['/custom-rules/show/'+ruleId+'/VIDEO']"
                                type="button" [ngClass]="{'btnBlue': apiResponse.creativeType === 'VIDEO'}"
                                class="btn arrowBtn ">Video
                                Ads</a>
                        </div>
                        <button
                            class="btn mb-3 btn-light arrowBtn py-3 rounded-4 d-flex align-items-center justify-content-between w-100">
                            <span>Ranges</span>
                        </button>
                        <div *ngFor="let rscore of apiResponse.customRuleRangesResponse; let i = index"
                            class="w-100 mb-4 rangeform">



                            <h4 class="subHeading mb-3">{{ rscore.name | titlecase }}</h4>
                            <div class="w-100">

                                <div *ngFor="let level of rscore.customRuleRangeScores; let j = index"
                                    class="row align-items-center">
                                    <div class="col-md-3">
                                        <label for="" class="mb">{{ level.name| titlecase }} range</label>
                                    </div>
                                    <div class="col-md-9">

                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label for="">From</label>

                                                    <div>
                                                        <div class="border p-2 bg-light form-control disabled"
                                                            disabled="disabled">
                                                            {{level.from}}
                                                        </div>

                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-6">
                                                    <label for="">Condition</label>

                                                    <div class="w-100 p-2 form-control disabled bg-light border">
                                                        {{level.condition}}
                                                    </div>

                                                </div> -->
                                                <div class="col-md-6">


                                                    <div>
                                                        <label for="">To</label>
                                                        <div class="border p-2 form-control disabled bg-light">
                                                            {{level.to}}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="w-100 ">
                                    <button
                                        class="btn mb-3 btn-light arrowBtn py-3 rounded-4 d-flex align-items-center justify-content-between w-100">
                                        <span>Weights</span>

                                    </button>

                                    <div *ngFor="let weight of customRuleWeightsResponse" class="w-100 mb-4 rangeform">
                                        <h4 class="subHeading mb-3">{{weight.name}}</h4>
                                        <div class="w-100 bg-info-alert p-1 rolecreatealert">

                                            {{ weight.name }} =
                                            <span *ngFor="let score of weight.scores; let i = index">
                                                {{ score.name }} {{score?.weight}}
                                                <small *ngIf="i < weight.scores.length - 1"> + </small>
                                            </span>

                                        </div>
                                        <div class="w-100">
                                            <div class="row">
                                                <div *ngFor="let wt of weight.scores" class="col-md-4">
                                                    <label for="">{{wt.name}}</label>
                                                    <input type="text" [(ngModel)]="wt.weight" readonly
                                                        class="form-control">

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="w-100 mt-5">
                            <div class="d-flex justify-content-between">
                                <span></span>
                                <!-- <button (click)="onRuleCreate()" 
                                [disabled]="isFormInvalid || form.invalid"
                                    class="btn btn-primary" style="background-color: #38B3E4;">Save</button> -->
                                <button (click)="goBack()" class="btn btn-primary"
                                    style="background-color: #38B3E4; border-color: #39B3EA;">Ok</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</section>