<div id="chart" *ngIf="loaded" >
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [xaxis]="chartOptions.xaxis!"
    [stroke]="chartOptions.stroke!"
    [colors]="chartOptions.colors!"
    [dataLabels]="chartOptions.dataLabels!"
    [legend]="chartOptions.legend!"
    [markers]="chartOptions.markers!"
    [grid]="chartOptions.grid!"
    [yaxis]="chartOptions.yaxis!"
    [title]="chartOptions.title!"
    [tooltip]="chartOptions.tooltip!"
    [plotOptions]="chartOptions.plotOptions!"
  ></apx-chart>
</div>