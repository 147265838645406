<div class="modal-wrapper">
         <div class="loader-overlay" *ngIf="isLoading">
        <div class="loader-spinner">
          <nz-spin nzSimple></nz-spin> 
        </div>
       </div>
   
    <app-alert [message]="errorMsg" [alertType]="'error'" *ngIf="errorMsg"></app-alert>
    <app-upload [myCallback]="getFile" [fileAccept]="fileAccept" [fileType]="fileType"
        [channelsList]="channel"
        (creativeNameChange)="updateCreativeName($event)"
        (creativeChannelChange)="updateCreativeChannel($event)">
    </app-upload>
    
    <div class="form-footer">
        <div class="form-footer-wrapper">
            <app-button buttonClass="tertiary" label="Cancel" [myCallback]="cancel"></app-button>
            <app-button buttonClass="secondary" *ngIf="isAnalyze" label="Upload and analyze"
                [myCallback]="analyze"></app-button>
            <app-button buttonClass="primary" label="Upload" [myCallback]="upload"></app-button>
        </div>
    </div>
</div>