<div class="trial-signup-page">
  <div class="sidebar" style="position: sticky;overflow-y: hidden;">
    <!-- <img
      style="rotate: 180deg"
      class="pattern"
      src="assets/trial-design.svg"
      alt=""
      srcset=""
    /> -->
    <img
      style="width: 100%; height: 135vh;"
      src="assets/opt_1.jpg"
      alt=""
      srcset=""
    />
    <!-- <img class="pattern" src="assets/trial-design.svg" alt="" srcset="" />
    <div class="logo-wrapper">
      <img class="logo" src="assets/icons/incivus-logo.svg" />
    </div> -->
  </div>

  <div class="content">
    <!-- <div class="logo-container">
      <img src="assets/incivus new logo.svg" class="logoPH" />
      <div class="login-text">Welcome to Incivus!</div>
      <div class="login-text">
        Find your most effective ad quickly and easily.
      </div>
      <div class="login-text">Let's get your free trial started!</div>
    </div> -->

    <div class="logo-container">
      <img src="assets/incivus new logo.svg" class="logoPH" />
      <div class="login-text">Welcome to Incivus!</div>
      <div class="login-text" style="display: flex; justify-content: center; align-items: center; width: 100%; position: relative;">
        <span style="text-align: center; flex-grow: 1;">Find your most effective ad quickly and easily.</span>
        <span (click)="openConnectToSales($event, salesFormTemplate)" class="link-new" style="position: absolute; right: 10px;">Connect to sales team</span>
      </div>
    
      <div class="login-text">Let's get your free trial started!</div>
    </div>
    
    <style>
      .link-new {
        color: rgb(25, 118, 210);
        text-decoration: underline;
        cursor: pointer;
      }
    
      .logo-container {
        width: 100%;
      }
    </style>
    
    <form nz-form nzLayout="vertical" [formGroup]="form">
      <nz-card>
        <div class="row-input-container">
          <nz-form-item>
            <span class="label"
              >First Name<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['firstName'].touched && f['firstName'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('firstName')"
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your first name"
                  formControlName="firstName"
                  id="firstName"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <span class="label"
              >Last Name<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['lastName'].touched && f['lastName'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('lastName')"
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your last name"
                  formControlName="lastName"
                  id="lastName"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="row-input-container">
          <nz-form-item>
            <span class="label"
              >Company email address<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['email'].touched && f['email'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('email')"
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your work email"
                  formControlName="email"
                  id="email"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <span class="label"
              >Company<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['companyName'].touched && f['companyName'].errors
                  ? 'error'
                  : ''
              "
              [nzErrorTip]="getErrorMessage('companyName')"
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your company name"
                  formControlName="companyName"
                  id="companyName"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
          <!-- <nz-form-item>
            <span class="label"
              >Country<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['country'].touched && f['country'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('country')"
            >
              <nz-input-group nzSearch nzSize="large">
                <nz-select
                  [nzMaxTagCount]="2"
                  [nzShowArrow]="true"
                  [nzOptionOverflowSize]="8"
                  nzPlaceHolder="Select"
                  formControlName="country"
                  id="country"
                  nzDropdownClassName="custom-radio-dropdown"
                  nzShowSearch="true"
                >
                  <ng-container
                    *ngIf="
                      formOptions &&
                      formOptions.countries &&
                      formOptions.countries.length > 0
                    "
                  >
                    <nz-option
                      *ngFor="let country of formOptions.countries"
                      [nzValue]="country"
                      [nzLabel]="country.name"
                    >
                    </nz-option>
                  </ng-container>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item> -->

          <div class="row-input-container">
          <nz-form-item>
            <span class="label"
              >Phone number<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['phone'].touched && f['phone'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('phone')"
              
              
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your phone number"
                  formControlName="phone"
                  id="phone"
                  [maxLength]="phoneMaxLength"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <span class="label"
              >Department<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['department'].touched && f['department'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('department')"
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your department"
                  formControlName="department"
                  id="department"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="row-input-container" style="margin-top: 0.5rem;">
          <nz-form-item>
            <span class="label"

              >Your Job role<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['jobRole'].touched && f['jobRole'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('jobRole')"
              
            >
              <nz-input-group nzSearch nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input"
                  placeholder="Enter your job role"
                  formControlName="jobRole"
                  id="jobRole"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <span class="label"
              >Industry<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['industry'].touched && f['industry'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('industry')"
            >
              <nz-input-group nzSearch nzSize="large">
                <nz-select
                  [nzMaxTagCount]="2"
                  [nzShowArrow]="true"
                  [nzOptionOverflowSize]="8"
                  nzPlaceHolder="Select"
                  id="industry"
                  nzDropdownClassName="custom-radio-dropdown"
                  nzShowSearch="true"
                  formControlName="industry"                  
                >
                  <ng-container
                    *ngIf="
                      formOptions &&
                      formOptions.industries &&
                      formOptions.industries.length > 0
                    "
                  >
                    <nz-option
                      *ngFor="let industry of formOptions.industries"
                      [nzValue]="industry"
                      [nzLabel]="industry.name"
                    >
                    </nz-option>
                    <nz-option
                      [nzValue]="{ name: 'custom', categories: ['Other'] }"
                      nzLabel="Other"
                    ></nz-option>
                  </ng-container>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
            <nz-form-control
              class="role-form"
              *ngIf="f['industry'].value?.name == 'custom'"
              [nzValidateStatus]="
                f['customIndustry'].touched && f['customIndustry'].errors
                  ? 'error'
                  : ''
              "
              [nzErrorTip]="getErrorMessage('customIndustry')"
            >
              <nz-input-group nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input custom"
                  placeholder="Enter your industry"
                  id="customIndustry"
                  formControlName="customIndustry"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

          <!-- <nz-form-item>
            <span class="label"
              >Category<span style="color: #ef4444">*</span></span
            >
            <nz-form-control
              class="role-form"
              [nzValidateStatus]="
                f['category'].touched && f['category'].errors ? 'error' : ''
              "
              [nzErrorTip]="getErrorMessage('category')"
            >
              <nz-input-group nzSearch nzSize="large">
                <nz-select
                  [nzMaxTagCount]="2"
                  [nzShowArrow]="true"
                  [nzOptionOverflowSize]="8"
                  nzPlaceHolder="Select the Industry category"
                  formControlName="category"
                  id="category"
                  nzDropdownClassName="custom-radio-dropdown"
                  nzShowSearch="true"
                >
                  <ng-container
                    *ngIf="selectedIndustry"
                  >
                    <nz-option
                      *ngFor="let category of selectedIndustry?.categories"
                      [nzValue]="category"
                      [nzLabel]="category"
                    >
                    </nz-option>
                  </ng-container>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
             <nz-form-control
              class="role-form"
              *ngIf="selectedIndustry?.name == 'custom'"
              [nzValidateStatus]="
                f['customIndustry'].touched && f['customIndustry'].errors
                  ? 'error'
                  : ''
              "
              [nzErrorTip]="getErrorMessage('customCategory')"
            >
              <nz-input-group nzSize="large">
                <input
                  nz-input
                  type="text"
                  class="rounded-input custom"
                  placeholder="Enter your industry category"
                  id="customCategory"
                  formControlName="customCategory"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div> -->
        <nz-form-item class="terms-wrapper">
          <nz-form-control
            class="role-form"
            [nzValidateStatus]="
              f['terms'].touched && f['terms'].errors ? 'error' : ''
            "
            [nzErrorTip]="
              'Please accept the Terms and Privacy Policy to move ahead. If you have any queries, please feel free to reach us at support@incivus.ai'
            "
          >
            <nz-input-group
              nzSearch
              nzSize="large"
              class="terms-checkbox"
              style="text-align: center"
            >
              <label nz-checkbox formControlName="terms"
                >I agree to all the
                <span (click)="openTermsAndPolicy(true, $event)" class="link" 
                  >Terms</span
                >
                and
                <span (click)="openTermsAndPolicy(false, $event)" class="link"
                  >Privacy Policies</span
                >
               
                <!-- <span (click)="openConnectToSales($event, salesFormTemplate)" class="link">Connect to sales team</span> -->

              </label>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </nz-card>
      <div class="form-footer">
        <div class="form-footer-wrapper">
          <app-button
            buttonClass="primary"
            label="Start your free trial"
            (click)="onContinueClicked()"
          ></app-button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- <ng-template #salesFormTemplate>
  <form nz-form nzLayout="vertical" [formGroup]="salesForm" class="responsive-form">
    <nz-form-item>
      <span class="label label-top">
        Email <span style="color: #ef4444">*</span>
      </span>
      <nz-form-control
        class="role-form"
        [nzValidateStatus]="fUser['emailUser'].touched && fUser['emailUser'].errors ? 'error' : ''"
        [nzErrorTip]="getErrorMessageUser('emailUser')"
      >
        <nz-input-group nzSearch nzSize="large">
          <input
            class="rounded-input"
            nz-input
            formControlName="emailUser"
            id="emailUser"
            placeholder="Enter your email"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <span class="label">
        Phone Number <span style="color: #ef4444">*</span>
      </span>
      <nz-form-control
        class="role-form"
        [nzValidateStatus]="fUser['phoneNumberUser'].touched && fUser['phoneNumberUser'].errors ? 'error' : ''"
        [nzErrorTip]="getErrorMessageUser('phoneNumberUser')"
      >
        <nz-input-group nzSearch nzSize="large">
          <input
            class="rounded-input"
            nz-input
            formControlName="phoneNumberUser"
            id="phoneNumberUser"
            placeholder="Enter your phone number"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div class="form-footer">
      <div class="form-footer-wrapper">
        <app-button
          buttonClass="small primary"
          label="SUBMIT"
          (click)="onConnectToSales()"
        ></app-button>
      </div>
    </div>
  </form>
</ng-template> -->



<div class="modal">
  <ng-template #salesFormTemplate>
    <form nz-form nzLayout="vertical" [formGroup]="salesForm" class="responsive-form">
      <nz-form-item>
        <span class="label label-top">
          Email <span style="color: #ef4444">*</span>
        </span>
        <nz-form-control
          class="role-form"
          [nzValidateStatus]="fUser['emailUser'].touched && fUser['emailUser'].errors ? 'error' : ''"
          [nzErrorTip]="getErrorMessageUser('emailUser')"
        >
          <nz-input-group nzSearch nzSize="large">
            <input
              class="rounded-input"
              nz-input
              formControlName="emailUser"
              id="emailUser"
              placeholder="Enter your email"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <span class="label">
          Phone Number <span style="color: #ef4444">*</span>
        </span>
        <nz-form-control
          class="role-form"
          [nzValidateStatus]="fUser['phoneNumberUser'].touched && fUser['phoneNumberUser'].errors ? 'error' : ''"
          [nzErrorTip]="getErrorMessageUser('phoneNumberUser')"
        >
          <nz-input-group nzSearch nzSize="large">
            <input
              class="rounded-input"
              nz-input
              formControlName="phoneNumberUser"
              id="phoneNumberUser"
              placeholder="Enter your phone number"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <div class="form-footer">
        <div class="form-footer-wrapper">
          <app-button
            buttonClass="small primary"
            label="SUBMIT"
            (click)="onConnectToSales()"
          ></app-button>
        </div>
      </div>
    </form>
  </ng-template>
</div>



