import { Component, OnInit } from '@angular/core';
import { RuleService } from '../_services/rule.service';
import { DynamicModalComponentService } from '../common/services/dyamic-modal-component.service';
import { AppServices } from '../_services/app.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { StorageService } from 'src/app/_services/storage.service';
import { EventBusService } from 'src/app/_shared/event-bus.service';
import { EventData } from 'src/app/_shared/event.class';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

interface Channel {
  id: string;
  title: string;
  selected?: boolean;
}
interface Brand {
  id: string;
  title: string;
}

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.less'],
  encapsulation: ViewEncapsulation.None

})


export class CampaignCreateComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private ruleService: RuleService,
    private appService: AppServices,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private serviceBus: EventBusService,
    private modal: NzModalService,


  ) { }
  user: any = {};
  errorMsg: string = '';
  saving: boolean = false;
  wordCount: number = 0;
  maxWordCount: number = 250;
  title: string = "";
  status: string = "Draft";
  oldName: string = "";
  edit: string = "";
  channelsList: Channel[] = [];
  selectedChannels: any = [];
  selectedBuget: string = "";
  selectedObjective: string = "";
  start_date: string = '';
  end_date: string = '';
  description: string = '';
  brands: string[] = [];
  selectedBrands: string = "";
  clientId: string = "";
  budgets: string[] = [];
  objectives: string[] = [];
  creativeMeta: any = {};
  respStatus: string = "";
  respMessage: string = "";
  campaignId: string | null = "";
  campaignData: any = {};

  currentDate: string;

  isLoading: boolean = true;
  addRoleForm = new FormGroup({
    roleName: new FormControl(''),
    email: new FormControl([]),
    permission: new FormControl([]),
    roleDescription: new FormControl('')
  });

  ngOnInit() {

    const today = new Date();
    this.currentDate = today.toISOString().split('T')[0];

    this.channelsList = [
      { id: 'Facebook', title: 'Facebook' },
      { id: 'Instagram', title: 'Instagram' },
      { id: 'Youtube', title: 'Youtube' },
      // { id: 'META', title: 'META' },
      // { id: 'YOUTUBE', title: 'YOUTUBE' },
    ]
    let user = this.ruleService.getUser();
    let cid = this.route.snapshot.paramMap.get('id');
    this.campaignData = user.campaigns[cid ?? -1];

    this.brands = user.brands;
    this.budgets = [
      '$0 to $500', '$500 to $5000', 'Above $5000'
    ];
    this.objectives = [
      'Awareness', 'Consideration', 'Conversion'
    ];
    if (this.campaignData) {
      console.log("this.campaignData", this.campaignData)
      this.title = this.campaignData.campaignName
      this.oldName = this.campaignData.campaignName
      this.selectedChannels = this.campaignData.channels?.split(",")
      this.description = this.campaignData.description
      this.selectedBuget = this.campaignData.budget
      this.selectedObjective = this.campaignData.objective
      this.start_date = this.campaignData.start_date
      this.end_date = this.campaignData.end_date
      this.selectedBrands = this.campaignData.brand
      this.edit = "true"
    } else {
      console.log(this.campaignData)
    }
  }
  createCampaign() {


    const specialCharRegex = /[!@#\$%\^\&*\)\(+=._-]/g;

    if (!this.title || this.title.trim() === '') {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh!oh! Campaign name cannot be empty. Please provide a name to continue.",
        nzClassName: "small-modal",
        nzClosable: true
      });
      return;
    }
    if (!this.selectedBrands) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh!oh! Brand name cannot be empty. Please provide a brand to continue.",
        nzClassName: "small-modal",
        nzClosable: true
      });
      return;
    }
    if (!this.selectedChannels) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh!oh! Channels name cannot be empty. Please provide a channel to continue.",
        nzClassName: "small-modal",
        nzClosable: true
      });
      return;
    }
    if (!this.selectedObjective) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh!oh! Objective name cannot be empty. Please provide a objective to continue.",
        nzClassName: "small-modal",
        nzClosable: true
      });
      return;
    }

    if (specialCharRegex.test(this.title)) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh! Oh! The campaign name cannot contain special characters. Please provide a new name",
        nzClassName: "small-modal",
        nzClosable: true
      });
      return;
    }

    if (new Date(this.end_date) < new Date(this.start_date)) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh! Oh! The end date cannot be before the start date. Please select the end date appropriately.",
        nzClassName: "small-modal",
        nzClosable: true
      });
      return;
    }


    let obj = {
      campaignName: this.title.toLowerCase().trim(),
      brand: this.selectedBrands,
      channels: this.selectedChannels.join(","),
      budget: this.selectedBuget,
      objective: this.selectedObjective,
      start_date: this.start_date,
      end_date: this.end_date,
      description: this.description,
      status: this.status
    }
    this.appService.createCamapign(obj).subscribe(
      (data: any) => {
        this.respMessage = data.message;
        this.respStatus = data.status;
        window.scrollTo(0, 0)


        if (data && data.status && data.status.toLowerCase() === "success") {
          this.modal.success({
            nzTitle: 'Success',
            nzContent: "Campaign created succesfully",
            nzClassName: "small-modal",
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => {
              this.user = this.storage.getUser()
              this.user.campaigns.push(obj);
              this.storage.saveUser(this.user);
              this.serviceBus.emit(new EventData('campaign_created', ""));
              this.router.navigate(["creatives", "pre-flight", "list"]);

            }
          });
        }


        // this.user.campaigns.push(obj);
        // this.storage.saveUser(this.user);
        // this.serviceBus.emit(new EventData('campaign_created', ""));

      },

      // (error) => {
      //   // Handle network or server errors
      //   let errorMessage = error.error.message || "An unexpected error occurred. Please check your connection and try again.";

      //   // Show the error popup
      //   this.modal.error({
      //     nzTitle: 'Error',
      //     nzContent: errorMessage,
      //     nzClassName: "small-modal",
      //     nzClosable: false,
      //     nzMaskClosable: false,
      //     nzKeyboard: false,
      //   });
      // }

      (error: any) => {
        // Handling the 400 error here
        if (error.status === 400) {
          this.modal.error({
            nzTitle: 'Error',
            nzContent: error?.error?.message,
            nzClassName: "small-modal",
            nzClosable: true,
            nzMaskClosable: true,
            nzKeyboard: true
          });
        } else {
          // Handle other status codes if needed
          this.modal.error({
            nzTitle: 'Error',
            nzContent: "An unexpected error occurred. Please try again later.",
            nzClassName: "small-modal",
            nzClosable: true,
            nzMaskClosable: true,
            nzKeyboard: true
          });
        }
      }
    )
  }
  editCampaign = () => {

    this.user = this.storage.getUser()
    let campaigns = this.user.campaigns;

    let nameOcc = campaigns.filter(
      (campaign: any) =>
        campaign.campaignName.toLowerCase() === this.title.toLowerCase()
    ).length;


    let updateFlag = true


    campaigns.forEach((campaign: any) => {
      if (campaign.campaignName.toLowerCase() === this.oldName.toLowerCase()) {
        console.log("check", nameOcc, this.title.toLowerCase())

        if (nameOcc > 0 && this.title.toLowerCase() !== this.oldName.toLowerCase()) {
          updateFlag = false; // Set flag to false if the name is taken by another campaign
        }
      }
    });

    if (updateFlag) {

    let obj = {
      campaignName: this.title.toLowerCase().trim(), 
      brand: this.selectedBrands,
      channels: this.selectedChannels?.join(","),
      budget: this.selectedBuget,
      objective: this.selectedObjective,
      start_date: this.start_date,
      end_date: this.end_date,
      description: this.description,
      oldCampaignName: this.oldName
    }

      this.appService.updateCampaign(obj).subscribe({
        next: (data: any) => {
          if (data && data.status && data.status.toLowerCase() === "success") {


            campaigns.forEach((campaign: any) => {
              if (campaign.campaignName.toLowerCase() === this.oldName.toLowerCase()) {
                campaign.campaignName = this.title;
                campaign.brand = this.selectedBrands;
                campaign.channels = this.selectedChannels.join(",");
                campaign.budget = this.selectedBuget;
                campaign.objective = this.selectedObjective;
                campaign.start_date = this.start_date;
                campaign.end_date = this.end_date;
                campaign.description = this.description;
                campaign.status = this.status;
              }
            });
            this.user.campaigns = campaigns;
            this.storage.saveUser(this.user);
            this.serviceBus.emit(new EventData('campaign_created', ""));
            this.router.navigate(["creatives", "pre-flight", "list"]);

            this.modal.success({
              nzTitle: "Successful!",
              nzContent:
                "Campaign Updated Successfully",
              nzMaskClosable: false,
              nzKeyboard: false,
              nzOkText: null,
              nzCancelText: null,
              nzClosable: true,
              nzWidth: "40%",
            });
          // this.dynamicModalService.closeModal();
        } else {
          // this.errorMsg = "Unable to update campagin name, please try again later";
          // this.saving = false;
        }
      },
      error: (err: any) => {
        
        if (err && err.error && err.error.status === 500) {
          this.errorMsg = "Unable to edit campagin, please try again later";
          this.saving = false;
        } 
        // else if (err && err.error &&
        //   err.error.status && err.error.status.toLowerCase() === 'error' &&
        //   err.error.errorCode && err.error.errorCode === 'CAMPAIGN_EXIST') {

        else if (err && err.error &&
          err.error.status && err.error.status.toLowerCase() === 'error' &&
          err.error.errorCode && err.error.errorCode) {
           //this.errorMsg = "Uh! Oh! seems like a campaign with this name already exists. Please enter a different campaign name and try again.";
          
            // Handle network or server errors
            let errorMessage = err.error.message || "An unexpected error occurred. Please check your connection and try again.";

            // Show the error popup
            this.modal.error({
              nzTitle: 'Error',
              nzContent: errorMessage,
              nzClassName: "small-modal",
              nzClosable: false,
              nzMaskClosable: false,
              nzKeyboard: false,
            });
            this.saving = false;

          } else {
            this.errorMsg = "Unable to edit campagin, please try again later";
            this.saving = false;
          }
        }
      });
    } else {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: "Uh! Oh! seems like a campaign with this name already exists. Please enter a different campaign name and try again.",
        nzClassName: "small-modal",
        nzClosable: true
      });
      // this.errorMsg = "Uh! Oh! seems like a campaign with this name already exists. Please enter a different campaign name and try again.";
      this.saving = false;
    }
  }

  checkTitleValid() {
    if (!this.title) {
      // Mark the input as touched to show the validation error
      return false;
    }
    return true;
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement);
    const words = input.value.split(/\s+/); // Split by spaces to get word count

    this.wordCount = words.length

    if (words.length > 250) {
      // Keep only the first 250 words and remove the rest
      this.description = words.slice(0, 250).join(' ');
      input.value = this.description; // Update the input field
    } else {
      this.description = input.value;
    }
  }

  onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData?.getData('text') || '';
    const currentWords = this.description.split(/\s+/);
    const pastedWords = pastedText.split(/\s+/);

    // If paste causes word count to exceed 250, trim the text
    if (currentWords.length + pastedWords.length > 250) {
      const allowedWords = pastedWords.slice(0, 250 - currentWords.length);
      this.description += ' ' + allowedWords.join(' ');
      event.preventDefault(); // Prevent default paste behavior
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    const input = (event.target as HTMLInputElement);
    const words = input.value.split(/\s+/);

    if (words.length >= 250 && event.key !== 'Backspace') {
      // Prevent typing if word limit is reached, except for backspace
      event.preventDefault();
    }
  }

  private countWords(text: string): number {
    return text.trim() ? text.trim().split(/\s+/).length : 0;
  }

}
