export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBM2eNukq3DBMVB76I1RPPtc3DzHrbS-R0",
    authDomain: "incivus-28cfd.firebaseapp.com",
    databaseURL: "https://incivus-28cfd-default-rtdb.firebaseio.com",
    projectId: "incivus-28cfd",
    storageBucket: "incivus-28cfd.appspot.com",
    messagingSenderId: "46175028950",
    appId: "1:46175028950:web:94b9aedc68c97b99a6ab77",
    measurementId: "G-C4CFPXY1L2"
  }
};