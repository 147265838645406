export const STROKE_COLOR:any = {
    LOW:"low",
    MEDIUM:"medium",
    HIGH:"high",
};

export const STROKE_COLOR_CODE:any = {
    LOW:"#EF4444",
    MEDIUM:"#F59E0B",
    HIGH:"#16A34A",
};