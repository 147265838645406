import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AppServices } from "src/app/_services/app.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { EventBusService } from "src/app/_shared/event-bus.service";
import { EventData } from "src/app/_shared/event.class";
import { Subject, takeUntil } from "rxjs";

interface ItemData {
  id: string;
  firstName: string;
  lastName: string;
  status: string;
  email: string;
  companyName: string | null;
  createdAt: string | null;
}

@Component({
  selector: "app-user-list",
  templateUrl: "./userList.component.html",
  styleUrls: ["./userList.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent implements OnInit {
  @Input() public data: any;
  isAddTrialUserVisible = false;
  addUserData: any;
  isServiceRequest: boolean = false;
  showResendEmailBtn: boolean = false; // if a single user is checked, this will store whether to show resendEmail
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private appService: AppServices,
    private eventBusService: EventBusService,
    private modal: NzModalService
  ) {}

  toggleAddUserModal(): void {
    this.isAddTrialUserVisible = !this.isAddTrialUserVisible;
  }

  handleCancel(): void {
    this.isAddTrialUserVisible = false;
  }

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<string>();

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    if (this.setOfCheckedId.size == 1) {
      const [first] = this.setOfCheckedId;

      let [user]: any = this.listOfData.filter((e: any) => {
        return e.id === first;
      });
      // TRIAL-33: Resend mail button is shown for the active trial users only.
      this.showResendEmailBtn =
        user?.password == null && user?.status == "active";
    } else this.showResendEmailBtn = false;
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
    this.indeterminate =
      this.listOfCurrentPageData.some((item) =>
        this.setOfCheckedId.has(item.id)
      ) && !this.checked;
  }

  ngOnInit(): void {
    console.log("userListComponent ngOnInit ", this.data.isTrialSignupCompany);

    setTimeout(() => {
      this.listOfData = this.data.user;
      console.log("userList ngOnInit listOfData", this.listOfData);

      this.isServiceRequest =
        this.data?.client?.featureAccess?.serviceRequest ?? false;

    }, 10);

    this.eventBusService.readEvent
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((action) => {
        if (action.name === "toggle_service_request") {
          // Based on "Service Request" feature turned on or off
          this.isServiceRequest = action?.value?.toggle;
        }
        if (action.name === "toggle_add_user") {
          // Based on "Service Request" feature turned on or off
          this.isAddTrialUserVisible = action?.value?.toggle;
        }
      });
  }

  addUser = () => {
    this.addUserData = {
      client: this.data.client,
      user: [],
      isTrialSignupCompany: this.data.isTrialSignupCompany,
    };
    this.toggleAddUserModal();
  };
  continue = () => {
    // on_trial_setup_complete
    this.eventBusService.emit(new EventData("on_trial_setup_complete", true));
  };
  // called with "edit user" button is clicked
  editUser = () => {
    const [first] = this.setOfCheckedId;
    let user = this.listOfData.filter((e: any) => {
      return e.id === first;
    });
    console.log("edit user", first, this.setOfCheckedId, this.listOfData, user);

    this.addUserData = {
      client: this.data.client,
      user: user,
      isTrialSignupCompany: this.data.isTrialSignupCompany,
      type:'edit'
    };
    this.toggleAddUserModal();
  };
  // called when "Resend Email" button is clicked
  resendEmail = () => {
    const [first] = this.setOfCheckedId;
    // TODO - call resentPasswordEmail API
    if (first) {
      console.log("resendEmail:", first);
      this.appService.resendEmail(first).subscribe({
        next: (data: any) => {
          this.modal.info({
            nzTitle: "Success",
            nzContent: "Email sent successfully",
            nzClassName: "small-modal",
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnCancel: () => {},
            nzOnOk: () => {},
          });
        },
        error: (err) => {
          this.modal.error({
            nzTitle: "Error",
            nzContent: "Unable to send email, please try again later",
            nzClassName: "small-modal",
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => console.log("Info OK"),
          });
        },
      });
    }
  };
  downloadData() {
    this.appService.getTrialUsersExcel().subscribe((fileData) => {
      const blob: any = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      let link = document.createElement("a");

      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "trial_users");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }
  deleteUser = () => {
    const [first] = this.setOfCheckedId;
    this.appService.deleteUser(first).subscribe({
      next: (data) => {
        this.modal.info({
          nzTitle: "Success",
          nzContent: "User deleted successfully",
          nzClassName: "small-modal",
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnCancel: () => {
            this.eventBusService.emit(
              new EventData("user_deleted", this.data.client)
            );
          },
          nzOnOk: () => {
            this.eventBusService.emit(
              new EventData("user_deleted", this.data.client)
            );
          },
        });
      },
      error: (err) => {
        this.modal.error({
          nzTitle: "Error",
          nzContent: "Unable to delete client, please try again later",
          nzClassName: "small-modal",
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => console.log("Info OK"),
        });
      },
    });
  };
}
