import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidation {
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }
  // Custom validator to allow only alphabets and spaces
  static textOnlyValidator() {
    return (control: AbstractControl) => {
      const textRegex = /^[a-zA-Z\s ]*$/; // Regex to match only letters and spaces
      console.log('CustomValidation textOnlyValidator control', control);
      
      if (control.value && !textRegex.test(control.value)) {
        return { invalidText: true };
      }
      return null;
    };
  }
  // Higher-order function to create a word count validator with a dynamic limit
  static wordCountValidator(limit: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const wordCount = value.trim().split(/\s+/).length;

    // Return error if word count exceeds the dynamic limit
    return wordCount > limit ? { wordCountExceeded: { limit: limit, actual: wordCount } } : null;
  };
}
}