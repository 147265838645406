<app-breadcrumb [labels]="breadcrumb" fullBC="{{ true }}" class="createbenchmark-heading"></app-breadcrumb>
<br>
<app-alert [message]="error?.message" [alertType]="error?.type" *ngIf="error" />
<span class="createbenchmark-heading">Create new benchmark</span>
<form nz-form nzLayout="vertical" [formGroup]="form" class="workbench-form">
  <nz-card style="margin-top: 1rem;">
    <div class="form-description role-description">
      Fill in the following information
    </div>
    <div *ngIf="page==1">
      <div class="row-input-container">
        <nz-form-item>
          
          <span class="label">Benchmark type <span style="color: #ef4444">*</span></span>
            <nz-form-control class="role-form" *ngIf="'benchmarkType' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
              <nz-input-group nzSearch nzSize="small" class="custom-gap">
                <nz-select nzPlaceHolder='Type name' [formControlName]="controlName" [id]="controlName"
                  nzDropdownClassName="custom-radio-dropdown" nzShowSearch="true">
                  <nz-option nzValue="industry" nzLabel="Industry benchmark"></nz-option>
                  <nz-option nzValue="category" nzLabel="Category benchmark"></nz-option>
                  <nz-option nzValue="company" nzLabel="Company benchmark"></nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <span class="label">Status <span style="color: #ef4444">*</span></span>
          <nz-form-control class="role-form" *ngIf="'status' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="large" class="custom-gap">
              <nz-select nzPlaceHolder="Select" [formControlName]="controlName" [id]="controlName"
                nzDropdownClassName="custom-radio-dropdown" nzShowSearch="false">
                <nz-option nzValue="active" nzLabel="Active"></nz-option>
                <nz-option nzValue="inactive" nzLabel="Inactive"></nz-option>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="row-input-container">
        <nz-form-item>
          <span class="label">Industry<span style="color: #ef4444">*</span></span>
          <nz-form-control class="role-form" *ngIf="'industry' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="large" class="custom-gap" [disabled]="true">
              <nz-select [nzMaxTagCount]="2"  [nzShowArrow]="true"
              
                [nzOptionOverflowSize]="8" nzPlaceHolder="Select industry" [formControlName]="controlName" [id]="controlName"
                nzDropdownClassName="custom-radio-dropdown" nzShowSearch="false" [nzDisabled]="true"
                [disabled]="true">
                <nz-option [nzValue]="industry" [nzLabel]="industry" />
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <span class="label">Category <span style="color: #ef4444" *ngIf="'category' == f['benchmarkType'].value">*</span></span>
          <nz-form-control class="role-form" *ngIf="'category' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="large" class="custom-gap">
              <nz-select [nzShowArrow]="true" nzPlaceHolder="Select" [formControlName]="controlName" [id]="controlName"
                nzDropdownClassName="custom-radio-dropdown" nzShowSearch="true" nzShowSearch="true"  [nzDisabled]="'category' != f['benchmarkType'].value">
                <ng-container *ngIf="client?.categories">
                  <nz-option *ngFor="let item of client?.categories" [nzValue]="item" [nzLabel]="item">
                  </nz-option>
                </ng-container>
              </nz-select>
              <ng-template #requestTypePlaceHolder let-selectedList>+ {{ selectedList.length }}</ng-template>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="row-input-container" style="align-items: start;">
        <div style="flex:1">
        <nz-form-item>
          <span class="label">Description</span>
          <nz-form-control class="role-form" nzAutosize *ngIf="'description' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="large">
              <textarea
                nz-input
                style="padding: 8px;text-align: start; border: 0.063rem solid #919EAB; border-radius: .5rem;"
                [formControlName]="controlName"
                [id]="controlName"
                placeholder="Enter Ad Brief"
                rows="7"
              ></textarea>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        </div>
        <nz-form-item>
          <span class="label">Creative types <span style="color: #ef4444">*</span></span>
          <nz-form-control class="role-form" *ngIf="'creativeTypes' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="large" class="custom-gap">
              <nz-select nzMode="multiple" nzPlaceHolder="Select" [formControlName]="controlName" [id]="controlName"
                nzDropdownClassName="custom-check-dropdown" nzShowSearch="false">
                <nz-option nzLabel="Video Ads" nzValue="video" />
                <nz-option nzLabel="Display Ads" nzValue="image" />
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div *ngIf="page==2">
      <div class="row-input-container">
        <nz-form-item>
          <span class="label">Dataset(s)<span style="color: #ef4444">*</span></span>
          <nz-form-control class="role-form" *ngIf="'datasets' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="small" class="custom-gap">
              <nz-select nzPlaceHolder='Select instance for base data' [formControlName]="controlName" [id]="controlName"
                nzDropdownClassName="custom-check-dropdown" nzShowSearch="true" nzMode="multiple">
                <ng-container *ngIf="
                      formOptions &&
                      formOptions.dataset &&
                      formOptions.dataset.length > 0
                    ">
                  <nz-option *ngFor="let item of formOptions.dataset" [nzValue]="item" [nzLabel]="item">
                  </nz-option>
                </ng-container>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <span class="label">Companies <span style="color: #ef4444">*</span></span>
          <nz-form-control class="role-form" *ngIf="'companies' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="large" class="custom-gap">
              <nz-select nzMode="multiple" nzPlaceHolder="Select" [formControlName]="controlName" [id]="controlName"
                nzDropdownClassName="custom-check-dropdown" nzShowSearch="false">
                <ng-container *ngIf="clientList && clientList.length > 0">
                  <nz-option *ngFor="let client of clientList" [nzValue]="client.companyName"
                    [nzLabel]="client.companyName">
                  </nz-option>
                </ng-container>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="row-input-container">
        <nz-form-item>
          <span class="label">Refresh schedule<span style="color: #ef4444">*</span></span>
          <nz-form-control class="role-form" *ngIf="'refreshSchedule' as controlName"
              [nzValidateStatus]="f[controlName].errors && f[controlName].dirty ? 'error' : ''"
              [nzErrorTip]="getErrorMessage(controlName)">
            <nz-input-group nzSearch nzSize="small" class="custom-gap">
              <nz-select nzPlaceHolder='Select instance for base data' [formControlName]="controlName"
                [id]="controlName" nzDropdownClassName="custom-radio-dropdown" nzShowSearch="false">
                          <nz-option nzLabel="Bi-annually" nzValue="bi-annually"/>
                          <nz-option nzLabel="Annually" nzValue="annually"/>
                          <nz-option nzLabel="None" nzValue="none"/>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control class="role-form" *ngIf="'calculatePercentile' as controlName">
          <span style="display: flex;gap: 0.5rem;position: relative;"><label nz-checkbox [formControlName]="controlName">Calculate
              percentiles</label><img src="../../../assets/icons/tooltip.svg" alt=""
              style="position: absolute;bottom: 1rem;left: 10rem;"></span>
              </nz-form-control>
        </nz-form-item>
      </div>
    </div>

  </nz-card>
  <div class="form-footer">
    <div class="form-footer-wrapper">
      <span class="cancel-button" (click)="cancel()"> cancel </span>
      <ng-container *ngIf="page==1">
        <app-button buttonClass="secondary" label="Save Progress" (click)="saveProgress()"></app-button>
        <app-button buttonClass="primary" label="Continue" (click)="nextPage()"></app-button>
      </ng-container>
      <ng-container *ngIf="page==2">
        <app-button buttonClass="secondary" label="Go back" (click)="prvsPage()"></app-button>
        <app-button buttonClass="primary" [label]="id == null ? 'Create benchmark': 'Update benchmark'" (click)="submitForm()"></app-button>
      </ng-container>
    </div>
  </div>

  <nz-modal [(nzVisible)]="showCompanyModal"  [nzClosable]="false"  [nzMaskClosable]="true" [nzKeyboard]="false" [nzFooter]="null"
    [nzContent]="modalContent" (nzOnCancel)="cancel()">

    <ng-template #modalContent nzLayout="vertical">
      <app-alert [message]="clientError?.message" [alertType]="clientError?.type" *ngIf="clientError" />
      <div class="row-input-container ant-row">
        <nz-form-item>
          <nz-form-label [nzFor]="'client'" class="label">
            Select Company <span style="color: #ef4444">*</span>
          </nz-form-label>
      
          <nz-form-control class="role-form">
            <nz-input-group nzSearch nzSize="small" class="custom-gap">
              <nz-select [nzShowArrow]="true" [nzOptionOverflowSize]="8" nzPlaceHolder='Select' formControlName="client"
                id="client" nzDropdownClassName="custom-radio-dropdown" [nzShowSearch]="true">
                <ng-container *ngIf="allClientList">
                  <nz-option *ngFor="let client of allClientList" [nzValue]="client" [nzLabel]="client?.companyName">
                  </nz-option>
                </ng-container>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="form-footer">
        <div class="form-footer-wrapper">
          <span class="cancel-button" (click)="cancel()">
            cancel
          </span>
          <app-button buttonClass="primary" label="Continue" (click)="onCompanySelected()"></app-button>
        </div>
      </div>
    </ng-template>
  </nz-modal>
</form>
