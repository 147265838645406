<app-breadcrumb [labels]=breadcrumb fullBC="{{true}}"></app-breadcrumb>
<div style="margin-top:1rem;"></div>
<app-alert class="ng-star-inserted" [message]="'You can edit ranges and weights for the metrics'" [alertType]="'info'"></app-alert>

<section>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div *ngIf="saved" class="w-full mb-4 alert alert-info">
                    {{saved}}
                </div>
                <div class="w-full p-3 shadow shadow-lg rounded-3" style="border-radius: 15px;">
                    <form #form="ngForm" (ngSubmit)="onSubmit(form)">




                        <div class="w-100">
                            <div class="row text-left">

                                <div class="col-md-6 mb-3">
                                    <nz-form-item>
                                        <nz-form-label class="input-float-label">Rule Name <span style="color:#EF4444">*</span></nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group nzSearch nzSize="large">
                                                <input nz-input type="text" name="name" [(ngModel)]="ruleObj.name"
                                                    id="name" placeholder="Enter Rule Name" class="rounded-input"
                                                    aria-required="true" required #name="ngModel"
                                                    [ngClass]="{'is-invalid': name.invalid && (name.dirty || name.touched)}">
                                            </nz-input-group>
                                            <div *ngIf="name.invalid && (name.dirty || name.touched)"
                                                class="text-danger text-erorshow mt-3">
                                                Rule name is required.
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>


                                <!-- <div class="col-md-6 mb-3">
                                    <label for="name" class="mb-2 block">Role name</label>
                                    <input type="text" name="name" id="name" class="form-control same-height"
                                        [(ngModel)]="ruleObj.name" aria-required="true" required #name="ngModel"
                                        [ngClass]="{'is-invalid': name.invalid && (name.dirty || name.touched)}">
                                    <div *ngIf="name.invalid && (name.dirty || name.touched)"
                                        class="text-danger text-erorshow">
                                        Role name is required.
                                    </div>

                                </div> -->

                                <div class="col-md-6 mb-3">
                                    <!-- <label for="brands"  class="mb-2 block">Select Brands</label> -->

                                    <nz-form-item>
                                        <nz-form-label class="input-float-label">Select Brands <span style="color:#EF4444">*</span></nz-form-label>

                                        <nz-form-control>
                                            <nz-input-group nzSearch nzSize="default">
                                                <nz-select [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                    [nzShowArrow]="true" nzSize="large" [nzOptionOverflowSize]="8"
                                                    nzMode="multiple" nzPlaceHolder="Select Brand"
                                                    nzDropdownClassName="custom-check-dropdown"
                                                    [(ngModel)]="selectedBrands" name="brnds" #brnds="ngModel" required>
                                                    <ng-container *ngIf="brands && brands.length > 0">
                                                        <nz-option *ngFor="let brand of brands" [nzValue]="brand.id"
                                                            [nzLabel]="brand.title">
                                                        </nz-option>
                                                    </ng-container>
                                                </nz-select>
                                                <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length
                                                    }}</ng-template>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <div *ngIf="brnds.invalid && (brnds.dirty || brnds.touched)"
                                        class="text-danger text-erorshow">
                                        Please select at least one brand.
                                    </div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <!-- <label for="channels" class="mb-2 block">Select Channels</label> -->
                                    <nz-form-item>
                                        <nz-form-label class="input-float-label">Select Channels <span style="color:#EF4444">*</span></nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group nzSearch nzSize="large">
                                                <nz-select [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                    [nzShowArrow]="true" nzSize="default" [nzOptionOverflowSize]="8"
                                                    nzMode="multiple" nzPlaceHolder="Select Channels"
                                                    [(ngModel)]="selectedChannels" 
                                                    nzDropdownClassName="custom-check-dropdown"
                                                    name="channels" #channels="ngModel"
                                                    required>
                                                    <ng-container *ngIf="channelsList && channelsList.length > 0">
                                                        <nz-option *ngFor="let channel of channelsList"
                                                            [nzValue]="channel.id" [nzLabel]="channel.title">
                                                        </nz-option>
                                                    </ng-container>
                                                </nz-select>
                                                <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length
                                                    }}</ng-template>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <div *ngIf="channels.invalid && (channels.dirty || channels.touched)"
                                        class="text-danger text-erorshow">
                                        Please select at least one channel.
                                    </div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <nz-form-item>
                                        <nz-form-label class="input-float-label">Creative Types <span style="color:#EF4444">*</span></nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group nzSearch nzSize="large">
                                                <nz-select [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                    [nzShowArrow]="true" nzSize="default" [nzOptionOverflowSize]="8"
                                                    nzMode="multiple" nzPlaceHolder="Select Creative Type"
                                                    [(ngModel)]="selectedCreativeType"
                                                    nzDropdownClassName="custom-check-dropdown" name="creativeType"
                                                    (ngModelChange)="onCreativeTypeChange($event)" #channels="ngModel"
                                                    required>
                                                    <nz-option [nzValue]="'Display Ads'"
                                                        nzLabel="Display Ads"></nz-option>
                                                    <nz-option [nzValue]="'Video Ads'" nzLabel="Video Ads"></nz-option>

                                                </nz-select>
                                            </nz-input-group>
                                            <!-- <div *ngIf="mark_as_default.invalid && (mark_as_default.dirty || mark_as_default.touched)"
                                                class="text-danger text-erorshow">
                                                Please select an option.
                                            </div> -->
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                                <div class="col-md-6 mb-3">
                                    <!-- <label for="channels" class="mb-2 block">Select Channels</label> -->
                                    <nz-form-item>
                                        <nz-form-label class="input-float-label">Mark As Default </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group nzSearch nzSize="large">
                                                <nz-select nzPlaceHolder="Select Option" [(ngModel)]="ruleObj.isDefault"
                                                    name="mark_as_default" nzDropdownClassName="custom-radio-dropdown" required>
                                                    <nz-option [nzValue]="false" nzLabel="No"></nz-option>
                                                    <nz-option [nzValue]="true" nzLabel="Yes"></nz-option>

                                                </nz-select>
                                            </nz-input-group>
                                            <!-- <div *ngIf="mark_as_default.invalid && (mark_as_default.dirty || mark_as_default.touched)"
                                                class="text-danger text-erorshow">
                                                Please select an option.
                                            </div> -->
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                                <!-- <div class="col-md-6">
                                    <label for="mark_as_default" class="mb-2 block">Mark as default</label>
                                    <select name="mark_as_default" #mark_as_default="ngModel" id="mark_as_default"
                                        class="form-control" [(ngModel)]="ruleObj.isDefault" aria-required="true"
                                        required>

                                        <option *ngFor="let item of isDefaults" [value]="item.id">{{item.label}}
                                        </option>

                                    </select>
                                    <div *ngIf="mark_as_default.invalid && (mark_as_default.dirty || mark_as_default.touched)"
                                        class="text-danger text-erorshow">
                                        Please select an option.
                                    </div>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="w-100 ">
                                        <span></span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 text-right">
                                    <button type="button" class="btn btn-light">Cancel</button>
                                    <button [disabled]="form.invalid" class="btn btn-primary ml-3"
                                        type="submit">Save</button>
                                </div> -->
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div style="margin-top:1rem;"></div>

        <div class="row justify-content-center">
            <div class="col-md-12">
                <form #form="ngForm" (ngSubmit)="saveScores(form)">
                    <div class="w-100 p-3 rounded-3 shadow shadow-lg bg-white" style="border-radius: 15px;">
                        <button type="button" (click)="openRangeView(!openRanges)" 
                            class="btn btn-light arrowBtn py-3 rounded-4 d-flex align-items-center justify-content-between w-100">
                            <span>Ranges</span>
                            <!-- <span *ngIf="!scoreSaved" class="arrowIcon">

                                &#10094;
                            </span>
                            <span *ngIf="scoreSaved" class="arrowIcon">
                                &#10095;
                            </span> -->
                            <span *ngIf="!openRanges" (click)="openRangeView(!openRanges)" class="arrowIcon">
                                &#10094;
                            </span>
                            <span *ngIf="openRanges" (click)="openRangeView(!openRanges)" class="arrowIcon">
                                &#10095;
                            </span>

                        </button>
                        <!-- <div *ngIf="ruleId" class="w-100"> -->
                        <div *ngIf="openRanges" class="w-100 mt-3">
                            <div class="w-100">
                                <!-- <div *ngIf="!scoreSaved" class="w-100"> -->
                                <div class="w-100 mb-3 d-flex ">


                                    <button *ngIf="(selectedCreativeType?.includes('Display Ads'))" type="button"
                                        (click)="handleCreative('DISPLAY')"
                                        [ngClass]="{'btnBlue': creative === 'DISPLAY'}"
                                        class="btn creativeBtn mr-4">Display
                                        Ads</button>
                                    <button *ngIf="(selectedCreativeType?.includes('Video Ads'))" type="button"
                                        (click)="handleCreative('VIDEO')" [ngClass]="{'btnBlue': creative === 'VIDEO'}"
                                        class="btn creativeBtn">Video

                                        Ads</button>
                                </div>
                                <div *ngFor="let rscore of ranges; let i = index" class="w-100 mb-4 rangeform">
                                    <h4 class="subHeading mb-3">{{ rscore.name | titlecase }}</h4>
                                    <span class="text-danger text-erorshow">{{this.rangeScoresError.includes(rscore._id)
                                        ? "Please correctly define the ranges, ensuring they add up to 100 without
                                        overlap. For more details, check Help documentation" : null}}</span>
                                    <div class="w-100">
                                        <div class="d-flex align-items-center text-left">
                                            <input type="checkbox" [id]="rscore._id" class="form-checkbox mr-1"
                                                (change)="toggleCheckbox(rscore._id, $event)"
                                                [checked]="isChecked(rscore._id)" />
                                            <label [for]="rscore._id" class="mb-0"> Incivus ranges </label>
                                        </div>
                                        <div *ngFor="let level of getScores(rscore); let j = index"
                                            class="row align-items-center">
                                            <div class="col-md-3">
                                                <label for="" class="mb">{{ level.name| titlecase }} range</label>
                                            </div>
                                            <div class="col-md-9">

                                                <div class="w-100">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <!-- <label for="">From</label> -->
                                                            <div *ngIf="!isChecked(rscore._id)">
                                                                <!-- <input type="text" name="from{{rscore._id}}_{{j}}"
                                                                    [(ngModel)]="level.from" placeholder="Enter from"
                                                                    class="form-control" #from="ngModel"
                                                                    [ngClass]="{'is-invalid': from.invalid && (from.dirty || from.touched)}" /> -->


                                                                <nz-form-item>
                                                                    <nz-form-label
                                                                        class="input-float-label">From </nz-form-label>
                                                                    <nz-form-control>
                                                                        <nz-input-group nzSearch nzSize="large">
                                                                            <input nz-input type="number"
                                                                                name="from{{rscore._id}}_{{j}}"
                                                                                [(ngModel)]="level.from" id="from"
                                                                                placeholder="From" class="rounded-input"
                                                                                aria-required="true" required
                                                                                #from="ngModel"
                                                                                [ngClass]="{'is-invalid': from.invalid && (from.dirty || from.touched)}"
                                                                                (blur)="onBlurFrom_To( level.from , rscore._id , level.name , 'from' )"
                                                                                [disabled] = "true"

                                                                                >

                                                                        </nz-input-group>
                                                                        <div *ngIf="from.invalid && (from.dirty || from.touched)"
                                                                            class="text-danger text-erorshow">
                                                                            From is required.
                                                                        </div>
                                                                    </nz-form-control>
                                                                </nz-form-item>



                                                            </div>
                                                            <div *ngIf="isChecked(rscore._id)">
                                                                <div class="border p-2 bg-light form-control disabled"
                                                                    disabled="disabled">
                                                                    {{getFieldValue(rscore._id, j, 'from')}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-md-6" *ngIf=false>
                                                            <div *ngIf="!isChecked(rscore._id)" class="w-100">
                                                                <nz-form-item>
                                                                    <nz-form-label
                                                                        class="input-float-label">Condition</nz-form-label>


                                                                    <nz-form-control>
                                                                        <nz-input-group hidden nzSearch nzSize="large">
                                                                            <nz-select
                                                                                [disabled]="isChecked(rscore._id)"
                                                                                [nzMaxTagCount]="2"
                                                                                [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                                                [nzShowArrow]="true" nzSize="large"
                                                                                [nzOptionOverflowSize]="8"
                                                                                nzMode="default"
                                                                                nzPlaceHolder="Select Channels"
                                                                                [(ngModel)]="level.condition"
                                                                                name="channels{{rscore._id}}_{{j}}"
                                                                                #channels="ngModel" required>
                                                                                <ng-container
                                                                                    *ngIf="conditions && conditions.length > 0">
                                                                                    <nz-option
                                                                                        *ngFor="let condition of conditions"
                                                                                        [nzValue]="condition.id"
                                                                                        [nzLabel]="condition.title"></nz-option>
                                                                                </ng-container>
                                                                            </nz-select>
                                                                            <ng-template #tagPlaceHolder
                                                                                let-selectedList>+ {{
                                                                                selectedList.length }}</ng-template>
                                                                        </nz-input-group>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                                <div *ngIf="channels.invalid && (channels.dirty || channels.touched)"
                                                                    class="text-danger text-erorshow">
                                                                    Condition is required.
                                                                </div>
                                                            </div>
                                                            <div *ngIf="isChecked(rscore._id)"
                                                                class="w-100 p-2 form-control disabled bg-light border">
                                                                {{ getFieldValue(rscore._id, j, 'condition') }}
                                                            </div>

                                                        </div>

                                                        <div class="col-md-6">
                                                            <!-- <label for="">To</label> -->
                                                            <div *ngIf="!isChecked(rscore._id)">
                                                                <!-- <input type="text" name="to{{rscore._id}}_{{j}}"
                                                                    [(ngModel)]="level.to" placeholder="Enter to"
                                                                    class="form-control" required #to="ngModel"
                                                                    [ngClass]="{'is-invalid': to.invalid && (to.dirty || to.touched)}" />
                                                                <div *ngIf="to.invalid && (to.dirty || to.touched)"
                                                                    class="text-danger text-erorshow">
                                                                    To is required.
                                                                </div> -->
                                                                <nz-form-item>
                                                                    <nz-form-label
                                                                        class="input-float-label">To</nz-form-label>
                                                                    <nz-form-control>
                                                                        <nz-input-group nzSearch nzSize="large">
                                                                            <input nz-input type="text"
                                                                                name="to{{rscore._id}}_{{j}}"
                                                                                [(ngModel)]="level.to" id="to"
                                                                                placeholder="To" class="rounded-input"
                                                                                [disabled] = "j==2?true:false"
                                                                                (blur)="onBlurFrom_To( level.to , rscore._id , level.name , 'to' )"
                                                                                aria-required="true" required
                                                                                #to="ngModel"
                                                                                [ngClass]="{'is-invalid': to.invalid && (to.dirty || to.touched)}">
                                                                        </nz-input-group>
                                                                        <div *ngIf="to.invalid && (to.dirty || to.touched)"
                                                                            class="text-danger text-erorshow">
                                                                            To is required.
                                                                        </div>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                            </div>
                                                            <div *ngIf="isChecked(rscore._id)">
                                                                <div class="border p-2 form-control disabled bg-light">
                                                                    {{getFieldValue(rscore._id, j, 'to')}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- <div class="w-100 mt-5">
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>

        <div style="margin-top:1rem;"></div>

        <!-- <div *ngIf="scoreSaved" class="row justify-content-center"> -->
        <div class="row justify-content-center">
            <div class="col-md-12" >
                <div class="w-100 p-3 rounded-3 shadow shadow-lg bg-white" style="border-radius: 15px;">
                    <button
                    (click)="openWeightView(!openWeights)"    
                    class="btn mb-1 btn-light arrowBtn py-3 rounded-7 d-flex align-items-center justify-content-between w-100 bg-grey" >
                        <span>Weights</span>
                        <!-- <span class="arrowIcon">
                            &#10094;
                        </span> -->
                        <span *ngIf="!openWeights"  class="arrowIcon">
                            &#10094;
                        </span>
                        <span *ngIf="openWeights"  class="arrowIcon">
                            &#10095;
                        </span>
                    </button>
                    <form *ngIf="openWeights" class="mt-3" #form="ngForm" (ngSubmit)="onRuleCreate()" novalidate>


                        <div class="w-100 mb-3 d-flex ">
                            <button *ngIf="(selectedCreativeType?.includes('Display Ads'))" type="button"
                                (click)="handleCreative('DISPLAY')" [ngClass]="{'btnBlue': creative === 'DISPLAY'}"
                                class="btn creativeBtn mr-4">Display
                                Ads</button>
                            <button *ngIf="(selectedCreativeType?.includes('Video Ads'))" type="button"
                                (click)="handleCreative('VIDEO')" [ngClass]="{'btnBlue': creative === 'VIDEO'}"
                                class="btn creativeBtn">Video
                                Ads</button>
                        </div>



                        <div *ngFor="let weight of weights" class="w-100 mb-4 rangeform">
                            <h4 class="subHeading mb-3">{{weight.name}}</h4>

                            <div class="d-flex align-items-center text-left">
                                <input type="checkbox" [id]="weight.name" class="form-checkbox mr-1"
                                    (change)="toggleCheckboxWeight(weight.name, $event)"
                                    [checked]="isCheckedWeight(weight.name)" />
                                <label [for]="weight.name" class="mb-0"> Incivus weightages </label>
                            </div>
                            <div style="margin-top:0.5rem;"></div>


                            
                            <div class="w-100 bg-info-alert p-1 alert alert-info " 
                            style="background-color: #e6f7ff; "
                            >


                                {{ weight.name }} =
                                <span *ngFor="let score of weight.scores; let i = index">
                                    {{ score.name }} {{score?.weight}}
                                    <small *ngIf="i < weight.scores.length - 1"> + </small>
                                </span>

                            </div>
                            <!-- <app-alert [message]="'You can edit ranges and weights for the metrics'" [alertType]="'info'" ></app-alert> -->


                            <div class="w-100">
                                <div class="row">
                                    <div *ngFor="let wt of weight.scores; let k = index;" class="col-md-4">
                                        <label for="">{{wt.name}}</label>

                                        <!-- <input *ngIf="creative === 'VIDEO'" type="text"
                                            [(ngModel)]="wt.customVideoWeight" (ngModelChange)="validateWeight(weight)"
                                            class="form-control" name="{{ wt.name }}{{k}}" [ngClass]="" required
                                            #wtField="ngModel"
                                            [ngClass]="{'is-invalid': wtField.invalid && (wtField.dirty || wtField.touched)}">

                                        <input *ngIf="creative === 'DISPLAY'" type="text"
                                            [(ngModel)]="wt.customDisplayWeight" (ngModelChange)="validateWeight(weight)"
                                            class="form-control" name="{{ wt.name }}{{k}}" [ngClass]="" required
                                            #wtField="ngModel"
                                            [ngClass]="{'is-invalid': wtField.invalid && (wtField.dirty || wtField.touched)}"> -->

                                        <input *ngIf="creative === 'VIDEO'" type="text"
                                            [(ngModel)]="wt.customVideoWeight" 
                                            (ngModelChange)="validateWeight(weight)"
                                            class="form-control" name="{{ wt.name }}{{k}}_videoWeight" required
                                            #wtVideoField="ngModel"
                                            [ngClass]="{'is-invalid': wtVideoField.invalid && (wtVideoField.dirty || wtVideoField.touched)}"
                                            [disabled]="isCheckedWeight(weight.name)"
                                            [value]="isCheckedWeight(weight.name) ? wt.videoWeight : wt.customVideoWeight "
                                            >

                                        <!-- Error messages for Video -->
                                        <!-- <div *ngIf="wtVideoField?.invalid && (wtVideoField?.dirty || wtVideoField?.touched)"
                                            class="text-danger">
                                            Video Weight is required.
                                        </div> -->

                                        <input *ngIf="creative === 'DISPLAY'" type="text"
                                            [(ngModel)]="wt.customDisplayWeight"
                                            (ngModelChange)="validateWeight(weight)" class="form-control"
                                            name="{{ wt.name }}{{k}}_displayWeight" required #wtDisplayField="ngModel"
                                            [ngClass]="{'is-invalid': wtDisplayField.invalid && (wtDisplayField.dirty || wtDisplayField.touched)}"
                                            [disabled]="isCheckedWeight(weight.name)"
                                            [value]="isCheckedWeight(weight.name) ? wt.displayWeight : wt.customDisplayWeight">

                                        <!-- <div *ngIf="wtDisplayField?.invalid && (wtDisplayField?.dirty || wtDisplayField?.touched)"
                                            class="text-danger">
                                            Display Weight is required.
                                        </div> -->





                                        <div class="text-danger" *ngIf="weight.invalidWeight">
                                            The total weight must be 100.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- <div class="w-100 mt-5">

                        <div class="d-flex justify-content-between">
                            <span></span>
                            <button (click)="onRuleCreate()" [disabled]="isFormInvalid || form.invalid"
                                class="btn btn-primary">Save</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        
        <div>
            <div class="w-100 mt-5">
                <div class="d-flex justify-content-between">
                    <span></span>
                    <!-- <button (click)="onRuleCreate()" 
                    [disabled]="isFormInvalid || form.invalid"
                        class="btn btn-primary" style="background-color: #38B3E4;">Save</button> -->
                    <button (click)="onRuleCreatePopup()" class="btn btn-primary"
                        style="background-color: #38B3E4; border-color: #39B3EA;"
                        [disabled]="rangeScoresError.length != 0"
                        >Save</button>
                </div>
            </div>
        </div>


    </div>
</section>