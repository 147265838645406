import { AbstractControl, ValidatorFn } from '@angular/forms';

 let publicEmailProviders = [
   "gmail",
   "yahoo",
   "hotmail",
   "outlook",
   "yopmail",
   "aol",
   "protonmail",
   "icloud",
   "zoho",
   "gmx",
   "mail",
   "yandex",
   "fastmail",
   "live",
   "hushmail",
   "me",
   "inbox",
   "tutanota",
 ];

// Custom Validator for email domain validation
export function companyEmailValidator(): ValidatorFn {
  return (control: AbstractControl) => {
   

    const email = control.value;
    const emailDomain = email?.split('@')[1] || '';
    const domainParts = emailDomain.split('.');
    const baseDomain =
    domainParts.length >= 1 ? domainParts[0] : '';
    
    // Check if email domain is public
    if (publicEmailProviders.includes(baseDomain)) {
      return { publicEmail: true };
    }
    // invalid email
       const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
       const valid = emailRegex.test(control.value);
       return valid ? null : { email: true };
    // Check if email domain matches company name
    // if (companyName && !emailDomain.includes(companyName.toLowerCase())) {
    //   return { companyEmailMismatch: true };
    // }

    // return null;
  };
}

export function updateDomains(domains: string[]) {
    publicEmailProviders = domains;
}


// Custom Validator for phone number (10 digits, no country code)
  export function phoneNumberValidator(digits: number = 10) {
    return (control: AbstractControl) => {
      const phone = control.value;
      const hasNonDigits = /\D/.test(phone); // checks if there are any non-digit characters

      const onlyDigits = phone?.replace(/\D/g, ""); // remove non-digit characters

      if (onlyDigits && onlyDigits.length !== digits) {
        return {
          invalidPhoneNumber:
            `Uh! Oh! The phone number must contain atleast ${digits} digits. Please ignore country code when entering mobile number here.`,
        };
      }
      if (phone != null && hasNonDigits && phone.length > 0 ) {
        return {
          invalidPhoneNumber: `Uh! Oh! Please enter a valid phone number.`,
        };
      }


      return null;
    };
  }
  // only a-z allowed, no numbers allowed
export function textOnlyValidator() {
  return (control: AbstractControl) => {
    const textRegex = /^[a-zA-Z\s]*$/; // Regex to match only letters and spaces
    if (control.value && !textRegex.test(control.value)) {
      return { invalidText:  true };
    }
    return null;
  };
}
// A-Z and numbers allowed
export function noSpecialCharValidator() {
  return (control: AbstractControl) => {
    const textRegex = /^[a-zA-Z0-9\s]*$/; // Regex to match only letters and spaces
    if (control.value && !textRegex.test(control.value)) {
      return { invalidText:  true };
    }
    return null;
  };
}

export const customErrorMessages = {
  required: {
    firstName: "Uh! Oh! Please enter the first name.",
    lastName: "Uh! Oh! Please enter the last name.",
    email: "Uh! Oh! Please enter a work email.",
    emailId: "Uh! Oh! Please enter a work email.",
    status: "Uh! Oh! Please select the status.",
    companyName: "Uh! Oh! Please enter the company name.",
    country: "Uh! Oh! Please select the country.",
    phone: "Uh! Oh! Please enter the phone number.",
    department: "Uh! Oh! Please enter the department.",
    jobRole: "Uh! Oh! Please enter the job role.",
    industry: "Uh! Oh! Please select the industry.",
    customIndustry: "Uh! Oh! Please enter the industry.",
    category: "Uh! Oh! Please select the industry category.",
    customCategory: "Uh! Oh! Please enter the industry category.",

    emailUser: "Uh! Oh! Please enter a work email.",
    phoneNumberUser: "Uh! Oh! Please enter the phone number.",
  } as { [key: string]: string },
  invalidText: {
    firstName: "Uh! Oh! First name cannot contain special characters.",
    lastName: "Uh! Oh! Last name cannot contain special characters.",
    email: "Uh! Oh! Please enter a valid work email.",
    companyName: "Uh! Oh! Please enter the company name.",
    department: "Uh! Oh! The department cannot contain special characters.",
    jobRole:
      "Uh! Oh! The job role cannot contain special characters. Please provide valid job role.",
    customIndustry: "Uh! Oh! The industry cannot contain special characters.",
    customCategory: "Uh! Oh! The category cannot contain special characters.",
  } as { [key: string]: string },
}; 

 export const getErrorMessage = (control: AbstractControl | null, controlName: string): string => {
    if (control && control.errors) {
      const errors = control.errors;

      console.log("error is "+Object.keys(errors)[0])

      const firstKey = Object.keys(errors)[0];

      if (typeof errors[firstKey] == "string")
        return errors[firstKey] as string;
      if (firstKey == "required")
        return (
          customErrorMessages.required[controlName] ??
          `Uh! Oh! This field is required.`
        );
      if (firstKey == "invalidText")
        return (
          customErrorMessages.invalidText[controlName] ??
          `Uh! Oh! The entered value is not valid.`
        );
      if (firstKey == "email")
        return `Uh! Oh! The please enter a valid work email.`;

      if (firstKey == "publicEmail" && controlName=="emailUser")
        return `Please use only official email address to connect with Incivus.`;

      if (firstKey == "publicEmail")
       return `Please use only official email address to sign-up for Trial.`;


      if (firstKey == "maxlength")
        return `Uh! Oh! The length cannot be more than ${errors[firstKey].requiredLength} characters.`;
      return firstKey;
      // Add more error handling logic here if needed
    }
    return "";
  }
