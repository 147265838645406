<app-report-card
    title="Attention score"
    description="High Attention score leads to Ad being highly memorable therefore enabling you to meet your Ad goals effectively."
    score="72"
    rangeInfo="Above 90"
></app-report-card>
<nz-divider></nz-divider>
<div class="focus-details-wrapper">
    <div class="focus-description">Simple and straightforward Ad copy with effective colors and background and text placement increases attention. </div>
    <h6>Focus Graph</h6>
    <img src="assets/icons/focus-image.svg">
</div>