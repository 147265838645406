<app-breadcrumb [labels]=breadcrumb fullBC="{{true}}"></app-breadcrumb>
<div class="card-details-container">
    <div class="card-details-wrapper flex-compliance">
        <div class="card-wrapper">
            <nz-card class="compliance-card help-card" [nzBordered]="false">
                <div class="details-card-wrapper">
                    <div class="title">In-flight Testing</div>
                    <div class="description">
                        <div class="secBody">
                            You can connect your Meta (Facebook and Instagram) account with Incivus Platform now to view all your in-flight Ads and run the Creative Effectiveness Analysis on them while they are active.  

                            <p style="margin-top: 1rem;">This section is divided into two parts: </p>

                            <ol>
                            <li>  Connecting Facebook and Instagram Ad account with Incivus Platform  </li>
                            <li>  Viewing In-flight Ads and running analysis on them within the Incivus Platform  </li>    
                            </ol>                
                        </div>
                        
                       
                        <div class="secTitle">
                            Account permissions for Meta/Facebook Business Suite                        
                        </div>
                    
                        <div class="secBody">
                            <p>To confirm you have the necessary permissions to link your Facebook Page to our platform, it's important to review your account permissions in Facebook Business Manager. This guide offers step-by-step instructions to check your access level and steps to take if you lack 'Full control.' </p>
                            <p class="sub-title">  Faceboook Business Manager Account permissions </p>
                            <p class="subtitle-body">Following steps will help you determine your permissions within Facebook Business Manager:  </p>
                            <ol class="subtitle-body">
                                <li>  Select the business 
                                    <ol type="a">
                                   <li> Choose the business that owns the page you wish to connect. </li>
                                
                                   <li> You can also navigate to <a href="https://business.facebook.com" target="_blank">“https://business.facebook.com”</a> and click on the drop-down menu in the top left-hand corner to select the business portfolio.  </li>
                                    </ol>
                                </li>
                                <li>     Business settings 
                                    <ol type="a">
                                        <li>     Once the business is selected, navigate to <b>“Settings”</b>  </li>
                                    </ol>
                                </li>                               
                                <li>       Permissions in the People tab 
                                    <ol type="a">
                                        <li>  In <b>“Settings” </b>, navigate to <b>“People” </b>tab under <b>“Users”</b> section   </li>
                                        <li>    Check if you have full control within in the permission levels associated with your account </li>
                                    </ol>
                                </li>  
                                <li>   Permissions in the Pages tab 
                                    <ol type="a">

                                        <li>  Navigate to Pages tab to ascertain your control level </li>
                                        <li> Select the page that you intend to check your control level </li>
                                        <li> Your permission is displayed on the right-hand side </li>
                                    </ol>
                                </li>  

                                <li>     Lack of full control 
                                    <ol type="a">

                                        <li>  In case you do not have full control, please reach out to a colleague who may  </li>
                                    </ol>
                                </li>  


                            </ol>
                        </div>

                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem">Setup Incivus as Partner within the Facebook Business Manager</div>
                            <div class="description">
                                    <div class="secBody">
                                        <div >
                                            <ol class="subtitle-body">
                                                <li>      There are three ways to add Incivus as partner within the Business Manager 
                                                    <ol type="a">
                                                        <li>     Pages </li>
                                                        
                                                        <li>     Ad Accounts   </li>
                                                        <li>    Users >> Partners  </li>
                                                    </ol>
                                                </li>

                                                <li>      Underneath the section Accounts, navigate to Pages and under your Ad Account click on <b>"+Add partner”</b>. Under Assign partner, select Business ID. Enter the business ID: 428832643193545 to add Incivus as Partner. 
                                                    <ol type="a">
                                                        <li>     Navigate to Partners tab underneath the listed Ad Account and click on <b>“+Add partner” </b>.  </li>
                                                    </ol>
                                                </li>
                                            
                                                <li>       Underneath the section Users, navigate to Partners and search for <b> “CES Incivus”  </b>and select from the result. Select the pages you would like to assign to Incivus. Enable the <b>“Insights”</b> permission for your selected pages. 
                                                    <ol type="a">
                                                        <li>         To ensure your data is secure, we request the <b>“lowest access level”</b> so that we may only ingest your ad creatives and associated performance metrics.  </li>
                                                        <li>    Incivus does not gain any edit access in this process.  </li>
                                                    </ol>
                                                </li>
                                                
                                                <li>    Verify that Incivus is listed as a partner and all pages under <b>“Assigned assets” </b>on the right hand side:  
                                                        <img  class="inFlightImg" src="assets/image_inFlight.png">
                                                </li>


                                            </ol>
                                        </div>
                                </div>
                            </div>
                        </div>


                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem"> Linking Facebook and Instagram Ad account with Incivus Platform</div>
                            <div class="description">
                                    <div class="secBody">
                                        <p>Here is the process to connecting your meta account within the Incivus platform:  </p>
                                         <div >
                                            <ol>                                                
                                                <li>    Login to Facebook Business Manager and add Incivus as a Partner to the Ad account(s) that you wish us to analyze. This is a pre-requisite. </li>
                                                <li>    Please note that Incivus will not be able to pull in all Ad content data unless all associated Facebook Brand Pages are assigned. </li>
                                                <li>    Login to Incivus platform. </li>
                                                <li>     On Incivus platform, navigate to Settings >> Media accounts and click on “Add account” where you are asked to Login to the Facebook.  </li>
                                                <li>    As soon as you successfully login to facebook account, you are required to select your Business Manager Account. This is provided in case you have multiple accounts linked. 
                                                    <br><b><u>Note:</u></b> Any permissions listed here are standard from Meta and are covered by your company's data-sharing agreement with Incivus. 

                                                </li>
                                           
                                         
                                                <li>
                                                    Once you have logged in, map the Brand and Market(s) to the facebook account and click on continue to view the connected Facebook accounts.  
                                                </li>
                                                <li>
                                                    As soon as access is provided, you can see the Brand Account and pages connected with Incivus platform. 
                                                </li>
                                                <li>
                                                        You can view all Live/Active Campaigns flowing into the In-flight Ads section of the Incivus Platform. 
                                                </li>
                                            </ol>



                                        </div>
                                </div>
                            </div>
                        </div>



                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem"> Analyzing In-flight Ads</div>
                            <div class="description">
                                    <div class="secBody">
                                        <p>Once your Facebook and Instagram account and brand pages are connected to the Incivus Platform, all active campaigns are visible in the In-flights section of the platform. You can now analyze, In-flight Ads from within a Campaign.   </p>
                                        <p>Here is how the In-flight section is structured: </p>
                                         <div >
                                            <ol>                                                
                                                <li>      All active Campaigns are listed as folders with the Campaign title/name.  </li>
                                                <li>       Clicking on a Campaign folder will open the Campaign details with all Ads in the campaign listed in a tabular format. The campaign level performance details are displayed and percentage (%) change 	in the performance numbers in last 30days.   </li>
                                                <li>       The ads listed within the Campaign can be analyzed by clicking on the “analyze” button. Each Ad’s performance details such as reach, impressions, ad spend, ad clicks and ad frequency are displayed under the listed ad.    </li>
                                                <li>    Once the Ad is analyzed, the report is available in the Reports section under In-flight sub-section. </li>
                                                 </ol>
                                            <p><b><u>Note:</u></b> You can also search for a Campaign in the In-flights section landing page.   </p>
            
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-card-wrapper">
                            <div class="title" style="margin-top: 1rem"> Service Request</div>
                            <div class="description">
                                    <div class="secBody">
                                            <p>
                                                The users of the Incivus Platform such as the Brand Managers, Creative Directors or Growth Marketers can request for the following services from Incivus Analyst team:                                            
                                            </p>
                                            <ol>
                                                <li>Create a new Ad</li>
                                                <li>Create Ad variations</li>
                                            </ol>
                                            
                                        <div >
                                            To submit a new request, following pre-requisites are a must:
                                            <ul style="list-style-type: none">                                                
                                                <li>- Permission to service request feature</li>
                                                <li>- If the request type is <b>“Create new Ad”</b>, the following information should be available with the user before they start creating the request
                                                    <ul style="list-style-type: circle;">
                                                        <li>Ad brief</li>
                                                        <li>Brand for which the new Ad must be created</li>
                                                        <li>Brand Assets that should be used in the Ad</li>
                                                        <li>Social media platform channels for which this Ad must be created</li>
                                                        <li>Launch date</li>
                                                        <li>Colors that should be used as dominant colors in the Ad</li>
                                                        <li>Emotions that you would like the Ad to emote</li>
                                                    </ul>
                                                </li>
                                               
                                                <li>- If the request type is <b>“Create Ad variation” </b>, the following information should be available apart from all the above information
                                                    <ul style="list-style-type: circle;">
                                                        <li>An image or video Ad file for which a variation needs to be created</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>Steps for creating a service request:</p>
                                            <ol>
                                                <li>
                                                    As the user with the permission to create a service request, you will find this feature under settings on the left-hand navigation.
                                                </li>
                                                <li>
                                                    Click on <b>“All”</b> under <b>“Service Requests”</b> menu on left-hand navigation to load the page with the option to create a new request.
                                                </li>
                                                <li>
                                                    Click on <b>“+New request”</b> to load a form that must be filled in for the new request to be generated.
                                                </li>
                                                <li>
                                                    Provide a summary that is easy to understand.
                                                </li>
                                                <li>
                                                    Request type should be selected as either <b>“Create new Ad”</b> or <b>“Create Ad variation”.</b>
                                                </li>
                                                <li>
                                                    Select the brand and creative type as <b>“video ad”</b> or <b>“display ad”</b>. <b>Note:</b> Brands available here for you to select from are the ones you have access to.
                                                </li>
                                                <li>
                                                    Ad brief can be provided in text or as attachment. Only upto 5 attachments are allowed for this field.
                                                </li>
                                                <li>
                                                    You must select a channel for which the Ad must be created to ensure the resolution as well as other aspects of the file are compatible with the social media channel.
                                                </li>
                                                <li>The launch date provided here would help the Analyst team to service you on time.</li>
                                                <li>Brand assets that must be used for the Ad should be selected. If no brand assets are selected, then the assets from Brand Guidelines for the brand is used for creating the ad or its variation.</li>
                                                <li>For creating Ad variations, the Ad for which variations must be designed should be uploaded as part of Ad brief or Assets.</li>
                                                <li>Any additional details that might want to be added to the current request can be accommodated in the additional details field. The word limit here is 500 words.</li>
                                                <li>Click on the submit for confirmation.</li>
                                                <li>A service request number will be automatically generated.</li>
                                                <li>The service requests logged into the platform will be seen in all sections of the navigation panel.</li>
                                                <li>As soon as the service request sitting in the Task list is marked as complete, the status changes to <b>“Completed”</b>, In case there are additional details required on the ticket, it is put <b> “on-hold”</b>.</li>
                                                <li>Status of the service request can be seen on looking into the service id details or on the quick look table.</li>
                                                <li>Completed service requests will be seen in green color and in progress status will be observed in amber color.</li>
                                                <li>Any service request which is in <b>“closed”</b> state for more than 3 months goes into the <b>“Archived”</b> list. These closed service requests are available only for 24months (about 2 years) from the day it was raised. After this, the service request is removed from the system.</li>
                                            </ol>
                                        </div>

                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</div>