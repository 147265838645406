<h5>DISCLAIMER</h5>
<p>
    WELCOME TO INCIVUS PLATFORM.
</p>
<p>
THANK YOU FOR AVAILING OUR SERVICES! WE TRUST THAT THE REPORT ASSISTS YOU FOR BETTER OUTCOMES. WHILE YOU ARE EAGER TO READ AND ACCESS THE REPORT, THIS IS A COURTESY ACKNOWLEDGEMENT THAT YOU HAVE READ AND UNDERSTOOD THE FINAL DISCLAIMER FOR THE USE OF THIS REPORT.
</p>
<p>
THIS REPORT IS PROVIDED BY INCIVUS FOR CREATIVE EVALUATION AND ENGAGEMENT PURPOSES ONLY. IT IS INTENDED SOLELY FOR THE  INTERNAL USE AND EVALUATION OF THE USER OR SUBSCRIBER AND IS NOT MEANT FOR PUBLIC DISSEMINATION OR RELIANCE FOR ANY OTHER PURPOSE BEYOND CREATIVE ASSESSMENT.
</p>
 
 
    
    <p> BY ACCESSING THIS REPORT, YOU ACCEPT, ACKNOWLEDGE AND AGREE THAT:</p>
    <ol type="1">
    <li>
       <p>THE EVALUATION PROVIDED DOES NOT CONSTITUTE OR IS NOT A SUBSTITUTE FOR PROFESSIONAL LEGAL, FINANCIAL, OR INVESTMENT ADVICE.</p>
    </li>
    
    <li>
        <p>        INCIVUS DISCLAIMS ALL LIABILITIES AND CLAIMS, WHETHER EXPRESS OR IMPLIED, ARISING FROM THE USE OR RELIANCE ON THE CONTENT OF THIS REPORT.
        </p>
    </li>
 
 
    <li>
        <p>THE EVALUATION IS BASED ON THE CONTENT PROVIDED BY YOU ON AS-IS WHERE IS BASIS, DATA ANALYSIS AND ASSUMPTIONS MADE BY THE PLATFORM, AND INCIVUS DOES NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR RELIABILITY OF THE INFORMATION PROVIDED.</p>
    </li>
    
    <li>
        <p>THE OPINIONS, FINDINGS, AND RECOMMENDATIONS EXPRESSED IN THIS REPORT ARE SUBJECTIVE AND MAY NOT REFLECT THE VIEWS OF INCIVUS AND ITS MANAGEMENT.</p>
    </li>
 
 
    <li>
       <p> USERS ARE ENCOURAGED TO CONDUCT THEIR OWN INDEPENDENT RESEARCH AND SEEK QUALIFIED PROFESSIONALS FOR ADVICE TAILORED TO THEIR SPECIFIC CIRCUMSTANCES BEFORE MAKING ANY DECISIONS BASED ON THE INFORMATION IN THIS REPORT.</p>
    </li>
 
    <li>
        <p>INCIVUS SHALL NOT BE LIABLE FOR ANY DAMAGES, LOSSES, OR EXPENSES ARISING FROM ERRORS, OMISSIONS, OR INACCURACIES, IF ANY, IN THE REPORT OR FROM THE USE OF THE INFORMATION CONTAINED HEREIN.</p>
    </li>
 
    <li>
        <p>INCIVUS MAY ADD OR REMOVE SOLUTIONS, FEATURES AND METRICS FROM TIME TO TIME AT ITS SOLE DISCRETION AND THEREFORE, REPORTS MAY DIFFER OVER TIME.</p>
    </li>
 
    </ol>
 
 
<p>
BY ACCESSING THIS REPORT, YOU AGREE TO WAIVE ANY CLAIMS AGAINST INCIVUS  AND ITS AFFILIATES, AGENTS, AND EMPLOYEES, AND TO INDEMNIFY AND HOLD THEM HARMLESS FROM ANY LIABILITIES ARISING FROM YOUR USE OF THIS REPORT.
</p>
<p>
THIS DISCLAIMER IS PERVASIVE AND SUPERSEDES ANY OTHER AGREEMENTS OR UNDERSTANDINGS, WHETHER WRITTEN OR ORAL, RELATING TO THE USE AND DISCLOSURE OF THIS REPORT.
</p>
<p>
IF YOU DO NOT AGREE WITH ANY PART OF THIS DISCLAIMER, YOU MUST NOT ACCESS OR USE THIS REPORT.
</p>
 
<div class="form-footer">
    <div class="form-footer-wrapper">
        <app-button buttonClass="secondary" label="Decline" (click)="declinePolicy()"></app-button>
        <app-button buttonClass="primary"  label="Accept" [myCallback]="acceptPolicy" [callbackParam]="this.id"></app-button>
 
    </div>
</div>
 
