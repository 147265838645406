// utils.ts

/**
 * Capitalizes the first letter of a string and converts the rest to lowercase.
 * @param value - The string to be capitalized.
 * @returns The capitalized string.
 */
export function capitalizeFirstLetter(value: string): string {
  if (!value) return value; // Handle empty or undefined values
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); // Capitalize the first letter
}
