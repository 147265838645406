import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class TrialSignupService {
  constructor(private http: HttpClient) {}

  getCountries(): Observable<any> {
    return this.http.get<any>('assets/data/countries.json');
  }

  getIndustries(): Observable<any> {
    return this.http.get<any>('assets/data/industries.json');
  }
}
