<app-breadcrumb [labels]=breadcrumb fullBC="{{true}}"></app-breadcrumb>
<div class="card-details-container">
    <div class="card-details-wrapper flex-compliance">
        <div class="card-wrapper">
            <nz-card class="compliance-card help-card" [nzBordered]="false">
                <div class="details-card-wrapper">
                    <div class="title">Guidelines and Scores</div>
                    <div class="description">
                        <div class="secBody">
                            <div>
                                <p>Rules provide a simple straightforward binary way of evaluating your Creatives. Rules help you to connect a specific marketing objective and measure your creative strategy outcome. For example, “Is my Ad Copy persuasive?” or “Is my creative imposing a high cognitive load on my viewers which might impact their ability to process the key message effectively?” </p> 
                                <p> On the Incivus platform, you can choose to add more weightage to specific metrics you want to measure and have rules around. In the above example, you may want to create a rule with increased weightage on Persuasiveness of the Ad or Cognitive Load of the Ad. This would impact the final Creative Effectiveness Score (C.E.S) values that will be calculated.  </p>
                                <p> All rules are tracked and measured by your organization is captured in the Rules page. Your administrator will have access to this page. In case, you have provided Incivus Support Team the access to this feature and they are handling the administrative part of your platform, they would be able to view and set Rules for you.</p>
                            </div>   
                        </div>
                    </div>

                    <div class="details-card-wrapper">
                        <div class="title" style="margin-top: 1rem">Rule definitions</div>
                        <div class="description">
                                <div class="secBody">
                                    <div >
                                        The standard guidelines of Incivus on how each metric and sub-metric should be calculated, weights to be assigned and the ranges for plotting the High/Med/Low graphs is defined within the static rule that cannot be edited by anyone. If no custom rule exists, Incivus default rule is applied.
                                    </div>
                            </div>
                        </div>
                    </div>


                    <div class="details-card-wrapper">
                        <div class="title" style="margin-top: 1rem">Creating a new custom rule</div>
                        <div class="description">
                                <div class="secBody">
                                    <div >
                                        The Client Admin has the rights to create custom rules that apply when the creatives are analyzed on Incivus Platform. Follow these steps to create a custom rule:
                                        <div class="title" style="margin-top: 1rem"></div>
                                        
                                        <ol>
                                            <li>Click on the Rules section from the left-hand navigation bar to load the page that already displays the standard Incivus rule shipped out-of-the box. To know the details of this rule, please see the sub-section “Incivus standard rule”.</li>
                                        </ol>    
                                            <div><b>Please Note:</b>The Incivus standard rule is not editable. To know details about this rule, you must refer this help documentation.</div>
                                            <div class="title" style="margin-top: 1rem"></div>
                                        <ol start="2">
                                            <li>Client Admin must have the custom rule definitions handy post internal discussions with Brand Teams.</li>
                                            <li>Client Admin can click on “Add new rules” button on the top-right hand corner to create a new rule.</li>
                                            <li>Rule creation form is displayed to the user with the following options:</li>
                                            <ol type="a">
                                                <li>Rule name</li>
                                                <li><b>Brand:</b> List of all Brands configured for your company is listed here. You can select only 1 brand for 1 rule.</li>
                                                <li><b>Creative types:</b> Video Ads, Display Ads, both</li>
                                                <li><b>Channel:</b> you can select multiple channels. Channels such as Facebook, Instagram, Youtube, Google, etc.</li>
                                                <li><b>Mark as default:</b> Yes or No. If you mark a custom rule as default, the guidelines get applied when a new Ad is uploaded meeting the criteria of the custom rule.</li>
                                            </ol>  
                                            <li>Ranges: You can define your own High, Medium and Low ranges to judge the outcome of the Ad Analysis. Metrics such as Creative Effectiveness Score, Recall Score, Cognitive Load, Ad Copy Effectiveness, Brand Compliance and Emotional Intensity can be selected for custom ranges.</li>
                                        </ol>
                                            <div><b>Please Note:</b>Please note: You can define ranges for the metrics mentioned above. The other metrics will follow the standard ranges defined by Incivus since they are based on extensive research across various industries and categories.</div>
                                            <div class="title" style="margin-top: 1rem"></div>
                                        <ol start="6">
                                            <li>Weights: Every brand focuses on creative elements basis their creative strategy. In this weight definition section, you can view the weights assigned to metrics and adjust the same depending on which creative metric is of higher importance for measurements.</li>
                                        </ol>
                                            <div><b>Please Note:</b>Weights can be adjusted for the following metrics such as Creative effectiveness score, Cognitive load and Ad copy effectiveness.</div>
                                            <div class="title" style="margin-top: 1rem"></div>
                                        <ol start="7">    
                                            <li>Once the custom rule is created, it cannot be edited. However, the rule can be marked as “not default” in case it has been wrongly created.</li>
                                        </ol>   
                                    </div>
                            </div>
                        </div>
                    </div>


                    <div class="details-card-wrapper">
                        <div class="title" style="margin-top: 1rem">Weights for Metrics</div>
                        <div class="description">
                                <div class="secBody">
                                    <div >
                                        <p>Creative Effectiveness metric is a composite score that takes into account Recall, Cognitive Load and Ad Copy effectiveness score.</p>
                                        <p>As per your business need as well as brand focus, you can choose to adjust the weights for these metrics. For example, if your Brand is emphasizing heavily on the Ad Copy being persuasive and impactful, increase the weight for this metric while reducing the weights on Recall and Cognitive Load.</p>
                                        <p>You must ensure the total weight comes to 100. You can choose to assign 0 (zero) weight to a metric. For example: Recall takes weight of 50, Cognitive Load takes weight as 0 (zero) and Ad Copy Effectiveness takes weight of 50. This would ensure only 2 sub-metrics are considered when calculating the overall Creative Effectiveness of your Ads.</p> 
                                    </div>
                            </div>
                        </div>
                    </div>


                    <div class="details-card-wrapper">
                        <div class="title" style="margin-top: 1rem">Ranges for Metrics</div>
                        <div class="description">
                                <div class="secBody">
                                    <div >
                                        <p>The objective of defining ranges (High/Medium/Low) for a metric is to push your teams to deliver higher standards of creative effectiveness across various metrics such as recall, attention, ad copy effectiveness, brand compliance, emotional intensity and more.  </p>
                                        <p>The business reason for lowering the ranges for a metric should be founded on your observation of how your audience responds to that aspect of a creative. For example, if your audience have responded well to your Ads that are cognitively heavy, you can set the medium range of cognitive load as 60 to 90 rather than the Incivus standard of 40 to 80. This will result in most of your Ads falling into the balanced range as per your objective.</p>
                                        <p>Another example would be increasing the ranges for metric such as Ad Copy effectiveness. You can set High range as 90+ which will push your teams to create Ad Copy that are highly effective and measure the outcomes basis these ranges.  </p> 
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div class="details-card-wrapper">
                        <div class="title" style="margin-top: 1rem">Generating Ad Report with custom rules</div>
                        <div class="description">
                                <div class="secBody">
                                    <div >
                                        <p>Once the custom rule is successfully setup and made default, all the creatives that fit the Custom Rule condition of Brand and Creative type will be evaluated basis the custom rule.</p>
                                        <p>The Ad Report that is available post analysis of the creative will contain an information icon informing you about the Custom Rule applied to the metrics. You can click on “view rule” to see the details of the applied rule.</p>
                                        <p><b>Please note:</b> Any changes in an active default rule will impact the next uploaded creative for analysis. The older reports shall continue to show the metric ranges and calculations as per the older definition of the rule.</p> 
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div class="details-card-wrapper">
                        <div class="title" style="margin-top: 1rem">Incivus standard rule</div>
                        <div class="description">
                                <div class="secBody">
                                    <div >
                                        <p>Incivus measures Creative Effectiveness Score of Ads across multiple industries and categories and therefore, the Incivus standard rule allows for all types of creatives to be analyzed in statistically most standardized fashion. We observe overall trends across industries and categories to arrive at weightages and ranges that will fit most Creatives.</p>
                                        <p>Incivus standard ranges for each metric is defined as:</p>
                                        <div class="title" style="margin-top: 1rem"></div>
                                        
                                        <ol>
                                            <li>Creative Effectiveness Score
                                              <ol type="a">
                                                <li>High: Greater than or equal to (>=) 75</li>
                                                <li>Medium: 55 to 75</li>
                                                <li>Low: Less than (<) 55</li>
                                              </ol>
                                            </li>

                                            <li>Recall
                                                <ol type="a">
                                                  <li>High: Greater than or equal to (>=) 75</li>
                                                  <li>Medium: 55 to 75</li>
                                                  <li>Low: Less than (<) 55</li>
                                                </ol>
                                            </li>

                                            <li>Attention
                                                <ol type="a">
                                                  <li>High: Greater than or equal to (>=) 75</li>
                                                  <li>Medium: 55 to 75</li>
                                                  <li>Low: Less than (<) 55</li>
                                                </ol>
                                            </li>

                                            <li>Cognitive Load – High (>=80), Medium (40 to 80), Low (<40) 
                                                <ol type="a">
                                                  <li>Design complexity</li>
                                                  <ol type="i">
                                                    <li>High: Greater than or equal to (>=) 80</li>
                                                    <li>Medium: 40 to 80</li>
                                                    <li>Low: Less than (<) 40</li>
                                                  </ol> 
                                                  <li>Ad Copy complexity</li>
                                                  <ol type="i">
                                                    <li>High: Greater than or equal to (>=) 80</li>
                                                    <li>Medium: 40 to 80</li>
                                                    <li>Low: Less than (<) 40</li>
                                                  </ol> 
                                                </ol>
                                            </li>

                                            <li>Ad Copy Effectiveness: High (>=75), Medium (55 to 75), Low (<55) 
                                                <ol type="a">
                                                  <li>Persuasiveness</li>
                                                  <ol type="i">
                                                    <li>High: 3</li>
                                                    <li>Medium: 2</li>
                                                    <li>Low: 1</li>
                                                  </ol> 
                                                  <li>Text Readability</li>
                                                  <ol type="i">
                                                    <li>High: Greater than or equal to (>=) 75</li>
                                                    <li>Medium: 55 to 75</li>
                                                    <li>Low: Less than (<) 55</li>
                                                  </ol> 
                                                  <li>Attention</li>
                                                  <ol type="i">
                                                    <li>High: Greater than or equal to (>=) 86</li>
                                                    <li>Medium: 70 to 86</li>
                                                    <li>Low: Less than (<) 70</li>
                                                  </ol> 
                                                </ol>
                                            </li>

                                            <li>Brand Compliance 
                                                <ol type="a">
                                                  <li>High: Greater than or equal to (>=) 86</li>
                                                  <li>Medium: 72 to 86</li>
                                                  <li>Low: Less than (<) 72</li>
                                                </ol>
                                            </li>
                                            
                                            <li>Emotional Intensity 
                                                <ol type="a">
                                                  <li>High: Greater than or equal to (>=) 75</li>
                                                  <li>Medium: 55 to 75</li>
                                                  <li>Low: Less than (<) 55</li>
                                                </ol>
                                            </li>
                                            
                                            <li>Digital Accessibility 
                                                <ol type="a">
                                                  <li>Yes: 1</li>
                                                  <li>No: 0</li>
                                                </ol>
                                            </li>
                                        </ol>
                                          
                                    </div>
                            </div>
                        </div>
                    </div>


                </div>
            </nz-card>
        </div>
    </div>
</div>