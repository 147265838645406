import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RuleService } from 'src/app/_services/rule.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rule-matrix-show',
  templateUrl: './rule-matrix-show.component.html',
  styleUrls: ['./rule-matrix-show.component.less']
})
export class RuleMatrixShowComponent implements OnInit {
  ruleId: string | null = null;
  creativeType: string | null = 'DISPLAY';
  apiResponse: any = [];
  apiResponse2: any = [];

  isDisplayAvailable = true;
  isVideoAvailable = true

  customRuleWeightsResponse: any = {};
  constructor(
    private ruleService: RuleService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modal: NzModalService,
    private router: Router,


  ) { }


  breadcrumb: any = [
    {
      name: "Settings",
      link: null
    },
    {
      name: "Rules",
      link: "/custom-rules/list"
    },
    {
      name: "Rules Details",
      link: null
    },
  ];

  ngOnInit() {
    // Subscribe to route parameter changes
    this.route.paramMap.subscribe(params => {
      this.ruleId = params.get('ruleId');
      this.creativeType = params.get('creativeType');


      let cT = localStorage.getItem("@selectedRulecreativeType")?.split(',')
      
      if(cT?.length == 1 && cT[0].includes("Display")){
        this.isVideoAvailable = false
      }
      if(cT?.length == 1 && cT[0].includes("Video")){
        this.isDisplayAvailable = false
      }

      // Make sure to call the API only if both parameters are available
      if (this.ruleId && this.creativeType) {
        this.fetchRuleData(this.ruleId, this.creativeType);
      }
    });
  }
  goBack() {
    this.router.navigate(["custom-rules", "list"])
  }
  fetchRuleData(ruleId: string, creativeType: string) {
    this.ruleService.showRule(ruleId, creativeType).subscribe(
      (data: any) => {

        if (data.message == "No Data") {
          this.modal.error({
            nzTitle: 'Error',
            nzContent: 'No Data Found',
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => {this.router.navigate(["custom-rules", "list"]);}  
          });
        } else {
          this.apiResponse = data.data;
          this.apiResponse2 = data;
          this.customRuleWeightsResponse = Object.entries(data.customRuleWeightsResponse).map(([key, value]) => {
            return {
              name: key,
              scores: value
            };
          });
        }
      },
      (error) => {
        console.error('Error fetching rule data:', error);
      }
    );
  }
}
