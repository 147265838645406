import { Component, Input } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { EventBusService } from "src/app/_shared/event-bus.service";
import { EventData } from "src/app/_shared/event.class";
import { AppServices } from "src/app/_services/app.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import * as TrialSignupValidators from "../../../../components/trial-signup/trial-signup.validators";
import { TrialSignupService } from "src/app/components/trial-signup/trial-signup.service";

@Component({
  selector: "app-user-create-modal",
  templateUrl: "./userCreate.component.html",
  styleUrls: ["./userCreate.component.less"],
})
export class UserCreateComponent {
  @Input() public data: any;
  @Input() public type: any;

  userForm = new FormGroup({
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    status: new FormControl(""),
    customIndustry: new FormControl("", [
      Validators.maxLength(50),
      TrialSignupValidators.textOnlyValidator(),
    ]),
    customCategory: new FormControl("", [
      Validators.maxLength(50),
      TrialSignupValidators.textOnlyValidator(),
    ]),
    companyName: new FormControl(""),
    department: new FormControl(""),
    industry: new FormControl(""),
    category: new FormControl(""),
    emailId: new FormControl("", [Validators.required]),
    phone: new FormControl(""),
    country: new FormControl(""),
    jobRole: new FormControl(""),
  });
  blurFN: boolean = false;
  blurLN: boolean = false;
  blurEmail: boolean = false;
  trialCompany: boolean = false;
  isTrialSignupCompany: boolean = false;// "Trial Company" company for Product Trials
  errorMsg: string = "";
  user: any;
  isClientAdmin: boolean = false;
  isBothAdmin: boolean = false;
  isServiceRequest: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  formOptions: any = {};
  selectedIndustry: any;
  selectedCountry: any;
  phoneMaxLength = 10;
  source: string = "";
  isNull:boolean=false;
  customIndustryOption: any = { name: "custom", categories: ["Other"] };

  constructor(
    private formBuilder: FormBuilder,
    private eventBusService: EventBusService,
    private appService: AppServices,
    private modal: NzModalService,
    private router: Router,
    private trialSignupService: TrialSignupService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.trialCompany =
        this.data &&
        this.data.client &&
        this.data.client.status &&
        this.data.client.status.toLowerCase() === "trial"
          ? true
          : false;
      this.isTrialSignupCompany = this.data.isTrialSignupCompany;    
      this.isServiceRequest =
        this.data?.client?.featureAccess?.serviceRequest ?? false;
      if (this.data && this.data.user && this.data.user.length == 1) {
        this.user = this.data.user[0];
      } else {
        this.resetForm();
      }
      console.log('type dataaaaaaaaaaaaaaaaaaaaaaaaaa', this.data)
      console.log("userCreate ngOnInit client", this.data.client);

      this.isClientAdmin = this.data.client.isClientAdmin;
      this.isBothAdmin = this.data.client.isBothAdmin;

      if(localStorage.getItem('checked')!=null){
        if(localStorage.getItem('checked')=='true'){
            this.isBothAdmin=true;
            this.data.client.isBothAdmin=true;
        }
        else {
          this.isBothAdmin=false;
          this.data.client.isBothAdmin=false;
        }

      }

      if (this.isTrialSignupCompany) {
        this.userForm = new FormGroup({
          firstName: new FormControl("", [Validators.required]),
          lastName: new FormControl("", [Validators.required]),
          status: new FormControl("", [Validators.required]),
          customIndustry: new FormControl("", [
            Validators.maxLength(50),
            TrialSignupValidators.textOnlyValidator(),
          ]),
          customCategory: new FormControl("", [
            Validators.maxLength(50),
            TrialSignupValidators.textOnlyValidator(),
          ]),
          companyName: new FormControl("", [Validators.maxLength(100)]),
          department: new FormControl("", [Validators.maxLength(100)]),
          industry: new FormControl("", [Validators.maxLength(50)]),
          category: new FormControl(""),
          emailId: new FormControl("", [
            Validators.required,
            Validators.email,
            // For company email validator, you'll likely need to pass a reference to companyName
            TrialSignupValidators.companyEmailValidator(),
          ]),
          phone: new FormControl("", [
            TrialSignupValidators.phoneNumberValidator(),
          ]),
          country: new FormControl(""),
          jobRole: new FormControl("", [
            Validators.maxLength(50),
            TrialSignupValidators.textOnlyValidator(),
          ]),
        });
        this.formOptions = {};
        this.trialSignupService.getCountries().subscribe((data) => {
          this.formOptions.countries = data;
        });
        this.trialSignupService.getIndustries().subscribe((data) => {
          this.formOptions.industries = data;
        });
      }
      // potentially after formOptions is filled
      setTimeout(() => {
        if (this.user != null) {
          if (this.user.industry != null) {
            let industry = null;
            if (typeof this.user.industry == "string") {
              // fetch the industry
              [industry] = this.formOptions.industries.filter(
                (item: any) => item.name == this.user.industry
              );
            } else {
              // when the form is reopened
              [industry] = this.formOptions.industries.filter(
                (item: any) => item.name == this.user.industry.name
              );
              this.user.industry = this.user.customIndustry;
              this.user.category =
                this.user.customCategory ?? this.user.category;
            }
            let customIndustry = "";
            let customCategory = "";

            if (industry == null) {
              industry = "custom";
              customIndustry = this.user.industry;
              customCategory = this.user.category;
              this.user.industry = this.customIndustryOption;
              this.user.category = this.customIndustryOption.categories[0];
              this.user.customIndustry = customIndustry;
              this.user.customCategory = customCategory;
            } else {
              this.selectedIndustry = industry;
              this.user.industry = industry;
            }
          }
        this.user.emailId = this.user.email;

      }
        this.userForm.patchValue(this.user);

        if(this.userForm.controls.firstName.value!=null && this.userForm.controls.lastName.value!=null && this.userForm.controls.emailId.value!=null ){
          this.isNull=true;
        }
      }, 60);
      if (this.isTrialSignupCompany) {
        this.initIndustryChange();
        this.initCountryChange();
      }
    }, 10);

    this.eventBusService.readEvent
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((action) => {
        if (action.name === "toggle_service_request") {
          // Based on "Service Request" feature turned on or off
          this.isServiceRequest = action?.value?.toggle;
        }
      });
  }

  initIndustryChange() {
    this.userForm.get("industry")?.valueChanges.subscribe((data: any) => {
      this.selectedIndustry = data;
      let isCustom = data?.name == "custom";
      this.userForm.controls["category"].setValue(
        isCustom && data.categories?.length > 0 ? data.categories[0] : null
      );
    });
  }
  initCountryChange() {
    // Listen for changes in the selected country
    this.userForm.get("country")?.valueChanges.subscribe((country: any) => {
      this.phoneMaxLength = country?.mobile_number_length ?? 10;

      // Update the phone number validator
      this.userForm.get("phone")?.clearValidators();
      this.userForm
        .get("phone")
        ?.addValidators(
          TrialSignupValidators.phoneNumberValidator(this.phoneMaxLength)
        );
      this.userForm.get("phone")?.updateValueAndValidity();
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }
  getErrorMessage(controlName: string): string {
    
    const control = this.userForm.get(controlName);
    return TrialSignupValidators.getErrorMessage(control, controlName);
  }

  cancel(): void {
    if (this.user) {
      this.appService.deleteUser(this.user.id).subscribe({
        next: (data) => {
          this.modal.info({
            nzTitle: "Success",
            nzContent: "User deleted successfully",
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => {
              this.user = null;
              this.isNull=false;
              this.resetForm();
            },
          });
        },
        error: (err) => {
          this.modal.error({
            nzTitle: "Error",
            nzContent: "Unable to delete user, please try again later",
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => console.log("Info OK"),
          });
        },
      });
    } else {
      this.resetForm();
       this.eventBusService.emit(new EventData("toggle_add_user", false));
    }
  }

  resetForm(): void {
    this.userForm.reset({ status: "inactive" });
  }

  saveUser(): void {
    this.eventBusService.emit(new EventData("company_form_error", ""));
    this.errorMsg = "";
    console.log(
      `userCreateComponent saveUser() isClientAdmin:${
        this.isClientAdmin
      } trialCompany:${
        this.trialCompany
      } (this.isClientAdmin && !this.trialCompany) = ${
        this.isClientAdmin && !this.trialCompany
      }`
    );

    localStorage.setItem('checked', JSON.stringify(this.isBothAdmin))

    Object.keys(this.userForm.controls).forEach((key) => {
      const control = this.userForm.get(key) as AbstractControl;
      // Do something with the control
      if (control.status != "VALID") {
        console.log(
          `${key} status: ${control.status} errors: `,
          control.errors
        );
      }
    });

    if (
      this.isClientAdmin == true &&
      (this.data.client.featureAccess.inFlight.isAvailable ||
        this.data.client.featureAccess.postFlight.isAvailable)
    ) {
      const error =
        "Please note, this Client has access to in-flight and post-flight features that would require channel setup. Please add Client side admin and allow access to Incivus admin.";
      this.eventBusService.emit(new EventData("company_form_error", error));
      return;
    }
    // I'll Administer = true  & Not in trial mode
    if (this.isClientAdmin && !this.trialCompany) {
      if (this.user && this.user.id) {
        // update user
        let userData: any = this.userForm.getRawValue();
        userData["status"] = "inactive";
        userData["id"] = this.user.id;
        userData["clientId"] = this.data.client.id;
        this.appService.editUser(userData);
      }
      // update client
      if (this.data.client) {
        let clientData: any = {};
        clientData.id = this.data.client.id;
        clientData.featureAccess = this.data.client.featureAccess;
        clientData.name = this.data.client.companyName;
        clientData.status = this.data.client.status;
        clientData.oversight = this.data.client.oversight;
        clientData.isClientAdmin = this.isClientAdmin;
        clientData.noOfUser = this.data.client.noOfUser;

        console.log("editClient", this.data);

        this.appService.editClient(clientData).subscribe({
          next: (data) => {
            // this.data.client.isClientAdmin = this.isClientAdmin;
            if (this.isServiceRequest) {
              this.eventBusService.emit(
                new EventData("on_admin_setup_complete", true)
              );
            } else
              this.modal.success({
                nzTitle: "Success",
                nzContent: "Customer setup successfully completed",
                nzMaskClosable: false,
                nzKeyboard: false,
                nzOnOk: () => {
                  this.router.navigate(["client", "list"]);
                },
              });
          },
          error: (err) => {
            this.modal.error({
              nzTitle: "Error",
              nzContent:
                "We are facing some technical issue pleae try again later",
              nzMaskClosable: false,
              nzKeyboard: false,
              nzOnOk: () => {},
            });
          },
        });
      }
    } else {
      let userData: any = this.userForm.getRawValue();
      // console.log("update user in trial mode:", userData);
      if (this.userForm.status.toLowerCase() === "invalid") {
        const error =
          "Oops! You might have left one or more mandatory fields empty. Please cross check once and add the necessary information.";
        this.errorMsg = error;
        // this.eventBusService.emit(new EventData("company_form_error", error));
        console.log("update user in trial mode:", userData);
        let keys = Object.keys(this.userForm.controls);
        for (const key of keys) {
          const control = this.userForm.get(key) as AbstractControl;
          // Do something with the control
          if (control.status != "VALID") {
            console.log(
              `${key} status: ${control.status} errors: `,
              control.errors
            );
            this.errorMsg = this.getErrorMessage(key);
            console.log(`${key} errorMsg: `, control.errors, this.errorMsg);
            // Exit the loop early if a control is invalid
            break; // This exits the entire loop
          }
        }

        return;
      }

      if (this.user && this.user.id) {
        // update admin/trial user
        let userData: any = this.userForm.getRawValue();
        if (this.trialCompany) {
          userData["role"] = "TRIAL_USER";
        } else {
          userData["role"] = "CL_ADMIN";
        }
        userData["id"] = this.user.id;
        userData["clientId"] = this.data.client.id;
        userData["isBothAdmin"] = this.isBothAdmin;
        userData["industry"] = userData.industry?.name;        

        // update user in trial mode
        console.log("update user in trial mode:", userData);
        Object.keys(this.userForm.controls).forEach((key) => {
          const control = this.userForm.get(key) as AbstractControl;
          // Do something with the control
          if (control.status != "VALID") {
            console.log(
              `${key} status: ${control.status} errors: `,
              control.errors
            );
          }
        });
        if (userData.industry == "custom") {
          userData.industry = userData.customIndustry;
          userData.category = userData.customCategory;
        }
        delete userData.customIndustry;
        delete userData.customCategory;
        this.appService.editUser(userData).subscribe({
          next: (data) => {
            console.log(
              "userCreateComponent saveUser() appService.editUser",
              userData,
              this.data
            );

            this.data.client.isBothAdmin = this.isBothAdmin;

            if (this.isServiceRequest) {
              this.eventBusService.emit(
                new EventData("on_admin_setup_complete", true)
              );
            } else console.log("show modal");

            this.modal.info({
              nzTitle: "Success",
              nzContent: "User updated successfully",
              nzMaskClosable: false,
              nzKeyboard: false,
              nzOnOk: () => {
                if (this.trialCompany) {
                  this.eventBusService.emit(
                    new EventData("trial_user_saved", "")
                  );
                } else {
                  this.user = data;
                  this.router.navigate(["client", "list"]);
                }
              },
            });
          },
          error: (err) => {
            if (this.type && this.type == "modal") {
              if (err.error && err.error.message) {
                this.errorMsg = err.error.message;
              } else {
                this.errorMsg =
                  "We are facing some glitches, please try again later.";
              }
            } else {
              if (err.error && err.error.message) {
                this.eventBusService.emit(
                  new EventData("company_form_error", err.error.message)
                );
              } else {
                this.eventBusService.emit(
                  new EventData(
                    "company_form_error",
                    "We are facing some glitches, please try again later."
                  )
                );
              }
            }
          },
        });
      } else {
        let userData: any = this.userForm.value;
        if (this.trialCompany) {
          userData["role"] = "TRIAL_USER";
        } else {
          userData["role"] = "CL_ADMIN";
          userData["isBothAdmin"] = this.isBothAdmin;
        }
        userData["clientId"] = this.data.client.id;
        userData["industry"] = userData.industry?.name;
        userData["isTrialSignup"] = this.isTrialSignupCompany;

        // update user in trial mode
        console.log("add user in trial mode:", userData);
        // return;
        Object.keys(this.userForm.controls).forEach((key) => {
          const control = this.userForm.get(key) as AbstractControl;
          // Do something with the control
          if (control.status != "VALID") {
            console.log(
              `${key} status: ${control.status} errors: `,
              control.errors
            );
          }
        });
        if (userData.industry == "custom") {
          userData.industry = userData.customIndustry;
          userData.category = userData.customCategory;
        }
        delete userData.customIndustry;
        delete userData.customCategory;

        this.appService.saveUser(userData).subscribe({
          next: (data) => {
            // this.data.client.isBothAdmin = this.isBothAdmin;
            
            if (this.isServiceRequest) {
              this.eventBusService.emit(
                new EventData("on_admin_setup_complete", true)
              );
            } 
            
              this.modal.info({
                nzTitle: "Success",
                nzContent: "User created successfully",
                nzMaskClosable: false,
                nzKeyboard: false,
                nzOnOk: () => {
                  if (this.trialCompany) {
                    this.eventBusService.emit(
                      new EventData("trial_user_saved", "")
                    );
                  } else {
                    this.user = data;
                    this.router.navigate(["client", "list"]);
                  }
                },
              });
          },
          error: (err) => {
            
            if (this.type && this.type == "modal") {
              if (err.error && err.error.message) {
                this.errorMsg = err.error.message;
              } else {
                this.errorMsg =
                  "We are facing some glitches, please try again later.";
              }
            } else {
              if (err.error && err.error.message) {
                this.eventBusService.emit(
                  new EventData("company_form_error", err.error.message)
                );
              } else {
                this.eventBusService.emit(
                  new EventData(
                    "company_form_error",
                    "We are facing some glitches, please try again later."
                  )
                );
              }
            }
          },
        });
      }
    }
  }

  buttonCallback = () => {
    console.log("isCompSetupCollapse");
  };
}
