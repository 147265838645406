import { Component, OnInit } from "@angular/core";
import { StorageService } from "src/app/_services/storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.less"],
})
export class ContentComponent implements OnInit {
  public showAlert: boolean = true;
  errorMsg: String | null = null;
  user: any;

  constructor(private storageService: StorageService) {}
  ngOnInit(): void {
    setTimeout(() => {
      this.user = this.storageService.getUser();
      let diff = this.noOfDays();

      if (this.user?.isTrialSignup && diff >= 25) {
        let endsIn = diff < 30 ? 30 - diff : 0;
        this.errorMsg = `Your trial period is ending after ${endsIn} ${
          endsIn == 1 ? "day" : "days"
        }. We hope you are using the Incivus platform to make effective creative decisions.`;
      }
    }, 10);
  }

  noOfDays() {
    const createdAtDate = new Date(this.user?.createdAt);
    const currentDate = new Date();
    const differenceInMilliseconds =
      currentDate.getTime() - createdAtDate.getTime();
    const differenceInDays = Math.ceil(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }
}
