<nz-content>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <!-- <app-alert *ngIf="showAlert"></app-alert> -->
     <app-alert
  [message]="errorMsg"
  [alertType]="'error'"
  *ngIf="errorMsg"
></app-alert>
    <router-outlet></router-outlet>
</nz-content>
