import { Component, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppServices } from 'src/app/_services/app.service';
import { StorageService } from 'src/app/_services/storage.service';
import { DynamicModalComponentService } from 'src/app/common/services/dyamic-modal-component.service';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';
import { Range } from 'src/app/common/models/range.model';
import { RuleService } from 'src/app/_services/rule.service';


@Component({
  selector: 'app-creative-details',
  templateUrl: './creative-details.component.html',
  styleUrls: ['./creative-details.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CreativeDetailsComponent {
  warningMsg: string = "";
  alertType: string = "";
  artifactId: string = '';
  loading: boolean = true;
  primaryButton: string = '';
  creatives: any = {};
  isAnalyze: boolean = false;
  isReport: boolean = false;
  client: any = {};
  user: any = {};
  isIncAdmin: boolean = false;
  isTrialUser: boolean = false;
  createdBy: string = "";
  currentDate: string = "";
  timeperiod = 100;
  timeperiod1: Date;
  clientId: string = "";
  isTrial: boolean = false;
  channels: string[] = [];
  ruleResponse: any = {}



  breadcrumb: any = [
    {
      name: "Creatives",
      link: "/creatives/pre-flight/list",
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: AppServices,
    private modal: NzModalService,
    private storage: StorageService,
    private viewContainerRef: ViewContainerRef,
    private dynamicServiceModal: DynamicModalComponentService,
    private ruleService: RuleService,

  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.artifactId = this.route.snapshot.params["artifactId"];

      if (this.artifactId) {
        this.user = this.storage.getUser();
        this.client = this.user.client;
        this.clientId = this.storage.clientId;
        if (this.user.disclaimerAccDate != null || this.user.disclaimerAccDate != "null") {
          this.getCurrentDate();
          this.timeperiod1 = new Date(this.user.disclaimerAccDate);
          this.getDiff();
        } else {
          this.timeperiod = 100;
        }
        if (this.user && this.user.roles.includes("INCIVUS_ADMIN")) {
          this.isIncAdmin = true;
        }
        if (this.user && this.user.roles.includes("TRIAL_USER")) {
          this.isTrialUser = true;
        }
        this.isAnalyze = this.isIncAdmin || this.isTrialUser || (this.user.permission && this.user.permission.isAnalyze);
        this.loadArtifact();
        if (this.user.name === 'Incivus Admin') {
          this.getClientDetails();
        }
      } else {
        this.modal.error({
          nzTitle: 'Error',
          nzContent: "We didnt find what you are looking for. Please try again later",
          nzClassName: "small-modal",
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => {
            this.router.navigate(["creatives", "pre-flight", "list"]);
          },
        });
      }
    }, 10);
  }

  getClientDetails(): void {
    this.service.getClientDetails(this.clientId).subscribe({
      next: (data: any) => {
        if (data) {
          this.client = data;
          if ("trial" === data?.client?.status?.toLowerCase()) {
            this.isTrial = true;
          }
        }
      },
      error: (error: any) => {
        console.error("Error fetching client details", error);
      }
    });
  }

  capitalizeFirstLetter(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  //this.timeperiod=100;
  loadArtifact(): void {
    this.service.getCreativeDetails(this.artifactId).subscribe({
      next: (data: any) => {
        if (data) {
          this.creatives = data;
          // let channelString = this.creatives.metadata.channel;
          // this.channels = JSON.parse(channelString);
          if (this.breadcrumb.length === 1) {
            this.breadcrumb.push({ name: this.capitalizeFirstLetter(this.creatives.metadata.title), link: null, bold: true });
          }


          switch (this.creatives.metadata.status.toString()) {
            case '0':
              this.warningMsg = "You can analyze a creative any time after its uploaded in the system. This ad is not analyzed yet.";
              this.alertType = "warning";
              this.primaryButton = "analyze";
              break;
            case '10':
              this.warningMsg = "The creative is currently being analyzed. Will let you know once the reports are ready.";
              this.alertType = "warning";
              this.primaryButton = "";
              break;
            case '50':
              this.warningMsg = "Your creative analysis has failed. You can try analyzing it again."
              this.alertType = "error";
              this.primaryButton = "reanalyze";
              break
            case '100':
              this.warningMsg = "The analysis of your creative is succesfully completed. You can view the report."
              this.alertType = "info";
              this.primaryButton = "report";
              if (!(this.isIncAdmin || this.isTrialUser)) {
                if (this.creatives.metadata.type === 'image') {
                  if (this.client.featureAccess.imageAd.analyze.viewReport) {
                    if (this.user.permission.viewSummaryPage || this.user.permission.viewFullReport) {
                      this.isReport = true
                    }
                  }
                } else {
                  if (this.client.featureAccess.videoAd.analyze.viewReport) {
                    if (this.user.permission.viewSummaryPage || this.user.permission.viewFullReport) {
                      this.isReport = true;
                    }
                  }
                }
              } else {
                this.isReport = true;
              }
              break;
            case '90':
              if (this.isIncAdmin) {
                this.warningMsg = "The analysis of your creative is succesfully completed. You can view the report."
                this.alertType = "info";
                this.primaryButton = "report";
                this.isReport = true;
              } else {
                this.warningMsg = "The creative is currently being analyzed. Will let you know once the reports are ready.";
                this.alertType = "warning";
                this.primaryButton = "";
                break;
              }
              break;
          }
          this.loading = false;
        } else {
          this.modal.error({
            nzTitle: 'Error',
            nzContent: "We didnt find what you are looking for. Please try again later",
            nzClassName: "small-modal",
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => {
              this.router.navigate(["creatives", "pre-flight", "list"]);
            },
          });
        }
      },
      error: err => {
        this.modal.error({
          nzTitle: 'Error',
          nzContent: "We didnt find what you are looking for. Please try again later",
          nzClassName: "small-modal",
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => {
            this.router.navigate(["creatives", "pre-flight", "list"]);
          },
        });
      },
    });
  }

  cancel = () => {
    this.router.navigate(["creatives", "pre-flight", "list"]);
  };

  viewReport = (params: any) => {

    this.ruleService.getRuleOnReport(params.id, params.artifactType == "video" ? 'video' : 'image').subscribe(
      (data) => {
        this.ruleResponse = data;
        let ruleResponseCreativeEffectivenessRange = this.ruleResponse?.data?.customRuleRangesResponse.filter((i: any) => i.name.toLowerCase().includes("Creative Effectiveness Score".toLowerCase()))[0]?.customRuleRangeScores
        let ruleResponseCognitiveRange = this.ruleResponse?.data?.customRuleRangesResponse.filter((i: any) => i.name.toLowerCase().includes("Cognitive Load".toLowerCase()))[0]?.customRuleRangeScores

        let ruleResponseRecallRange = this.ruleResponse?.data?.customRuleRangesResponse.filter((i: any) => i.name.toLowerCase().includes("Recall Score".toLowerCase()))[0]?.customRuleRangeScores
        let ruleResponseAdCopyRange = this.ruleResponse?.data?.customRuleRangesResponse.filter((i: any) => i.name.toLowerCase().includes("Ad Copy Effectiveness".toLowerCase()))[0]?.customRuleRangeScores

        let ruleResponseBrandComplianceRange = this.ruleResponse?.data?.customRuleRangesResponse.filter((i: any) => i.name.toLowerCase().includes("Brand Compliance".toLowerCase()))[0]?.customRuleRangeScores
        let ruleResponseEmotionalIntensityRange = this.ruleResponse?.data?.customRuleRangesResponse.filter((i: any) => i.name.toLowerCase().includes("Emotional Intensity".toLowerCase()))[0]?.customRuleRangeScores


        Range.RECALLL = ruleResponseRecallRange[0]?.to
        Range.RECALLH = ruleResponseRecallRange[1]?.to
        Range.ADCOPYEH = ruleResponseAdCopyRange[1]?.to
        Range.ADCOPYEL = ruleResponseAdCopyRange[0]?.to



        localStorage.setItem("@localArtifactDataRecommendedCESlowfrom", ruleResponseCreativeEffectivenessRange[0]?.from);
        localStorage.setItem("@localArtifactDataRecommendedCESmediumfrom", ruleResponseCreativeEffectivenessRange[1]?.from);
        localStorage.setItem("@localArtifactDataRecommendedCEShighfrom", ruleResponseCreativeEffectivenessRange[2]?.from);

        localStorage.setItem("@localArtifactDataRecommendedCESlowend", ruleResponseCreativeEffectivenessRange[0]?.to);
        localStorage.setItem("@localArtifactDataRecommendedCESmediumend", ruleResponseCreativeEffectivenessRange[1]?.to);
        localStorage.setItem("@localArtifactDataRecommendedCEShighend", ruleResponseCreativeEffectivenessRange[2]?.to);


        localStorage.setItem("@clLowFrom", ruleResponseCognitiveRange[0]?.from);
        localStorage.setItem("@clLowTo", ruleResponseCognitiveRange[0]?.to);
        localStorage.setItem("@clMedFrom", ruleResponseCognitiveRange[1]?.from);
        localStorage.setItem("@clMedTo", ruleResponseCognitiveRange[1]?.to);
        localStorage.setItem("@clHighFrom", ruleResponseCognitiveRange[2]?.from);
        localStorage.setItem("@clHighTo", ruleResponseCognitiveRange[2]?.to);



        this.router.navigate(['reports', params.id]);

      },
      (err) => {
        console.log(err);
      }
    )

  }

  analyze = () => {
    this.service.analyzeCreative(this.creatives.metadata.id).subscribe({
      next: (data: any) => {
        this.modal.success({
          nzTitle: "Success",
          nzContent: "We are analyzing the creative now. Check back in few minutes for the report.",
          nzClassName: "small-modal",
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => {
            this.loadArtifact();
          }
        });
      },
      error: err => {
        this.modal.error({
          nzTitle: "Error",
          nzContent: "We are unable to analyze it, please try again later",
          nzClassName: "small-modal",
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => {
            this.loadArtifact();
          }
        });
      }
    });
  }

  reanalyze = () => {
    this.service.retryAnalyzeCreative(this.creatives.metadata.id).subscribe({
      next: (data: any) => {
        this.modal.success({
          nzTitle: "Success",
          nzContent: "We have reinitiated the analysis. Check back in few minutes for the report.",
          nzClassName: "small-modal",
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => {
            this.loadArtifact();
          }
        });
      },
      error: err => {
        this.modal.error({
          nzTitle: "Error",
          nzContent: "We are unable to reinitiate the analysis now, please try again later",
          nzClassName: "small-modal",
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => {
            this.loadArtifact();
          }
        });
      }
    });
  }
  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');
    const timezoneOffset = currentDate.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60)).toString().padStart(2, '0');
    const timezoneOffsetMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
    const timezoneSign = timezoneOffset < 0 ? '+' : '-';

    this.currentDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
  }

  getDiff() {
    const t1 = this.timeperiod1;
    const t2 = new Date(this.currentDate);

    const timeDiff = Math.abs(t1.getTime() - t2.getTime());
    const daysDiff = (timeDiff / (1000 * 60 * 60 * 24));
    this.timeperiod = daysDiff;
  }

  popup = (id: string) => {
    let className = 'report-details-modal';
    this.dynamicServiceModal.createComponentModal('', DisclaimerComponent, this.viewContainerRef, { data: id, value: 'creative' }, className)
  }
}

