<div class="card-details-container colorContainer">
    <div class="colorBoard">
        <div style="display: flex;flex-direction: column; margin-right: 0.2rem;">
            <div style="width: 2.5rem;height: 3rem;margin-bottom: 0.3rem;" [style.background-color]="color.p1"></div>
            <div style="display: flex;justify-content: space-around;">
                <div style="width: 1rem;height: 1rem;border-radius: 50%;" [style.background-color]="color.p2"></div>
                <div style="width: 1rem;height: 1rem;border-radius: 50%;" [style.background-color]="color.p3"></div>
            </div>
        </div>
        <div style="display: flex;flex-direction: column;  margin-right: 0.2rem;">
            <div style="width: 2.5rem;height: 1.5rem;margin-bottom: 0.3rem;" [style.background-color]="color.p4"></div>
            <div style="width: 2.5rem;height: 2.5rem;" [style.background-color]="color.p5"></div>
        </div>
        <div style="display: flex;flex-direction: column;">
            <div style="width: 2.5rem;height: 1.3rem;margin-bottom: 0.3rem;" [style.background-color]="color.p6"></div>
            <div style="width: 2.5rem;height: 1.2rem;margin-bottom: 0.3rem;" [style.background-color]="color.p7"></div>
            <div style="width: 2.5rem;height: 1.2rem;" [style.background-color]="color.p8"></div>
        </div>
    </div>
    <div class="colorText" style="margin-top: 1rem;">Color mood-board</div>
</div>