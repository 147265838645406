import { Component } from '@angular/core';

@Component({
  selector: 'app-show-rule',
  templateUrl: './show-rule.component.html',
  styleUrls: ['./show-rule.component.less']
})
export class ShowRuleComponent {

}
