<div class="droupdown">
  <div nz-dropdown [nzDropdownMenu]="menu4" class="content">
    Type
    <span nz-icon nzType="down"></span>
  </div>
  <nz-dropdown-menu #menu4="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>menu4 1st menu item</li>
      <li nz-menu-item>menu4 2nd menu item</li>
      <li nz-menu-item>menu4 3rd menu item</li>
    </ul>
  </nz-dropdown-menu>
</div>




<div class="card-container">
  <nz-card *ngFor="let card of cards" [nzHoverable]="true" class="card">
    <p class="card-heading">{{card.heading}}</p>
    <h4 class="card-value"><b>{{card.value}}</b></h4>
    <p class="card-points">
      <b [ngClass]="{'positive': isPositive(card.points), 'negative': isNegative(card.points)}">
        {{card.points}}
      </b>
    </p>
    <small class="card-median">{{card.median}}</small>
  </nz-card>
</div>

<div class="chart-container">
  <nz-card *ngFor="let card of chartCards" [nzHoverable]="true" class="custom-card">
    <div *ngIf="card.first_svg && card.isSummary" class="file-img">
      <!-- SVG Icon and Summary Text -->
      <div class="summary-header">
        <img [src]="card.first_svg" alt="SVG Icon" class="svg-icon">
        <div class="file-info">
          <b>Summary</b>
          <p>Summary of benchmark report</p>
        </div>
      </div>
    
      <!-- Points Section -->
      <ng-container *ngIf="card.isSummary">
        <ng-container *ngFor="let point of card.points">
          <div class="info-point">
            <div class="ready">
              <div class="count">
                <p class="point-mark">{{point.number}}</p>
              </div>
            </div>
            <div class="point-info">
              <b>{{point.title}}</b>
              <p class="info">{{point.description}}</p>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    

    <!-- Gantt Chart -->
    <div *ngIf="card.isGanttChart">
      <figure class="highcharts-figure">
        <highcharts-chart 
          [Highcharts]="Highcharts" 
          [options]="card.chartOptions">
        </highcharts-chart>
        <div class="range-labels">
          <span class="range-label start-label">0</span>
          <span class="range-label end-label">100</span>
        </div>
      </figure>
    </div>

    <!-- Bar Chart -->
    <div *ngIf="card.isBarChart">
      <figure class="chart-wrapper">
        <highcharts-chart 
          [Highcharts]="Highcharts" 
          [options]="card.chartOptions" 
          class="chart-box">
        </highcharts-chart>
      </figure>
    </div>
    
  </nz-card>
</div>
