import { HttpParams } from '@angular/common/http';
import { Component,Input } from '@angular/core';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.less']
})
export class PreviewImageComponent {
   @Input() public data: any;
   brandAssets:any=[];
   user: any;
   showButton: boolean = false;
   cacheBuster = new Date().getTime();


   ngOnInit(){
    this.brandAssets=this.data.data;
    this.user = this.storageService.getUser();
    console.log("heyyyyyyyyy---->",this.user)
    this.showButton = this.user?.roles?.includes('INCIVUS_ADMIN') || this.user?.roles?.includes('SERVICE_MANAGER');
   }
   
   constructor(
    private storageService: StorageService,
  ) {}

downloadAllImages() {
  const params = new HttpParams().set('cacheBuster',this.cacheBuster.toString())
  this.brandAssets.forEach((url: any, index: number) => {
     const finalUrl = `${url}?${params.toString()}`

      this.fetchAndDownloadImage(finalUrl, `image-${index + 1}${this.getFileExtensionFromUrl(url)}`);
  });
}

getFileExtensionFromUrl(url: string): string {
  return url.substring(url.lastIndexOf('.')); // Get the extension from the URL (e.g., .jpg, .png)
}

fetchAndDownloadImage(url: string, filename: string) {

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the object URL after the download is triggered
      URL.revokeObjectURL(objectUrl);
    })
    .catch(error => {
      console.error('Error downloading image:', error);
    });
}

}

