import { Component, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
// import { HighchartsChartModule } from 'highcharts-angular';
@Component({
  selector: 'app-report-benchmark',
  templateUrl: './report-benchmark.component.html',
  styleUrls: ['./report-benchmark.component.less']
})
export class ReportBenchmarkComponent {

  Highcharts: typeof Highcharts = Highcharts;
  cards = [
    {  heading:'CSE', value: '75.66', points:'+7618 points',median:'vs industry median'},
    {  heading:'Recall',value: '67.66',points:'-12.22 points',median:'vs industry median' },
    {  heading:'Cognitive Load',value: '35.66', points:'+1 points',median:'vs industry median'},
    {  heading:'Ad copy effectiveness',value: '85.66',points:'-10.17 points',median:'vs industry median' },
    {  heading:'Brand Compliance',value: '73',points:'-13 points',median:'vs industry median' },
  ];


  isPositive(points: string): boolean {
    return points.startsWith('+');
  }

  // Check if points are negative
  isNegative(points: string): boolean {
    return points.startsWith('-');
  }
chartCards = [
  {
    isSummary: true,
    first_svg: '../../../../assets/icons/file.svg',
    points: [
      {
        number: '1',
        title: 'Creative Effectiveness Score',
        description: 'Ad CES Score of 75.66 is -7.9 points lower than Industry Highest CES score.'
      },
      {
        number: '2',
        title: 'Recall Score',
        description: 'The Ad Recall Score of 67.66 is -13 points lower than Industry Highest Recall Score.'
      },
      {
        number: '3',
        title: 'Cognitive Load Score',
        description: 'The Ad Cognitive Load Score of 35.66 is -5 points lower than the Industry’s balanced cognitive load score.'
      }
    ]
  },
  {
    heading: 'Project Schedule',
    isGanttChart: true,
    chartOptions: <Highcharts.Options>{
      chart: {
        plotBackgroundColor: undefined,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: '#EFFAFF',
      },
      title: {
        text: 'Creative Effectiveness Score - Industry Median',
        align: 'center',
        verticalAlign: 'top',
        useHTML: true,
        y: 10,
        style: {
          color: 'var(--11-Text-Color-Light-01-Text-Primary, #212B36)', // Custom color
        fontFamily: 'Inter', // Custom font family
        fontSize: '0.8rem', // Custom font size
        fontStyle: 'normal', // Normal font style
        fontWeight: '700', // Custom font weight
        lineHeight: 'normal', // Custom line height
        width: '100%', // Ensure it takes the full width
        whiteSpace: 'nowrap', // Prevent wrapping
        textAlign: 'center', // Ensure it's centered
        },
      },
      subtitle: {
        text: '68.05', // The score in the center
        align: 'center',
        verticalAlign: 'middle',
        y: 170,
        style: {
          fontSize: '1.7rem',
          fontWeight: 'bold',
          color: '#334155'
        }
      },
      credits: {
        enabled: false, // Disable Highcharts credit label
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white',
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%',
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Browser share',
          innerSize: '50%',
          data: [
            {
              name: '',
              y: 73.86, // Percentage of the slice
              color: {
                linearGradient: {
                  x1: 0.23, // Custom coordinate to simulate 23.17% start
                  y1: 0,
                  x2: 0.89, // Custom coordinate to simulate 89.76% end
                  y2: 1,    // Adjusted to simulate 142 degrees
                },
                stops: [
                  [0, '#2B43FF'],  // Start color at 23.17%
                  [1, '#8492FF'],  // End color at 89.76%
                ],
              },
            },
            {
              name: '',
              y: 26.14, // Remaining background slice percentage
              color: '#E9ECF1', // Background color
            },
          ],
        },
      ],      
    },
  },  
  {
    heading: 'Customer Satisfaction',
    isBarChart: true,
    chartOptions: <Highcharts.Options>{
      chart: {
        type: 'column',
        backgroundColor: '#EFFAFF',
      },
      title: {
        text: 'Creative Effectiveness Score',
        align: 'left',
        style: {
          color: 'var(--11-Text-Color-Light-01-Text-Primary, #212B36)',
          fontFamily: 'Inter',
          fontSize: '0.7rem',
          fontWeight: '700',
          lineHeight: '1rem',
        },
        margin: 50,
      },
      xAxis: {
        title: {
          text: 'Industry',
        },
        lineWidth: 1,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title:'',
        min: 0,
        max: 100,
        tickPositions: [0, 25, 50, 70, 90, 100],
        labels: {
          style: {
            fontSize: '12px',
            color: '#333',
          },
        },
        lineWidth: 1,
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 100,
          grouping: false,
          dataLabels: {
            enabled: false, // Disable data labels inside the bars
          },
          pointPlacement: 'on',
        },
        scatter: {
          marker: {
            symbol: 'circle',
            radius: 6, // Adjust the size of the dot
            fillColor: '#D9D9D9', // White color for the dot
            lineWidth: 2,
            lineColor: '#D9D9D9', // Same as the bar color for a neat look
          },
        },
      },
      series: [
        {
          type: 'column',
          name: 'Highest',
          data: [30],
          color: '#3730A3',
        },
        {
          type: 'column',
          name: 'High',
          data: [20],
          color: '#4338CA',
        },
        {
          type: 'column',
          name: 'Medium',
          data: [10],
          color: '#4F46E5',
        },
        {
          type: 'column',
          name: 'Low',
          data: [15],
          color: '#6366F1',
        },
        {
          type: 'scatter', // The series for the dot
          name: 'Dot',
          data: [[0, 45]], // X = 0 (first column), Y = 75 (the position of the dot)
          color: '#FFFFFF', // White dot
          marker: {
            radius: 6,
          },
          showInLegend: false,
          enableMouseTracking: false, // Disable tooltip for the dot
        },
      ],
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        symbolHeight: 12,
        symbolWidth: 15,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
        itemMarginTop: 10,
      },
      credits: {
        enabled: false,
      },
    },
  },  
  {
    heading: 'Customer Satisfaction',
    isBarChart: true,
    chartOptions: <Highcharts.Options>{
      chart: {
        type: 'column',
        backgroundColor: '#EFFAFF',
        height: 450,
        width: 600,
      },
      title: {
        text: 'Creative Effectiveness Score',
        align: 'left',
        style: {
          color: 'var(--11-Text-Color-Light-01-Text-Primary, #212B36)',
          fontFamily: 'Inter',
          fontSize: '0.7rem',
          fontWeight: '700',
          lineHeight: '1rem',
        },
        margin: 50,
      },
      xAxis: {
        title: {
          text: 'Industry',
        },
        lineWidth: 1,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title:'',
        min: 0,
        max: 100,
        tickPositions: [0, 25, 50, 70, 90, 100],
        labels: {
          style: {
            fontSize: '12px',
            color: '#333',
          },
        },
        lineWidth: 1,
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 100,
          grouping: false,
          dataLabels: {
            enabled: false, // Disable data labels inside the bars
          },
          pointPlacement: 'on',
        },
        scatter: {
          marker: {
            symbol: 'circle',
            radius: 6, // Adjust the size of the dot
            fillColor: '#D9D9D9', // White color for the dot
            lineWidth: 2,
            lineColor: '#D9D9D9', // Same as the bar color for a neat look
          },
        },
      },
      series: [
        {
          type: 'column',
          name: 'Highest',
          data: [30],
          color: '#3730A3',
        },
        {
          type: 'column',
          name: 'High',
          data: [20],
          color: '#4338CA',
        },
        {
          type: 'column',
          name: 'Medium',
          data: [10],
          color: '#4F46E5',
        },
        {
          type: 'column',
          name: 'Low',
          data: [15],
          color: '#6366F1',
        },
        {
          type: 'scatter', // The series for the dot
          name: 'Dot',
          data: [[0, 65]], // X = 0 (first column), Y = 75 (the position of the dot)
          color: '#FFFFFF', // White dot
          marker: {
            radius: 6,
          },
          showInLegend: false,
          enableMouseTracking: false, // Disable tooltip for the dot
        },
      ],
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        symbolHeight: 12,
        symbolWidth: 15,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
        itemMarginTop: 10,
      },
      credits: {
        enabled: false,
      },
    },
  },
  {
    heading: 'Customer Satisfaction',
    isBarChart: true,
    chartOptions: <Highcharts.Options>{
      chart: {
        type: 'column',
        backgroundColor: '#EFFAFF',
        height: 450,
        width: 600,
      },
      title: {
        text: 'Creative Effectiveness Score',
        align: 'left',
        style: {
          color: 'var(--11-Text-Color-Light-01-Text-Primary, #212B36)',
          fontFamily: 'Inter',
          fontSize: '0.7rem',
          fontWeight: '700',
          lineHeight: '1rem',
        },
        margin: 50,
      },
      xAxis: {
        title: {
          text: 'Industry',
        },
        lineWidth: 1,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title:'',
        min: 0,
        max: 100,
        tickPositions: [0, 25, 50, 70, 90, 100],
        labels: {
          style: {
            fontSize: '12px',
            color: '#333',
          },
        },
        lineWidth: 1,
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 100,
          grouping: false,
          dataLabels: {
            enabled: false, // Disable data labels inside the bars
          },
          pointPlacement: 'on',
        },
        scatter: {
          marker: {
            symbol: 'circle',
            radius: 6, // Adjust the size of the dot
            fillColor: '#D9D9D9', // White color for the dot
            lineWidth: 2,
            lineColor: '#D9D9D9', // Same as the bar color for a neat look
          },
        },
      },
      series: [
        {
          type: 'column',
          name: 'Highest',
          data: [30],
          color: '#3730A3',
        },
        {
          type: 'column',
          name: 'High',
          data: [20],
          color: '#4338CA',
        },
        {
          type: 'column',
          name: 'Medium',
          data: [10],
          color: '#4F46E5',
        },
        {
          type: 'column',
          name: 'Low',
          data: [15],
          color: '#6366F1',
        },
        {
          type: 'scatter', // The series for the dot
          name: 'Dot',
          data: [[0, 50]], // X = 0 (first column), Y = 75 (the position of the dot)
          color: '#FFFFFF', // White dot
          marker: {
            radius: 6,
          },
          showInLegend: false,
          enableMouseTracking: false, // Disable tooltip for the dot
        },
      ],
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        symbolHeight: 12,
        symbolWidth: 15,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
        itemMarginTop: 10,
      },
      credits: {
        enabled: false,
      },
    },
  },
  {
    heading: 'Customer Satisfaction',
    isBarChart: true,
    chartOptions: <Highcharts.Options>{
      chart: {
        type: 'column',
        backgroundColor: '#EFFAFF',
        height: 450,
        width: 600,
      },
      title: {
        text: 'Creative Effectiveness Score',
        align: 'left',
        style: {
          color: 'var(--11-Text-Color-Light-01-Text-Primary, #212B36)',
          fontFamily: 'Inter',
          fontSize: '0.7rem',
          fontWeight: '700',
          lineHeight: '1rem',
        },
        margin: 50,
      },
      xAxis: {
        title: {
          text: 'Industry',
        },
        lineWidth: 1,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title:'',
        min: 0,
        max: 100,
        tickPositions: [0, 25, 50, 70, 90, 100],
        labels: {
          style: {
            fontSize: '12px',
            color: '#333',
          },
        },
        lineWidth: 1,
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 100,
          grouping: false,
          dataLabels: {
            enabled: false, // Disable data labels inside the bars
          },
          pointPlacement: 'on',
        },
        scatter: {
          marker: {
            symbol: 'circle',
            radius: 6, // Adjust the size of the dot
            fillColor: '#D9D9D9', // White color for the dot
            lineWidth: 2,
            lineColor: '#D9D9D9', // Same as the bar color for a neat look
          },
        },
      },
      series: [
        {
          type: 'column',
          name: 'Highest',
          data: [30],
          color: '#3730A3',
        },
        {
          type: 'column',
          name: 'High',
          data: [20],
          color: '#4338CA',
        },
        {
          type: 'column',
          name: 'Medium',
          data: [10],
          color: '#4F46E5',
        },
        {
          type: 'column',
          name: 'Low',
          data: [15],
          color: '#6366F1',
        },
        {
          type: 'scatter', // The series for the dot
          name: 'Dot',
          data: [[0, 35]], // X = 0 (first column), Y = 75 (the position of the dot)
          color: '#FFFFFF', // White dot
          marker: {
            radius: 6,
          },
          showInLegend: false,
          enableMouseTracking: false, // Disable tooltip for the dot
        },
      ],
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        symbolHeight: 12,
        symbolWidth: 15,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
        itemMarginTop: 10,
      },
      credits: {
        enabled: false,
      },
    },
  },  
 
];


}
