<div *ngIf="data?.isTrialSignupCompany" style="text-align: end;margin-right: 1rem;" (click)="downloadData()">
  <img src="assets/icons/excel.svg" alt="" srcset="" style="cursor: pointer;">
</div>
<nz-table
  #rowSelectionTable
  [nzData]="listOfData"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
  [nzShowPagination]="true"
  style="overflow-x: scroll;"
>
  <thead>
    <tr>
      <th
        [(nzChecked)]="checked"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th>First name</th>
      <th>Last name</th>
      <th>Status</th>
      <th>Email id</th>
      <th *ngIf="data?.isTrialSignupCompany">Company Name</th>
      <th *ngIf="data?.isTrialSignupCompany">Registered On</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let d of rowSelectionTable.data">
      <td
        [nzChecked]="setOfCheckedId.has(d.id)"
        (nzCheckedChange)="onItemChecked(d.id, $event)"
      ></td>
      <td>{{ d.firstName | titlecase }}</td>
      <td>{{ d.lastName | titlecase }}</td>
      <td class="tags">
        <!-- change tag nzColor [active: success, NotActive : error] -->
        <nz-tag
          nzColor="{{
            d.status.toLowerCase() === 'active' ? 'success' : 'error'
          }}"
          >{{ d.status | titlecase }}</nz-tag
        >
      </td>
      <td>{{ d.email }}</td>
      <td *ngIf="data?.isTrialSignupCompany">{{ d.companyName }}</td>
      <td *ngIf="data?.isTrialSignupCompany">{{ d.createdAt | date:'dd/MM/yy; h:mm a'}}</td>
    </tr>
  </tbody>
</nz-table>
<div class="table-footer">
  <div class="table-footer-wrapper">
    <app-button
      buttonClass="secondary"
      *ngIf="setOfCheckedId.size === 1"
      label="Delete user"
      [myCallback]="deleteUser"
    ></app-button>
    <app-button
      buttonClass="secondary"
      *ngIf="setOfCheckedId.size === 1"
      label="Edit user"
      [myCallback]="editUser"
    ></app-button>
    <app-button
      buttonClass="secondary"
      *ngIf="setOfCheckedId.size === 1 && data?.isTrialSignupCompany && showResendEmailBtn"
      label="Resend Email"
      [myCallback]="resendEmail"
    ></app-button>
    <app-button
      *ngIf="setOfCheckedId.size != 1"
      [buttonClass]="isServiceRequest ? 'secondary' : 'primary'"
      label="Add new user"
      [myCallback]="addUser"
    ></app-button>
    <app-button
      buttonClass="primary"
      *ngIf="isServiceRequest"
      label="Continue"
      [myCallback]="continue"
    ></app-button>
  </div>
      

</div>

<!-- <app-modal></app-modal> -->
<nz-modal
  [(nzVisible)]="isAddTrialUserVisible"
  [nzMaskClosable]="false"
  [nzKeyboard]="false"
  nzClassName="feature-modal"
  [nzFooter]="null"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  (nzOnCancel)="handleCancel()"
>
  <ng-template #modalTitle>Add new trial user</ng-template>
  <ng-template #modalContent>
    <app-user-create-modal
      [data]="addUserData"
      [type]="'modal'"
    ></app-user-create-modal>
  </ng-template>
</nz-modal>
