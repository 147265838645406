import { Component, OnInit, ViewChild } from '@angular/core';
import { RuleService } from '../_services/rule.service';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ViewEncapsulation } from '@angular/core';
import { StorageService } from '../_services/storage.service';
import { Router } from '@angular/router';

interface Brand {
  id: string;
  title: string;
}

interface RuleObj {
  _id?: string;
  createdByName?: string;
  name: string;
  market: string[];
  userId: string;
  brandNames: string[];
  channels: string[];
  isDefault: boolean;
  creativeTypes: string;
}



interface Channel {
  id: string;
  title: string;
  selected?: boolean;
}

interface Range {
  _id: string;
  name: string;
}

interface Score {
  name: string;
  from: string;
  to: string;
  condition: string;
}

@Component({
  selector: 'app-roule-create',
  templateUrl: './rule-create.component.html',
  styleUrls: ['./rule-create.component.less'],
  encapsulation: ViewEncapsulation.None

})
export class RuleCreateComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  checkedIds: Set<string> = new Set();
  checkedIdsWeights: Set<string> = new Set();

  breadcrumb: any = [
    {
      name: "Settings",
      link: null
    },
    {
      name: "Rules",
      link: "/custom-rules/list"
    },
    {
      name: "Add new rule",
      link: null
    },
  ];

  isDefaults: any = [{ id: true, label: 'Yes' }, { id: false, label: 'No' }];
  toggleCheckbox(rangeId: string, event: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.checkedIds.add(rangeId);
    } else {
      this.checkedIds.delete(rangeId);
    }
  }
  isChecked(rangeId: string): boolean {
    return this.checkedIds.has(rangeId);
  }
  toggleCheckboxWeight(rangeId: string, event: any) {
    // console.log(this.weights)
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.checkedIdsWeights.add(rangeId + "-" + this.creative);
    } else {
      this.checkedIdsWeights.delete(rangeId + "-" + this.creative);
    }
    console.log(this.checkedIdsWeights)

  }

  isCheckedWeight(rangeId: string): boolean {
    return this.checkedIdsWeights.has(rangeId + "-" + this.creative);
  }
  isCheckedWeightAfter(rangeId: string): boolean {
    return this.checkedIdsWeights.has(rangeId);
  }
  getFieldValue(id: string, idx: number, key: string) {
    const arr = this.creative == "VIDEO" ? 'videoruleRangeScores' : 'ruleRangeScores'
    const index = this.rangeScores.findIndex((obj: { _id: string; }) => obj._id === id);

    let finalVal = ""

    if (key == "condition" && this.rangeScores[index][arr][idx][key] == "<") {
      finalVal = "Less Than"
      return finalVal;

    }
    else if (key == "condition" && this.rangeScores[index][arr][idx][key] == "<=x<") {
      finalVal = "More Than Or Equal To"
      return finalVal;

    }
    else if (key == "condition" && this.rangeScores[index][arr][idx][key] == ">=") {
      finalVal = "More Than"
      return finalVal;

    }




    return this.rangeScores[index][arr][idx][key]

  }
  isFormInvalid: boolean = false;
  scoreSaved: boolean = false;
  clientId: string = "";
  brands: Brand[] = [];
  selectedBrands: string[] = [];
  selectedChannels: string[] = [];
  selectedCreativeType: string[] = [];
  ruleWeights: any = [];
  dropdownSettings = {};
  weights: any = [];
  stepOne = false;
  openRanges: Boolean = false;
  openWeights: Boolean = false;
  creative = "DISPLAY";
  saved = "";
  ruleId = "";
  ranges: any = [];
  rangeScores: any = [];
  rangeScoresError: any = [];
  channelsList: Channel[] = [];
  conditions: any = [{ title: 'more than or equal to', id: "<=x<" }, { title: "less than", 'id': "<" }, { title: "more than", id: ">=" }];

  ruleObj: RuleObj = {
    name: '',
    userId: this.clientId,
    market: ['All'],
    brandNames: this.selectedBrands,
    channels: this.selectedChannels,
    isDefault: false,
    createdByName: '',
    creativeTypes: '',

  };

  constructor(
    private ruleService: RuleService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private storageService: StorageService,
    private route: Router

  ) { }

  validateWeight(itm: any): void {
    console.log(itm, this.creative)
    if (this.creative == "DISPLAY") {
      const totalWeight = itm.scores.reduce((sum: number, score: any) => {
        return sum + (parseFloat(score.customDisplayWeight) || 0);
      }, 0);

      itm.invalidWeight = totalWeight !== 100;
      console.log(`Weight validation for ${itm.name}: ${itm.invalidWeight}`); // Debugging line
      this.checkFormValidity();
    } else {
      const totalWeight = itm.scores.reduce((sum: number, score: any) => {
        return sum + (parseFloat(score.customVideoWeight) || 0);
      }, 0);

      itm.invalidWeight = totalWeight !== 100;
      console.log(`Weight validation for ${itm.name}: ${itm.invalidWeight}`); // Debugging line
      this.checkFormValidityVideo();
    }
  }
  checkFormValidity(): void {
    this.isFormInvalid = this.weights.some((customDisplayWeight: { invalidWeight: any; }) => customDisplayWeight.invalidWeight);
  }
  checkFormValidityVideo(): void {
    this.isFormInvalid = this.weights.some((customVideoWeight: { invalidWeight: any; }) => customVideoWeight.invalidWeight);

  }
  changeSaveScore() {
    this.scoreSaved = false;
  }

  onCreativeTypeChange(selectedValues: string[]) {
    if (selectedValues.length === 0) {
      // Run your condition here
      this.openRanges = false
      this.openWeights = false
      // Add your logic here
    } else {
      if (this.selectedCreativeType.length == 1) {
        let cT = this.selectedCreativeType[0] == "Display Ads" ? "DISPLAY" : "VIDEO"
        this.creative = cT
        this.handleCreative(cT)
      } else {
        this.creative = "DISPLAY"
        this.handleCreative("DISPLAY")
      }
    }
  }

  openRangeView(x: Boolean) {

    if (this.selectedCreativeType.length == 0) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: 'Please select the creative type',
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false,
        nzOnOk: () => { }
      });
    } else {
      if (this.selectedCreativeType.length == 1) {
        let cT = this.selectedCreativeType[0] == "Display Ads" ? "DISPLAY" : "VIDEO"
        this.creative = cT
        this.handleCreative(cT)
      } else {
        this.creative = "DISPLAY"
        this.handleCreative("DISPLAY")
      }
      this.openRanges = x;
    }
  }
  openWeightView(x: Boolean) {
    if (this.selectedCreativeType.length == 0) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: 'Please select the creative type',
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false,
        nzOnOk: () => { }
      });
    } else {
      if (this.selectedCreativeType.length == 1) {
        let cT = this.selectedCreativeType[0] == "Display Ads" ? "DISPLAY" : "VIDEO"
        this.creative = cT
        this.handleCreative(cT)
      } else {
        this.creative = "DISPLAY"
        this.handleCreative("DISPLAY")
      }
      this.openWeights = x;
    }
  }


  saveScores(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      console.log(`${field} Control:`, control);
      control.markAsTouched({ onlySelf: true });
    });
    if (form.valid) {
      this.scoreSaved = true;
    } else {
      console.log('form invalid')
      form.control.markAllAsTouched();
    }
  }
  getScores(rscore: any) {
    return this.creative === 'VIDEO' ? rscore.videoruleRangeScores : rscore.ruleRangeScores;
  }

  ngOnInit() {
    let user = this.ruleService.getUser();
    this.clientId = user.client.id;
    this.ruleService.rangesScores().subscribe(
      (response: any) => {
        this.ranges = response.filter((i: any) => i.isEditable == true);
        this.rangeScores = JSON.parse(JSON.stringify(response));
      },
      error => {
        console.error(error);
      }
    );
    this.channelsList = [
      { id: 'Facebook', title: 'Facebook' },
      { id: 'Instagram', title: 'Instagram' },
      { id: 'Youtube', title: 'Youtube' }
    ];
    this.ruleService.getBrandGuideline(this.clientId).subscribe(
      (response: any) => {
        const arr = response.client.brandDetails.map((itm: any) => {
          return {
            id: itm.masterBrand.name.toLowerCase(),
            title: itm.masterBrand.name
          }
        });
        this.brands = arr;
      }
    )
    this.ruleService.getRuleWeight().subscribe(
      (response: any) => {
        this.weights = Object.keys(response.data).map(key => (
          {
            name: key,
            scores: response.data[key]
          }
        ));
        this.weights.forEach((weight: any) => {
          if (!this.isCheckedWeight(weight.name)) {

            weight.scores.forEach((score: any) => {
              score.customDisplayWeight = score.displayWeight;
              score.customVideoWeight = score.videoWeight;

            })
          }
        });

        console.log(this.weights)

      },
      (err) => {
        console.log(err)
      }
    )
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onSubmit(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
    if (form.valid) {
      this.ruleObj.brandNames = this.selectedBrands;
      this.ruleObj.channels = this.selectedChannels;
      this.ruleObj.isDefault = !!this.ruleObj.isDefault;
      this.ruleObj.userId = this.clientId;
      this.ruleObj.createdByName = this.clientId;
      this.ruleService.createRule(this.ruleObj).subscribe(
        response => {
          if (response._id) {
            this.ruleId = response._id;
            this.stepOne = true;
          }
        },
        error => {
          // console.error('Error creating rule', error);
          this.modal.error({
            nzTitle: 'Error',
            nzContent: 'A similar rule already exists as default rule for the selected channel(s) and brand(s). You can set only 1 rule as default for the combination.',
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => { }
          });

          this.modal.error({
            nzTitle: 'Error',
            nzContent: 'A similar rule already exists as default rule for the selected channel(s) and brand(s). You can set only 1 rule as default for the combination.',
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => { }
          });

        }
      );
      console.log(this.rangeScores);
    } else {
      console.log('form invalid values');
      form.control.markAllAsTouched()
    }
  }

  onRuleCreatePopup() {
    this.modal.confirm({
      nzTitle: 'Rule Submission',
      nzContent: 'Please note, once a custom rule is created it cannot be edited. However you can always mark it as `not default`\n Are you sure you want to create this rule?',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzOnOk: () => { this.onRuleCreate() },
      nzOnCancel: () => { },
      nzCancelText: 'No',
      nzOkText: "Yes"
    });
  }

  onRuleCreate() {


    console.log(this.ranges)
    console.log(this.weights)

    let user = this.storageService.getUser();

    this.ruleObj.brandNames = this.selectedBrands;
    this.ruleObj.channels = this.selectedChannels;
    this.ruleObj.isDefault = this.ruleObj.isDefault;
    this.ruleObj.userId = this.clientId;
    this.ruleObj.createdByName = user.username;
    this.ruleObj.creativeTypes = this.selectedCreativeType.join(",")
    console.log(this.ruleObj, user)

    this.ruleService.createRule(this.ruleObj).subscribe(
      response => {
        if (response._id) {
          this.ruleId = response._id;
          this.stepOne = true;
          this.onMetricsCreate()
        }
      },
      error => {
        // console.error('Error creating rule', error);
        this.modal.error({
          nzTitle: 'Error',
          nzContent: 'A similar rule already exists as default rule for the selected channel(s) and brand(s). You can set only 1 rule as default for the combination.',
          nzClosable: false,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzOnOk: () => { }
        });

      }
    );

  }

  onMetricsCreate() {
    if (!this.isFormInvalid) {
      let ruleWeightsDisplay = this.weights.flatMap((category: any) =>
        category.scores.map((score: any) => ({
          weightNames: score._id,
          weight: this.isCheckedWeightAfter(`${category.name}-DISPLAY`) == true ? parseFloat(score.displayWeight) : parseFloat(score.customDisplayWeight) ? parseFloat(score.customDisplayWeight) : parseFloat(score.displayWeight),
        }))
      );
      let ruleWeightsVideo = this.weights.flatMap((category: any) =>
        category.scores.map((score: any) => ({
          weightNames: score._id,
          weight: this.isCheckedWeightAfter(category.name + "-VIDEO") ? score.videoWeight : score.customVideoWeight ? parseFloat(score.customVideoWeight) : parseFloat(score.videoWeight),
        }))
      );

      // console.log("ruleWeightsDisplay")

      // console.log(ruleWeightsDisplay)
      // console.log(this.isCheckedWeightAfter("Cognitive Load-DISPLAY"))
      // console.log(this.checkedIdsWeights)


      const allRangeScoresDisplay = this.ranges.map((rscore: any) => {

        if (this.checkedIds.has(rscore._id)) {
          const index = this.rangeScores.findIndex((r: { _id: any; }) => r._id == rscore._id);
          const scores = 'ruleRangeScores';
          return {
            rangeNames: rscore._id,
            ruleRangeScores: this.rangeScores[index][scores]
          };
        } else {
          const index1 = this.rangeScores.findIndex((r: { _id: any; }) => r._id == rscore._id);
          const scores1 = 'ruleRangeScores';
          return {
            rangeNames: rscore._id,
            ruleRangeScores: rscore.ruleRangeScores ?? this.rangeScores[index1][scores1]
          };
        }
      });
      const allRangeScoresVideo = this.ranges.map((rscore: any) => {

        if (this.checkedIds.has(rscore._id)) {
          const index = this.rangeScores.findIndex((r: { _id: any; }) => r._id == rscore._id);
          const scores = 'videoruleRangeScores';
          return {
            rangeNames: rscore._id,
            ruleRangeScores: this.rangeScores[index][scores]
          };
        } else {
          const index1 = this.rangeScores.findIndex((r: { _id: any; }) => r._id == rscore._id);
          const scores1 = 'videoruleRangeScores';
          return {
            rangeNames: rscore._id,
            ruleRangeScores: rscore.videoruleRangeScores ?? this.rangeScores[index1][scores1]
          };
        }
      });
      const objDisplay = {
        'ruleWeights': ruleWeightsDisplay,
        'rulesRanges': allRangeScoresDisplay,
        'creativeType': "DISPLAY",
        'customRuleId': this.ruleId,
      }
      const objVideo = {
        'ruleWeights': ruleWeightsVideo,
        'rulesRanges': allRangeScoresVideo,
        'creativeType': "VIDEO",
        'customRuleId': this.ruleId,
      }

      let weightDisplayFlag = false
      let weightVideoFlag = false

      if (this.selectedCreativeType.includes("Display Ads")) {
        this.ruleService.saveRuleWeight(objDisplay).subscribe(
          (resp: any) => {
            if (resp.code === 200) {
              // this.saved = "Rule score saved successfully for " + this.creative + " creative type";
              // window.scrollTo({ top: 0, behavior: 'smooth' });
              weightDisplayFlag = true
            }
          },
          (err: any) => {
            console.log(err)
          }
        )
      }
      if (this.selectedCreativeType.includes("Video Ads")) {
        this.ruleService.saveRuleWeight(objVideo).subscribe(
          (resp: any) => {
            if (resp.code === 200) {
              // this.saved = "Rule score saved successfully for " + this.creative + " creative type";
              // window.scrollTo({ top: 0, behavior: 'smooth' });
              weightVideoFlag = true
            }
          },
          (err: any) => {
            console.log(err)
          }
        )
      }
      this.modal.success({
        nzTitle: 'Success',
        nzContent: 'The custom rule is saved successfully',
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false,
        nzOnOk: () => {
          this.route.navigate(["custom-rules", "list"]);
        }
      });

      // console.log(obj)


    } else {
      alert('Weight form is invalid')
    }
  }

  handleCreative(type: string) {
    this.creative = type;
  }
  onBlurFrom(val: any, score_id: any, range: any, level: any) {
    // console.log(this.ranges.filter((i:any)=>i._id == score_id) );


    let currentSelectedRange = this.creative === 'VIDEO' ? this.ranges.filter((i: any) => i._id == score_id)[0]?.videoruleRangeScores : this.ranges.filter((i: any) => i._id == score_id)[0]?.ruleRangeScores

    console.log(val, score_id, range, level, currentSelectedRange);

    const evaluateCondition = (a: number, b: number, condition: string) => {
      switch (condition) {

        case '>':
          return a > b;
        case '>=':
          return a >= b;
        case '<':
          return a < b;
        case '<=':
          return a <= b;
        case '==':
          return a == b;
        default:
          return false;  // Return false if the condition is invalid
      }
    };


    const highEnd = (range == "high" && level == "to") ? val : currentSelectedRange.filter((i: any) => i.name == "high")[0].to
    const highStart = (range == "high" && level == "from") ? val : currentSelectedRange.filter((i: any) => i.name == "high")[0].from
    // const highCondition = currentSelectedRange.filter((i: any) => i.name == "high")[0].condition
    const highCondition = ">"

    const mediumEnd = (range == "medium" && level == "to") ? val : currentSelectedRange.filter((i: any) => i.name == "medium")[0].to
    const mediumStart = (range == "medium" && level == "from") ? val : currentSelectedRange.filter((i: any) => i.name == "medium")[0].from
    // const mediumCondition = currentSelectedRange.filter((i: any) => i.name == "medium")[0].condition
    const mediumCondition = "<="

    const lowEnd = (range == "low" && level == "to") ? val : currentSelectedRange.filter((i: any) => i.name == "low")[0].to
    const lowStart = (range == "low" && level == "from") ? val : currentSelectedRange.filter((i: any) => i.name == "low")[0].from
    // const lowCondition = currentSelectedRange.filter((i: any) => i.name == "low")[0].condition
    const lowCondition = "<"
    const equalCondition = "=="

    console.log("cond", highCondition, mediumCondition, lowCondition);



    // if (highStart <= highEnd &&
    //   mediumStart <= mediumEnd &&
    //   lowStart <= lowEnd &&

    //   highEnd > mediumStart &&  // High end must be greater than Medium start
    //   mediumEnd > lowStart &&   // Medium end must be greater than Low start

    //   highEnd >= mediumEnd &&   // Medium start must not overlap with High
    //   mediumEnd >= lowEnd       // Low start must not overlap with Medium

    // ) 
    if (
      // Apply dynamic conditions for all range validations
      evaluateCondition(highStart, highEnd, highCondition) &&
      evaluateCondition(mediumStart, mediumEnd, mediumCondition) &&
      evaluateCondition(lowStart, lowEnd, lowCondition) &&

      evaluateCondition(highEnd, mediumStart, highCondition) &&  // High end vs. Medium start
      evaluateCondition(mediumEnd, lowStart, highCondition) &&  // Medium end vs. Low start

      evaluateCondition(highEnd, mediumEnd, highCondition) &&  // Ensure Medium doesn't overlap High
      evaluateCondition(mediumEnd, lowEnd, highCondition) && // Ensure Low doesn't overlap Medium

      evaluateCondition(highStart, mediumEnd, equalCondition) &&  // Ensure Medium doesn't overlap High
      evaluateCondition(mediumStart, lowEnd, equalCondition)

    ) {
      this.rangeScoresError = this.rangeScoresError.filter((i: any) => i != score_id)  // Valid continuous ranges
    } else {
      this.rangeScoresError.push(score_id)
    }



    // const [highStart, highEnd] = currentSelectedRange.ruleRangeScores;    // High range [start, end]
    // const [mediumStart, mediumEnd] = mediumRange;  // Medium range [start, end]
    // const [lowStart, lowEnd] = lowRange;




  }


  onBlurFrom_To(val: any, score_id: any, range: any, level: any) {


    console.log("JSON.stringify(this.rangeScores)")

    if (range == "low") {
      this.updateMediumRangeScore(score_id, val, "medium");
    }
    else if (range == "medium") {
      this.updateMediumRangeScore(score_id, val, "high");
    }

    let currentSelectedRange = this.creative === 'VIDEO' ? this.ranges.filter((i: any) => i._id == score_id)[0]?.videoruleRangeScores : this.ranges.filter((i: any) => i._id == score_id)[0]?.ruleRangeScores


    const highEnd = (range == "high" && level == "to") ? val : currentSelectedRange.filter((i: any) => i.name == "high")[0].to
    const highStart = (range == "high" && level == "from") ? val : currentSelectedRange.filter((i: any) => i.name == "high")[0].from

    const mediumEnd = (range == "medium" && level == "to") ? val : currentSelectedRange.filter((i: any) => i.name == "medium")[0].to
    const mediumStart = (range == "medium" && level == "from") ? val : currentSelectedRange.filter((i: any) => i.name == "medium")[0].from

    const lowEnd = (range == "low" && level == "to") ? val : currentSelectedRange.filter((i: any) => i.name == "low")[0].to
    const lowStart = (range == "low" && level == "from") ? val : currentSelectedRange.filter((i: any) => i.name == "low")[0].from

    console.log(score_id, highEnd
      , highStart
      , mediumEnd
      , mediumStart
      , lowEnd
      , lowStart, level, range);


    let flag = 1
    if (lowStart > lowEnd) {
      console.log("cond", 1)
      flag = 0
    }

    if (mediumStart > mediumEnd) {
      console.log("cond", 2)
      flag = 0
    }

    if (highStart > highEnd) {
      console.log("cond", 3)
      flag = 0
    }

    if (lowEnd > mediumStart) {
      console.log("cond", 4)
      flag = 0
    }

    if (mediumEnd > highStart) {
      console.log("cond", 5)
      flag = 0
    }

    if (flag == 0) {
      this.rangeScoresError.push(score_id)
    } else {
      this.rangeScoresError = this.rangeScoresError.filter((i: any) => i != score_id)  // Valid continuous ranges
    }

    // if(lowEnd < mediumEnd && lowEnd <= mediumStart && lowEnd < highStart && lowEnd < highEnd && mediumEnd <= highStart && mediumStart <  highStart && mediumStart < mediumEnd ){

    // }else{
    //   this.rangeScoresError.push(score_id)
    // }

    // if (
    //   // Apply dynamic conditions for all range validations
    //   evaluateCondition(highStart, highEnd, highCondition) &&
    //   evaluateCondition(mediumStart, mediumEnd,mediumCondition) &&
    //   evaluateCondition(lowStart, lowEnd, lowCondition) &&

    //   evaluateCondition(highEnd, mediumStart, highCondition) &&  // High end vs. Medium start
    //   evaluateCondition(mediumEnd, lowStart, highCondition) &&  // Medium end vs. Low start

    //   evaluateCondition(highEnd, mediumEnd, highCondition) &&  // Ensure Medium doesn't overlap High
    //   evaluateCondition(mediumEnd, lowEnd, highCondition) && // Ensure Low doesn't overlap Medium

    //   evaluateCondition(highStart, mediumEnd, equalCondition) &&  // Ensure Medium doesn't overlap High
    //   evaluateCondition(mediumStart, lowEnd, equalCondition) 

    // ){
    //   this.rangeScoresError = this.rangeScoresError.filter((i: any) => i != score_id)  // Valid continuous ranges
    // } else {
    //   this.rangeScoresError.push(score_id)
    // }





  }


  updateMediumRangeScore(id: any, newFrom: any, level: any) {
    const item = this.ranges.find((obj: any) => obj._id === id);

    if (item) {
      let mediumRange
      if (this.creative == "VIDEO") {
        mediumRange = item.videoruleRangeScores.find((score: any) => score.name === level);
      } else {
        mediumRange = item.ruleRangeScores.find((score: any) => score.name === level);
      }
      if (mediumRange) {
        mediumRange.from = newFrom;
        console.log(`Updated medium range for ID: ${id}`, mediumRange);
      } else {
        console.log(`Medium range not found for ID: ${id}`);
      }
    } else {
      console.log(`Item with ID ${id} not found`);
    }
  }


}
