<div class="card-details-container imageEmotion">
    <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item>{{data.title | titlecase}}</nz-breadcrumb-item>
        <nz-breadcrumb-item>
        <a>Emotions</a>
        </nz-breadcrumb-item>
    </nz-breadcrumb>
    <div class="card-details-container" id="emotionalintensity">
        <nz-breadcrumb class="marginBottomSpace">
        </nz-breadcrumb>
        <app-report-card
            title="Emotional Intensity"
            description= "Emotional intensity in advertising grabs attention and engages viewers."
            [score]="data.data.emotionalIntensity"
            [scoreDisplay]="data.data.emotionalIntensity ? data.data.emotionalIntensity.toFixed(2) : 'NA'"
            rangeInfo="Above {{localArtifactDataRecommendedEmotional}}"
            metric="EMOTION"
        ></app-report-card>
    </div>
    <div class="card-details-wrapper flex-emotions">
    <div class="card-details-wrapper flex-emotions" style="flex-direction: column; margin: 0%;">
        <nz-card class="report-details-card" [nzBordered]="false" style="width: 23rem;">
            <div class="details-card-wrapper">
                <div class="title">Color mood board</div>
                <div class="emotion-information">
                    <app-color [data]="data.data.colorAppearances"></app-color>
                </div>
                <div class="title">Color emotions</div>
                <div class="description" *ngIf="data.data.colorEmotion && data.data.colorEmotion.length">{{data.data.colorEmotion.join(", ") | titlecase}}</div>
                <div class="description" *ngIf="!(data.data.colorEmotion && data.data.colorEmotion.length)">NA</div>
           
            </div>
        </nz-card>
        <nz-card class="report-details-card" [nzBordered]="false" style="width: 23rem;">
            <div class="details-card-wrapper">
                <div class="title">Human emotion</div>
                <div class="emotion-information" *ngIf="data.data.humanEmotions && data.data.humanEmotions.length" style="margin-top: 1rem;">
                    <div class="emtions-card" *ngFor="let emotion of data.data.humanEmotions | slice:0:3">
                        <div class="emation-icon">
                            <img *ngIf="emotion == 'Sad' || emotion=='sad' " nz-icon src="assets/icons/Icon_Sad.svg">
                            <img *ngIf="emotion == 'Fear' || emotion=='fear' " nz-icon src="assets/icons/Icon_Neutral.svg">
                            <img *ngIf="emotion == 'Scared' || emotion=='scared' " nz-icon src="assets/icons/Icon_Neutral.svg">
                            <img *ngIf="emotion == 'Surprised' || emotion=='surprised' "nz-icon src="assets/icons/Icon_Surprised.svg">
                            <img *ngIf="emotion == 'Angry' || emotion=='angry' " nz-icon src="assets/icons/Icon_Angry.svg">
                            <img *ngIf="emotion == 'Happy' || emotion=='happy' " nz-icon src="assets/icons/Icon_Happiness.svg">
                            <img *ngIf="emotion == 'Neutral' || emotion=='neutral' " nz-icon src="assets/icons/Icon_Neutral.svg">
                            <img *ngIf="emotion == 'Disgust' || emotion=='disgust' " nz-icon src="assets/icons/Icon_Disgust.svg">
                        </div>
                        <div class="emation-name" style="text-transform: capitalize;">
                            {{emotion}}
                        </div>
                    </div>
                </div>
                <div class="emotion-information" *ngIf="!(data.data.humanEmotions && data.data.humanEmotions.length)">
                    <div class="description" style="text-transform: capitalize;">NA</div>
                </div>
            </div>
        </nz-card>
        </div>
        <nz-card class="report-details-card" [nzBordered]="false">
            <div class="details-card-wrapper">             
                <div class="title">Ad copy emotion</div>
                <div class="description" *ngIf="data.data.adCopyEmotion && data.data.adCopyEmotion.length">{{data.data.adCopyEmotion.join(", ") }}</div>
                <div class="description" *ngIf="!(data.data.adCopyEmotion && data.data.adCopyEmotion.length)">NA</div>
                <div class="title" style="margin-top:2rem"  *ngIf="data.data.adCopyEmotion && data.data.adCopyEmotion[0]!='NA'" >Ad copy analysis</div>

                <div class="newFeature" *ngIf="!data.data.emotion_reason  && data.data.adCopyEmotion && data.data.adCopyEmotion.length && checked">This is a new feature, you will need to re-run the report to get the emotion explanation.</div>

                <div class="description" *ngIf="data.data.adCopyEmotion && data.data.adCopyEmotion[0]!='NA'">
                    <span>
                        {{data.data.emotion_reason}}
                    </span>
                </div>
            </div>
        </nz-card>
        <!-- <nz-card class="report-details-card" [nzBordered]="false">
            <div class="details-card-wrapper">
                <div class="title">Human emotion</div>
                <div class="emotion-information" *ngIf="data.data.humanEmotions && data.data.humanEmotions.length" style="margin-top: 1rem;">
                    <div class="emtions-card" *ngFor="let emotion of data.data.humanEmotions | slice:0:3">
                        <div class="emation-icon">
                            <img *ngIf="emotion == 'Sad'" nz-icon src="assets/icons/Icon_Sad.svg">
                            <img *ngIf="emotion == 'Fear'" nz-icon src="assets/icons/Icon_Neutral.svg">
                            <img *ngIf="emotion == 'Scared'" nz-icon src="assets/icons/Icon_Neutral.svg">
                            <img *ngIf="emotion == 'Surprised'"nz-icon src="assets/icons/Icon_Surprised.svg">
                            <img *ngIf="emotion == 'Angry'" nz-icon src="assets/icons/Icon_Angry.svg">
                            <img *ngIf="emotion == 'Happy'" nz-icon src="assets/icons/Icon_Happiness.svg">
                            <img *ngIf="emotion == 'Neutral'" nz-icon src="assets/icons/Icon_Neutral.svg">
                            <img *ngIf="emotion == 'Disgust'" nz-icon src="assets/icons/Icon_Disgust.svg">
                        </div>
                        <div class="emation-name" style="text-transform: capitalize;">
                            {{emotion}}
                        </div>
                    </div>
                </div>
                <div class="emotion-information" *ngIf="!(data.data.humanEmotions && data.data.humanEmotions.length)">
                    <div class="description" style="text-transform: capitalize;">NA</div>
                </div>
            </div>
        </nz-card> -->
    </div>
    <div class="description-container">
        <div class="description-text">
            <img nz-icon src="assets/icons/idea-light.svg"> 
            <div>
                Emotional intensity in advertising grabs attention and engages viewers. Brands can connect with their target audience by building an emotional connection with their product.
            </div>
        </div>
    </div>
</div>