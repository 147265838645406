<app-alert [message]="errorMsg" [alertType]="'error'" *ngIf="errorMsg"></app-alert>
<form nz-form nzLayout ="vertical" [formGroup]="userForm">
    <div class="form-description">Fill in the following information to create admin user.</div>
    <nz-form-item class="row-switch-container" *ngIf="!trialCompany">
        <nz-form-label nzFor="featureAccess" class="input-radio-label">I'll Administrate this client</nz-form-label>
        <nz-form-control>
            <nz-input-group nzSearch nzSize="large">
                <nz-switch [(ngModel)]="isClientAdmin" [ngModelOptions]="{standalone: true}"></nz-switch>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <ng-container *ngIf="!isClientAdmin">
    <div class="row-input-container">
        <nz-form-item>
            <nz-form-label nzFor="firstName" class="input-float-label">First name<span style="color:#EF4444">*</span></nz-form-label>
            <nz-form-control [nzValidateStatus]="f['firstName'].errors && blurFN ? 'error':''"  nzErrorTip="First name is a mandatory field and and should be minimum 3 character.">
                <nz-input-group nzSearch nzSize="large">
                    <input nz-input [readonly]="isNull" type="text" class="rounded-input" (blur)="blurFN = true" (focus)="blurFN=false" formControlName="firstName" nz-input placeholder="First name" formControlName="firstName" id="firstName"/>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="lastName" class="input-float-label">Last name<span style="color:#EF4444">*</span></nz-form-label>
            <nz-form-control [nzValidateStatus]="f['lastName'].errors && blurLN ? 'error':''"  nzErrorTip="Last name is a mandatory field and and should be minimum 1 character.">
                <nz-input-group nzSearch nzSize="large" nzStatus="error">
                    <input nz-input [readonly]="isNull" type="text" class="rounded-input" (blur)="blurLN = true" (focus)="blurLN=false" formControlName="lastName" nz-input placeholder="Last name" formControlName="lastName" id="lastName"/>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div class="row-input-container">
        <nz-form-item>
            <nz-form-label nzFor="userEmail" class="input-float-label">Email id<span style="color:#EF4444">*</span></nz-form-label>
            <nz-form-control [nzValidateStatus]="f['emailId'].errors && blurEmail ? 'error':''"  [nzErrorTip]="getErrorMessage('emailId')">
                <nz-input-group nzSearch nzSize="large">
                    <input type="text" [readonly]="isNull" class="rounded-input" (blur)="blurEmail = true" (focus)="blurEmail=false" formControlName="emailId" nz-input placeholder="Email id" id="userEmail"/>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <!-- Because this needs to be shown only for non "Trial Company" -->
        <nz-form-item *ngIf="!data?.isTrialSignupCompany">
            <nz-form-label class="input-float-label">Status<span style="color:#EF4444">*</span></nz-form-label>
            <nz-form-control>
                <nz-input-group nzSearch nzSize="large">
                    <nz-select nzPlaceHolder="Status" class="select-radio-list" formControlName="status">
                        <nz-option nzCustomContent nzLabel="Active" nzValue="active">
                            Active
                        </nz-option>
                        <nz-option nzCustomContent nzLabel="Inactive" nzValue="inactive">
                            Inactive
                        </nz-option>
                    </nz-select>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item  *ngIf="data?.isTrialSignupCompany">
            <nz-form-label class="input-float-label">Company</nz-form-label>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['companyName'].touched && f['companyName'].errors
                      ? 'error'
                      : ''
                  " [nzErrorTip]="getErrorMessage('companyName')">
                <nz-input-group nzSearch nzSize="large">
                    <input nz-input type="text" class="rounded-input" placeholder="Enter your company name"
                        formControlName="companyName" id="companyName" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    <ng-container *ngIf="data?.isTrialSignupCompany">
    <div class="row-input-container">
        <nz-form-item>
            <span class="label">Country</span>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['country'].touched && f['country'].errors ? 'error' : ''
                  " [nzErrorTip]="getErrorMessage('country')">
                <nz-input-group nzSearch nzSize="large">
                    <nz-select [nzMaxTagCount]="2" [nzShowArrow]="true" [nzOptionOverflowSize]="8" nzPlaceHolder="Select"
                        formControlName="country" id="country" nzDropdownClassName="custom-radio-dropdown"
                        nzShowSearch="true">
                        <ng-container *ngIf="
                          formOptions &&
                          formOptions.countries &&
                          formOptions.countries.length > 0
                        ">
                            <nz-option *ngFor="let country of formOptions.countries" [nzValue]="country.name"
                                [nzLabel]="country.name">
                            </nz-option>
                        </ng-container>
                    </nz-select>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <span class="label">Phone number</span>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['phone'].touched && f['phone'].errors ? 'error' : ''
                  " [nzErrorTip]="getErrorMessage('phone')">
                <nz-input-group nzSearch nzSize="large">
                    <input nz-input type="text" class="rounded-input" placeholder="Enter your phone number"
                        formControlName="phone" id="phone" [maxLength]="phoneMaxLength" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div class="row-input-container">
        <nz-form-item>
            <span class="label">Department</span>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['department'].touched && f['department'].errors ? 'error' : ''
                  " [nzErrorTip]="getErrorMessage('department')">
                <nz-input-group nzSearch nzSize="large">
                    <input nz-input type="text" class="rounded-input" placeholder="Enter your department"
                        formControlName="department" id="department" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <span class="label">Job role</span>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['jobRole'].touched && f['jobRole'].errors ? 'error' : ''
                  " [nzErrorTip]="getErrorMessage('jobRole')">
                <nz-input-group nzSearch nzSize="large">
                    <input nz-input type="text" class="rounded-input" placeholder="Enter your job role"
                        formControlName="jobRole" id="jobRole" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div class="row-input-container">
        <nz-form-item>
            <span class="label">Industry</span>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['industry'].touched && f['industry'].errors ? 'error' : ''
                  " [nzErrorTip]="getErrorMessage('industry')">
                <nz-input-group nzSearch nzSize="large">
                    <nz-select [nzMaxTagCount]="2" [nzShowArrow]="true" [nzOptionOverflowSize]="8" nzPlaceHolder="Select"
                        id="industry" nzDropdownClassName="custom-radio-dropdown" nzShowSearch="true"
                        formControlName="industry">
                        <ng-container *ngIf="
                          formOptions &&
                          formOptions.industries &&
                          formOptions.industries.length > 0
                        ">
                            <nz-option *ngFor="let industry of formOptions.industries" [nzValue]="industry"
                                [nzLabel]="industry.name">
                            </nz-option>
                            <nz-option [nzValue]="customIndustryOption" nzLabel="Other"></nz-option>
                        </ng-container>
                    </nz-select>
                </nz-input-group>
            </nz-form-control>
            <nz-form-control class="role-form" *ngIf="f['industry'].value?.name == 'custom'" [nzValidateStatus]="
                    f['customIndustry'].touched && f['customIndustry'].errors
                      ? 'error'
                      : ''
                  " [nzErrorTip]="getErrorMessage('customIndustry')">
                <nz-input-group nzSize="large">
                    <input nz-input type="text" class="rounded-input custom" placeholder="Enter your industry"
                        id="customIndustry" formControlName="customIndustry" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    
        <nz-form-item>
            <span class="label">Category</span>
            <nz-form-control class="role-form" [nzValidateStatus]="
                    f['category'].touched && f['category'].errors ? 'error' : ''
                  " [nzErrorTip]="getErrorMessage('category')">
                <nz-input-group nzSearch nzSize="large">
                    <nz-select [nzMaxTagCount]="2" [nzShowArrow]="true" [nzOptionOverflowSize]="8"
                        nzPlaceHolder="Select the Industry category" formControlName="category" id="category"
                        nzDropdownClassName="custom-radio-dropdown" nzShowSearch="true">
                        <ng-container *ngIf="selectedIndustry">
                            <nz-option *ngFor="let category of selectedIndustry?.categories" [nzValue]="category"
                                [nzLabel]="category">
                            </nz-option>
                        </ng-container>
                    </nz-select>
                </nz-input-group>
            </nz-form-control>
            <nz-form-control class="role-form" *ngIf="selectedIndustry?.name == 'custom'" [nzValidateStatus]="
                    f['customIndustry'].touched && f['customIndustry'].errors
                      ? 'error'
                      : ''
                  " [nzErrorTip]="getErrorMessage('customCategory')">
                <nz-input-group nzSize="large">
                    <input nz-input type="text" class="rounded-input custom" placeholder="Enter your industry category"
                        id="customCategory" formControlName="customCategory" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div class="row-input-container" style="width: 50%;">
        <nz-form-item style="width: 50%;">
            <span class="label">Status<span style="color: #ef4444">*</span></span>
            <!-- <nz-form-label class="input-float-label">Status<span style="color:#EF4444">*</span></nz-form-label> -->
            <nz-form-control>
                <nz-input-group nzSearch nzSize="large">
                    <nz-select nzPlaceHolder="Status" class="select-radio-list" formControlName="status">
                        <nz-option nzCustomContent nzLabel="Active" nzValue="active">
                            Active
                        </nz-option>
                        <nz-option nzCustomContent nzLabel="Inactive" nzValue="inactive">
                            Inactive
                        </nz-option>
                    </nz-select>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    </ng-container>
    <div class="row-input-container" *ngIf="!trialCompany" style="text-align: left;align-items: start;">
        <nz-form-control>
            <!-- <nz-input-group> -->
            <!-- <nz-checkbox-wrapper class="feature-checkbox-selector"> -->
            <!-- <div class="chk-box-label"> -->
            <label nz-checkbox [(ngModel)]="isBothAdmin" [ngModelOptions]="{ standalone: true }"
                style="align-items: center;" nzValue="isBothAdmin">Allow access to Incivus Admin</label>
            <!-- </div> -->
            <!-- </nz-checkbox-wrapper> -->
            <!-- </nz-input-group> -->
        </nz-form-control>
    </div>
    <!-- <div class="row-input-container" *ngIf="!trialCompany" style="text-align: left;align-items: start;">
        <nz-form-control>
            <label nz-checkbox [(ngModel)]="isBothAdmin" [ngModelOptions]="{ standalone: true }"
                style="align-items: center;" nzValue="isBothAdmin">Allow access to Incivus Admin</label>
        </nz-form-control>
    </div> -->
    </ng-container>
    <div class="form-footer">
        <div class="form-footer-wrapper">
            <app-button buttonClass="secondary" [label]="user && user.id ? 'Delete' : 'Cancel'" (click)="cancel()"></app-button>
            <app-button buttonClass="primary" [label]="trialCompany ? (( user && user.id) ? 'Upade trial user' : 'Create trial user') : isClientAdmin ? ( isServiceRequest ? 'Continue' : 'Complete setup') : ( user && user.id) ? 'Update user' : 'Create admin user'" (click)="saveUser()"></app-button>
        </div>
    </div>
</form>