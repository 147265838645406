import { HttpClient } from "@angular/common/http";
import { Component, DoCheck, OnInit, TemplateRef, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { TrialSignupService } from "./trial-signup.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { AppServices } from "src/app/_services/app.service";
import { AuthService } from "src/app/_services/auth.service";
// Import all validators under one alias 'TrialSignupValidators'
import * as TrialSignupValidators from "./trial-signup.validators";

@Component({
  selector: "app-trial-signup",
  templateUrl: "./trial-signup.component.html",
  styleUrls: ["./trial-signup.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class TrialSignupComponent implements OnInit{
  form: FormGroup = new FormGroup({
    firstName: new FormControl("", [Validators.required, TrialSignupValidators.textOnlyValidator()]),
    lastName: new FormControl("", [Validators.required, TrialSignupValidators.textOnlyValidator()]),
    customIndustry: new FormControl("", [
      Validators.maxLength(50),
      TrialSignupValidators.textOnlyValidator(),
    ]),
    customCategory: new FormControl("", [
      Validators.maxLength(50),
      TrialSignupValidators.textOnlyValidator(),
    ]),
    terms: new FormControl(false, Validators.requiredTrue),
    companyName: new FormControl("", [
      Validators.required,
      Validators.maxLength(100),
    ]),
    department: new FormControl("", [
      Validators.required,
      Validators.maxLength(100),
      TrialSignupValidators.textOnlyValidator()
    ]),
    industry: new FormControl("", [
      Validators.required,
      Validators.maxLength(50),
    ]),
    // category: new FormControl("", [Validators.required]),
    email: new FormControl("", [
      Validators.required,
      Validators.email,
      // For company email validator, you'll likely need to pass a reference to companyName
      TrialSignupValidators.companyEmailValidator(),
    ]),
    phone: new FormControl("", [
      Validators.required,
      TrialSignupValidators.phoneNumberValidator(),
    ]),
    // country: new FormControl("", Validators.required),
    jobRole: new FormControl("", [
      Validators.required,
      Validators.maxLength(50),
      TrialSignupValidators.textOnlyValidator(),
    ]),
  });
  formOptions: any = {};
  selectedIndustry: any;
  selectedCountry: any;
  phoneMaxLength = 10;
  source: string = "";
  submitted: boolean;
 

  salesForm: FormGroup = new FormGroup({
    emailUser: new FormControl("", [
      Validators.required,
      Validators.email,
    
      TrialSignupValidators.companyEmailValidator(),
    ]),
    phoneNumberUser: new FormControl("", [
      Validators.required,
      TrialSignupValidators.phoneNumberValidator(),
    ]),
  })
  modalRef: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
 

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private modal: NzModalService,
    private service: AppServices,
    private authService: AuthService,
    private trialSignupService: TrialSignupService,
    private router: Router
  ) {}

  

  ngOnInit() {
    console.log("ngOnInit");

    setTimeout(() => {
      this.authService.redirectIfLoggedIn();
      this.route.queryParams.subscribe((params) => {
        this.source = params["source"];
      });
      this.trialSignupService.getCountries().subscribe((data) => {
        this.formOptions.countries = data;
      });
      this.trialSignupService.getIndustries().subscribe((data) => {
        this.formOptions.industries = data;
      });
      this.initIndustryChange();
      this.initCountryChange();
      this.getFormOptions();
    }, 10);
  }

  getFormOptions() {
    this.service.getTrialFormOptions().subscribe({
      next: (response: any) => {
        if (response?.domains != null)
          TrialSignupValidators.updateDomains(response.domains);
      } 
    });
  }

  initIndustryChange() {
    this.form.get("industry")?.valueChanges.subscribe((data: any) => {
      this.selectedIndustry = data;
      let isCustom = data?.name == "custom";
      this.form.controls["category"].setValue(
        isCustom && data.categories?.length > 0 ? data.categories[0] : null
      );
      if (isCustom) {
        this.form.get("customIndustry")?.addValidators(Validators.required);
        this.form.get("customCategory")?.addValidators(Validators.required);
      } else {
        this.form.get("customIndustry")?.removeValidators(Validators.required);
        this.form.get("customCategory")?.removeValidators(Validators.required);
      }
      this.form.get("customIndustry")?.updateValueAndValidity();
      this.form.get("customCategory")?.updateValueAndValidity();
      this.form.controls["category"].markAsUntouched();
      this.form.controls["customIndustry"].markAsUntouched();
      this.form.controls["customCategory"].markAsUntouched();
    });
  }
  initCountryChange() {
    // Listen for changes in the selected country
    this.form.get("country")?.valueChanges.subscribe((country) => {
      this.phoneMaxLength = country?.mobile_number_length ?? 10;
      
      // Update the phone number validator
      this.form.get("phone")?.clearValidators();
      this.form.get("phone")?.addValidators(Validators.required);
      this.form
        .get("phone")
        ?.addValidators(
          TrialSignupValidators.phoneNumberValidator(this.phoneMaxLength)
        );
      this.form.get("phone")?.updateValueAndValidity();
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.form.get(controlName);
    return TrialSignupValidators.getErrorMessage(control, controlName);
  }

  getErrorMessageUser(controlName: string): string {
    const control = this.salesForm.get(controlName);
    return TrialSignupValidators.getErrorMessage(control, controlName);
  }


  openTermsAndPolicy(isTerms: boolean, event: MouseEvent) {
    event?.stopPropagation();
    console.log("openTermsAndPolicy", event);

    const filePath = isTerms
      ? "assets/data/terms.html"
      : "assets/data/privacy_policy.html";

    this.http
      .get(filePath, { responseType: "text" })
      .subscribe((htmlContent) => {
        this.modal.info({
          nzTitle: isTerms
            ? "TERMS & CONDITIONS OF FREE TRIAL OF PLATFORM"
            : "PRIVACY NOTICE",
          nzContent: htmlContent,
          nzWidth: "70%",
          nzOkText: "Okay",
        });
      });
    return false;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get fUser(): { [key: string]: AbstractControl } {
    return this.salesForm.controls;
  }

  onContinueClicked() {
    // Validate the form
    this.submitted = true;
    this.form.markAllAsTouched();

    this.submitted = false;
    let data: any = this.form.value;
    data.industry = data.industry.name;
    console.log("data.industry"+data.industry)
    data.country = data.country?.name;
    console.log("data.country"+data.country)
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.controls[key] as AbstractControl;
      // Do something with the control
      if (control.status != "VALID") {
        console.log(
          `${key} status: ${control.status} errors: `,
          control.errors
        );
      }
    });
    if (this.form.status.toLowerCase() === "invalid") {
      return;
    }
    if (data.industry == "custom") {
      data.industry = data.customIndustry;
      data.category = data.customCategory;
    }
    delete data.customIndustry;
    delete data.customCategory;
    data.source = this.source;
    console.log("TrialSignupComponent submit ", this.form.status, data);
    this.service.saveTrialUser(data).subscribe({
      next: (data) => {
        this.showSuccessPopup();
        // this.storageService.saveUser(data);
        // this.router.navigate(['creatives', 'pre-flight', 'list']);
      },
      error: (err) => {
        this.modal.error({
          nzTitle: "Error",
          nzContent:
            err?.error?.message ??
            'Uh!oh! seems like we broke something. Please try again or write to <a href="mailto:support@incivus.ai" target="_blank">support@incivus.ai</a> support.',
          nzMaskClosable: false,
          nzKeyboard: false,
        });
      },
    });
  }

  showSuccessPopup() {
    this.modal.success({
      nzTitle: "Sign Up Successful!",
      nzContent:
        "Congratulations! You have successfully signed up for a trial with Incivus. As the next step, please check your email ID and setup a secure password to access the Incivus platform.",
      nzMaskClosable: false,
      nzKeyboard: false,
      nzOkText: "Okay",
      //  nzIconType: "primary",
      nzOnOk: () => {
        this.router.navigate(["login"]);
      },
    });
  }


  // openConnectToSales(event: MouseEvent, tpl: TemplateRef<{}>): void {
  //   event?.stopPropagation();

  //   this.salesForm.reset();
    
  //  this.modalRef= this.modal.info({
  //     nzTitle: "Connect to sales team",
  //     nzContent: tpl,
  //     nzWidth: "35%",
  //     nzOkText: null,
  //   });
  // }

  openConnectToSales(event: MouseEvent, tpl: TemplateRef<{}>): void {
    event?.stopPropagation();
  
    this.salesForm.reset();
  
    this.modalRef = this.modal.info({
      nzTitle: `<span class="modal-title">Connect to sales team</span>`, // Added class to control the title size
      nzContent: tpl,
      nzWidth: "35%",
      nzOkText: null,
      nzClassName: 'custom-modal', // Optional if you need to further customize
    });
  }
  

  // onConnectToSales(){
  //   if(this.salesForm.valid){




  //     const successModal = this.modal.success({
  //       nzTitle: "Successful!",
  //       nzContent:
  //         "Thank you for reaching out to Incivus. Our Sales team will contact you shortly.",
  //       nzMaskClosable: false,
  //       nzKeyboard: false,
  //       nzOkText: null, 
  //       nzCancelText: null, 
  //       nzClosable: true,
  //       nzWidth: "40%",
  //     });
  
  //     this.modalRef.destroy();
  
  //     setTimeout(() => {
  //       successModal.triggerOk();
  //     }, 5000);
  
  //   let userEmail =this.salesForm.get('emailUser')?.value;
  //   let userPhoneNumber =this.salesForm.get('phoneNumberUser')?.value;
  //   }else{
  //     this.modal.error({
  //       nzTitle: "Error",
  //       nzContent:
  //         'Uh!oh! seems like we broke something. Please try again',
  //         nzMaskClosable: false,
  //         nzKeyboard: false,
  //         nzOkText: null, 
  //         nzCancelText: null, 
  //         nzClosable: true,
  //       nzWidth: "40%",
  //     });
  //   }  
  // }
  
  onConnectToSales() {

    this.submitted = true;
    this.salesForm.markAllAsTouched();
    this.submitted = false;
    let data: any = this.salesForm.value;
    data.emailUser = data.emailUser;
    console.log("data.emailUser"+data.emailUser)
    data.userPhoneNumber = data.userPhoneNumber;
    console.log("data.userPhoneNumber"+data.userPhoneNumber)
    Object.keys(this.salesForm.controls).forEach((key) => {
      const control = this.salesForm.controls[key] as AbstractControl;
      // Do something with the control
      if (control.status != "VALID") {
        console.log(
          `${key} status: ${control.status} errors: `,
          control.errors
        );
      }
    });
    if (this.salesForm.status.toLowerCase() === "invalid") {
      return;
    }

  
      // Extract email and phone number
      const userEmail = this.salesForm.get('emailUser')?.value;
      const userPhoneNumber = this.salesForm.get('phoneNumberUser')?.value;
  
      // Create the payload object for API call
      const payload = {
        email: userEmail,
        phoneNumber: userPhoneNumber,
      };
  
      // Call the API service to connect the sales user
      this.service.connectSalesUser(payload).subscribe({
        next: (response:any) => {
          // If the API call is successful (200 OK)
          const successModal = this.modal.success({
            nzTitle: "Successful!",
            nzContent:
              "Thank you for reaching out to Incivus. Our Sales team will contact you shortly.",
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOkText: null,
            nzCancelText: null,
            nzClosable: true,
            nzWidth: "40%",
          });
  
          // Destroy the current modal
          this.modalRef.destroy();
  
          // Automatically close the success modal after 5 seconds
          setTimeout(() => {
            successModal.triggerOk();
          }, 5000);
        },
        error: (err:any) => {
          // If the API call fails, show an error modal
          this.modal.error({
            nzTitle: "Error",
            nzContent:
              err?.error?.message ??
              'Uh!oh! Something Went Wrong Please try again.',
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOkText: null,
            nzCancelText: null,
            nzClosable: true,
            nzWidth: "40%",
          });
        }
      });
    
  }

  
}
