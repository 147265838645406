import {
  Component,
  ViewEncapsulation,
  Input,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { formatDate } from "@angular/common";


@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class UploadComponent {
  @Input() public myCallback: Function;
  @Input() public fileAccept: string;
  @Input() public fileType: string;
  @Input() public isTrialSignup: boolean = false;
  @Input() public breadcrumb:string='';
  @Input() public channelsList: any[] = [];
  @Output() creativeNameChange = new EventEmitter<string>();
  @Output() creativeChannelChange = new EventEmitter<string>();
  errorMsg: string|null = "";
  logoListPreview: any;
  file: any;
  selectedChannels : any;
  type: string = "";
  creativeName:string='';
  uploadInfo: any = {
    showPreviewIcon: false,
    showRemoveIcon: false,
    showDownloadIcon: false,
  };

  
  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.myCallback = () => {};

  }


  uploadCreativeForm = new FormGroup({  
    creativeName: new FormControl('', [Validators.required, Validators.maxLength(75)]),
    channelsList: new FormControl('select', [Validators.required]),
    creativeType: new FormControl('image', [Validators.required]),
    brandName: new FormControl('')
  });

  ngOnInit(): void {
    this.uploadCreativeForm.get('creativeName')?.valueChanges.subscribe(value => {
      this.updateCreativeName(value);
    });

    this.uploadCreativeForm.get('channelsList')?.valueChanges.subscribe(value => {
      this.updateCreativeChannel(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logoListPreview = null;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  // `beforeUploadLogo` returns a boolean synchronously
  beforeUploadLogo = (file: NzUploadFile): boolean => {

    this.errorMsg = "";
    let acceptedFileType = this.fileAccept.split(",");
    this.file = file;
    let name = this.file.name;
    let nameArr = name.split(".");
    const last = nameArr[nameArr.length - 1];
  
    let formData=this.getFormData('false')
    let titleValue = formData.get('title');
    if(titleValue!=null)this.creativeName=titleValue

    // Check file type
    if (!acceptedFileType.includes("." + last)) {
      this.errorMsg = "File format not supported";
      return false;
    }

    // Check file size (1 GB = 1073741824 bytes)
    if (this.file.size > 1073741824) {
      this.errorMsg = "File size exceeds 1 GB";
      return false;
    }

    // Validate based on file type (asynchronous)
    if (this.file.type.startsWith("image/")) {
      // TRIAL-39: The display ad and Video ad Resolution check needs to be changed.
      // this.validateImage(this.file).then((error) => {
      //   if (error) {
      //     this.errorMsg = error;
      //   }
      // });
    } else if (this.file.type.startsWith("video/")) {
      this.validateVideo(this.file).then((error) => {
        if (error) {
          this.errorMsg = error;
        }
      });
    }
    // If there's an error message, stop the upload
    if (this.errorMsg) {
      return false;
    }

    this.logoListPreview = window.URL.createObjectURL(file as any);

    if (this.fileType === "video" || this.isVideoFile(last)) {
      this.type = "video";
      this.loadVideo();
    } else {
      this.type = "image";
    }

    

    setTimeout(() => {

      if (!this.errorMsg || this.errorMsg.trim() === "") {
        this.myCallback(this.file);
      } else 
      this.myCallback(null);
      let titleValue = formData.get('title');

      if (titleValue != null) {
        this.creativeName = titleValue;
        this.creativeNameChange.emit(this.creativeName); 
        this.creativeChannelChange.emit(this.selectedChannels?.join(","))
      }

    }, 100);

    return false; // Prevent default upload behavior
  };
  getFormData(analyze: string) : any {
    let input = this.uploadCreativeForm.value;
    let formData = new FormData();
    formData.append('artifactType', this.fileType);
    formData.append('brand', input.brandName ? input.brandName : '');
    formData.append('multipartFile', this.file);
    formData.append('analyze', analyze);
    formData.append('title', input.creativeName ? input.creativeName : '');
    formData.append('groupName', '');
    formData.append("phase", "pre-flight");
   
    return formData;
  }


  // Helper function to check if file is a video based on extension
  isVideoFile(extension: string): boolean {
    const videoFormats = ["mp4", "avi", "wmv", "ogg", "webm", "mov"];
    return videoFormats.includes(extension);
  }

  // Load the video after preview
  loadVideo() {
    let element = document.getElementById("source_video");
    let parentElement = element?.parentElement as HTMLVideoElement;
    parentElement?.load();
  }

  // Validate image (aspect ratio and resolution)
  validateImage(file: File): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      if (!this.isTrialSignup) {
        URL.revokeObjectURL(objectUrl); // Clean up object URL
        resolve("");
      }


      img.onload = () => {
        const width = img.width;
        const height = img.height;
        // Define acceptable aspect ratios and tolerance
        const acceptableRatios = [
          { ratio: 16 / 9, tolerance: 0.01 },
          { ratio: 9 / 16, tolerance: 0.01 },
          { ratio: 1 / 1, tolerance: 0.01 },
          { ratio: 4 / 5, tolerance: 0.01 },
          { ratio: 1.91 / 1, tolerance: 0.01 },
        ];

        const aspectRatio = width / height;

        // Check if the aspect ratio is within any of the acceptable ranges
        const isValidAspectRatio = acceptableRatios.some(
          ({ ratio, tolerance }) => Math.abs(aspectRatio - ratio) <= tolerance
        );

        if (!isValidAspectRatio) {
          URL.revokeObjectURL(objectUrl); // Clean up object URL
          return resolve(
            "Aspect ratio should be either 16:9, 9:16, 4:5 or 1:1"
          );
        }

        // Check for specific resolutions
        if (
          !(
            (width === 1280 && height === 720) || // 1280 x 720
            (width === 500 && height === 888) || // 500 x 888
            (width === 1080 && height === 1080)
          ) // 1080 x 1080
        ) {
          URL.revokeObjectURL(objectUrl); // Clean up object URL
          return resolve(
            `Resolution should be either 1280x720 or 500x888 or 1080x1080.`
          );
        }

        URL.revokeObjectURL(objectUrl); // Clean up object URL
        resolve("");
      };

      img.onerror = () => {
        resolve("Error loading image.");
      };
    });
  }
  // Validate video (aspect ratio and resolution)
  validateVideo(file: File): Promise<string> {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      const objectUrl = URL.createObjectURL(file);
      video.src = objectUrl;
        if (!this.isTrialSignup) {
          URL.revokeObjectURL(objectUrl); // Clean up object URL
          resolve("");
        }


      video.onloadedmetadata = () => {
        const duration = video.duration;
        // TRIAL-39: The display ad and Video ad Resolution check needs to be changed.
        const fileSize = file.size / (1024 * 1024); // Convert from bytes to MB
        // file should be less than 50Mb & duration less than 45
        if (fileSize > 50 || duration > 45) {
          URL.revokeObjectURL(objectUrl); // Clean up object URL
          return resolve(
            "As part of this trial, we are supporting Video Ads with size upto 50MB and duration of the Ad upto 45 seconds only. Kindly upload files that meet this criteria."
          );
        }
        // const width = video.videoWidth;
        // const height = video.videoHeight;

        // // Define acceptable aspect ratios and tolerance
        // const acceptableRatios = [
        //   { ratio: 16 / 9, tolerance: 0.01 },
        //   { ratio: 9 / 16, tolerance: 0.01 },
        //   { ratio: 1 / 1, tolerance: 0.01 },
        //   { ratio: 4 / 5, tolerance: 0.01 },
        //   { ratio: 1.91 / 1, tolerance: 0.01 },
        // ];

        // const aspectRatio = width / height;

        // // Check if the aspect ratio is within any of the acceptable ranges
        // const isValidAspectRatio = acceptableRatios.some(
        //   ({ ratio, tolerance }) => Math.abs(aspectRatio - ratio) <= tolerance
        // );

        // if (!isValidAspectRatio) {
        //   URL.revokeObjectURL(objectUrl); // Clean up object URL
        //   return resolve(
        //     "Aspect ratio should be either 16:9, 9:16, 4:5 or 1:1"
        //   );
        // }

        // // Check for specific resolutions
        // if (
        //   !(
        //     (width === 1280 && height === 720) || // 1280 x 720
        //     (width === 720 && height === 1280) || // 1280 x 720
        //     (width === 500 && height === 888) || // 500 x 888
        //     (width === 888 && height === 500) || // 500 x 888
        //     (width === 1080 && height === 1080)
        //   ) // 1080 x 1080
        // ) {
        //   URL.revokeObjectURL(objectUrl); // Clean up object URL
        //   return resolve(
        //     `Resolution should be either 1280x720 or 500x888 or 1080x1080.`
        //   );
        // }

        URL.revokeObjectURL(objectUrl); // Clean up object URL
        resolve("");
      };

      video.onerror = () => {
        resolve("Error loading video metadata.");
      };
    });
  }

    updateCreativeName(event: any): void {
      this.creativeNameChange.emit(event);
    }
  
   
    updateCreativeChannel(event: any): void {
      this.creativeChannelChange.emit(event);
    }
    
}
