<nz-card nzTitle="Logos" style="display: flex; flex-direction: column; justify-content: space-between;">
  <div class="br-selected-list fullWidth">
      <ul style="display: flex; flex-wrap: wrap;">
          <li class="br-selected-logo" *ngFor="let url of brandAssets; let i = index">
              <img src="{{url}}" alt="Preview Image" style="width: 100%; max-height: 100%;" />
          </li>
      </ul>
  </div>
</nz-card>

<div  style="display: flex; justify-content: flex-end; margin-top: auto;">
  <app-button
      *ngIf="showButton"
      buttonClass="small primary"
      label="Download"
      (click)="downloadAllImages()"
  ></app-button>
</div>

