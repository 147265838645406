import { Component, Input, ViewEncapsulation } from '@angular/core';
import { STROKE_COLOR } from '../constants/report-constants';

@Component({
  selector: 'app-emotions',
  templateUrl: './emotions.component.html',
  styleUrls: ['./emotions.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class EmotionsComponent {
  @Input() public data: any;
  artifactId:string='';
  localArtifactDataRecommendedEmotional:any= localStorage.getItem("@localArtifactDataRecommendedEmotional")
  storedDate:string | null;
  checked:boolean=false;

  ngOnInit(){
     
  this.artifactId=this.data.data.artifactId;
  
  const getDate = () => localStorage.getItem('uploadedAt');
  this.storedDate=getDate();

  if (this.storedDate) {
    const storedDateObj = new Date(this.storedDate);
    const checkDate= new Date('2024-10-17');

    const diffInMs = storedDateObj.getTime() - checkDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

    if(diffInDays<0 || diffInHours<0 || diffInMinutes<0){this.checked=true}

  }
  }
}
