import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppServices } from "src/app/_services/app.service";
import { StorageService } from "src/app/_services/storage.service";

@Component({
  selector: "app-workbench",
  templateUrl: "./workbench.component.html",
  styleUrls: ["./workbench.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class WorkbenchComponent implements OnInit {
  getCreativeTypes(list: string[]) {
    let names: any[] = [];
    list?.forEach((element) => {
      if (element == "video") names.push("Video Ads");
      if (element == "image") names.push("Display Ads");
    });
    return names.join(", ");
  }
  srList: any;
  clientList: any[];

  placeholder: string = "Unilever";
  clientId: any;
  filter() {
    // throw new Error("Method not implemented.");
  }
  searchText: any;

  srListFiltered: any[] = [];
  srCopyFilteredList: any[] = [];

  adminCustomerListEmpty: string | TemplateRef<any> | undefined;

  breadcrumb: any = [
    {
      name: "Settings",
      link: null,
    },
    {
      name: "Workbench",
      link: null,
    },
  ];
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<string>();

  constructor(
    private router: Router,
    private appService: AppServices,
    private storage: StorageService
  ) {}

  ngOnInit(): void {
    this.getBenchmarkList();
  }

  onEditBenchmarkClick(benchmark: any) {
    this.router.navigate(["client", "workbench", benchmark?.id, "edit"]);
  }

  loadClientReports(clientId: any) {
    this.clientId = clientId;
    this.storage.clientId = this.clientId;
    this.getAdsList(clientId);
  }

  onItemChecked(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.srList.forEach((item: any) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {}

  createBenchmark() {
    this.router.navigate(["client", "workbench", "create"]);
  }

  getBenchmarkList(): void {
    this.appService.getAllBenchmark().subscribe((data: any) => {
      console.log("workbench.component.ts [ba9c09] getBenchmarkList: ", data);
      let response: any = data.benchmarks;
      this.srList = response;

      this.searchText = "";
      this.srListFiltered = response;
      this.srCopyFilteredList = this.srListFiltered;

      const clients = this.srList
        .map((request: any) => request.client)
        .filter((client: any) => client !== null);
      console.log(
        "workbench.component.ts [b6d0fa] clients: ",
        clients,
        this.srList
      );

      const uniqueClients = Array.from(
        new Map(clients.map((client: any) => [client.id, client])).values()
      ).filter((client: any) => client.companyName !== null);
      console.log("uniqueClients", clients, uniqueClients);

      this.clientList = uniqueClients;
      if (this.clientList != null && this.clientList.length > 0) {
        this.placeholder = this.clientList[0].companyName;
        this.clientId = this.clientList[0].id;
        this.getAdsList(this.clientList[0].id);
      } else {
        this.getAdsList("");
      }
    });
  }

  getAdsList(id: string): void {
    if (id == null || id == "") {
      this.srListFiltered = this.srList;
      return;
    }
    this.srListFiltered = this.srList.filter(
      (request: any) => request.client?.id == id
    );
    this.srCopyFilteredList = this.srListFiltered;
  }
}
