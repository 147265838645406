import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { CommonModule } from'@angular/common';

@Component({
  selector: 'app-adcopy',
  templateUrl: './adcopy.component.html',
  
  styleUrls: ['./adcopy.component.less']

})
export class AdcopyComponent {
  @Input() public data:any;
  adcopy:any={}
  ngOnInit(){
    this.adcopy=this.data.emotion_reason;
  }
}
