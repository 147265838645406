<app-breadcrumb [labels]="breadcrumb" fullBC="{{ true }}"></app-breadcrumb>

<nz-page-header class="breadcrumb-container heading">
  <nz-page-header-extra class="primary-container">
    <button nz-button nzType="primary" class="white-text-button" (click)="createBenchmark()">
      Create Benchmark
    </button>
  </nz-page-header-extra>
</nz-page-header>



<nz-page-header class="page-header-container heading">
  <nz-page-header-title>Benchmarks <span style="font-weight: normal;">(Showing All)</span> </nz-page-header-title>
  <nz-page-header-extra>
    <nz-space>
      <nz-form-item class="creativesFilter">

        <!-- <nz-form-control>
          <nz-input-group nzSearch nzSize="large">
            <nz-select [nzPlaceHolder]="placeholder" nzDropdownClassName="custom-check-dropdown" [ngModel]="clientId"
              nzShowSearch="true" id="clientSelect" (ngModelChange)="loadClientReports($event)">
              <nz-option *ngFor="let client of clientId" [nzValue]="client.id"
                [nzLabel]="client.companyName"></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control> -->
        <nz-form-control>
          <nz-input-group nzSearch nzSize="large">
            <nz-button nz-button nzType="secondary" class="refresh-button">
              <span nz-icon><img nz-icon src="../../../../assets/icons/refresh-file.svg"
                  style="width: 0.7rem;height: 0.7rem;flex-shrink: 0;"></span>
              Refresh
            </nz-button>
            <nz-select nzPlaceHolder="Select Client" nzDropdownClassName="custom-radio-dropdown" [ngModel]="clientId"
              nzShowSearch="true" id="clientSelect" (ngModelChange)="loadClientReports($event)">
              <nz-option *ngFor="let client of clientList" [nzValue]="client.id"
                [nzLabel]="client.companyName"></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </nz-space>
    <nz-space style="margin-left:1rem">
      <nz-input-group [nzPrefix]="prefixIconSearch" *nzSpaceItem>
        <input type="text" nz-input [(ngModel)]="searchText" (keyup)="filter()" [ngModelOptions]="{ standalone: true }"
          placeholder="Search..." />
      </nz-input-group>
      <ng-template #prefixIconSearch>
        <span nz-icon nzType="search" nzTheme="outline" class="search-extra"></span>
      </ng-template>
    </nz-space>
  </nz-page-header-extra>
</nz-page-header>


<nz-table #serviceRequestsTable [nzData]="srListFiltered" [nzNoResult]="adminCustomerListEmpty"
  [nzShowPagination]="true" class="admin-list-table">
  <thead>
    <tr>
      <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
      <th [nzShowSort]="true" [nzSortDirections]="['descend', null]">Name</th>
      <th [nzShowSort]="true" [nzSortDirections]="['descend', null]">Company</th>
      <th [nzShowSort]="true" [nzSortDirections]="['descend', null]" style="text-align: center;">Status</th>
      <th [nzShowSort]="true" [nzSortDirections]="['descend', null]">Creative Type</th>
      <th [nzShowSort]="true" [nzSortDirections]="['descend', null]">Date Created</th>
      <th>Submitted On</th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let data of serviceRequestsTable.data; let i = index">
      <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
      <td><span *ngIf="data.benchmarkType">{{ data.benchmarkType + " benchmark" | capitalize }}</span></td>
      <td>{{ data.client?.companyName }}</td>
      <td style="text-align: center;">
        <div>
          {{ data.status?.includes("draft") ? "Draft" : data.status | capitalize }}
        </div>
      </td>
      <td>{{ getCreativeTypes(data.creativeTypes)}}</td>
      <td>{{ data.createdAt | date: 'short' }}</td>
      <td>{{ data.createdAt | date: 'short' }}</td>
      <td>
        <button nz-button nzNoAnimation nz-tooltip class="action-trigger" [nzTooltipTitle]="menu"
          nzTooltipOverlayClassName="tableTooltipOverlay" [nzTooltipPlacement]="['bottomRight', 'topRight']">
          <span nz-icon nzType="more" nzTheme="outline" style="font-size: 0.87rem; vertical-align: top;"></span>
        </button>
        <ng-template #menu>
          <ul nz-menu>
            <li nz-menu-item>
              <span nz-icon><img nz-icon src="../../../../assets/icons/refresh-icon.svg"></span>
              <span>Refresh benchmark</span>
            </li>
            <li nz-menu-item (click)="onEditBenchmarkClick(data)">
              <span nz-icon><img nz-icon src="../../../../assets/icons/edit-icon.svg"></span>
              <span>Edit benchmark</span>
            </li>
          </ul>
        </ng-template>
      </td>
    </tr>
  </tbody>
</nz-table>
