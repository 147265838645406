import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RuleService } from '../_services/rule.service';
import { HttpClient } from '@angular/common/http';
import { AppServices } from "src/app/_services/app.service";
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { StorageService } from '../_services/storage.service';



@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class RulesComponent implements OnInit {
  rules: any = [];
  copyRules: any = [];
  user: any;
  searchText: String;

  pageIndex = 1;
  pageSize = 10;
  total = 0;
  displayedRules: any = []; // Data for the current page 

  resp: any = [];
  breadcrumb: any = [
    {
      name: "Settings",
      link: null
    },
    {
      name: "Rules",
      link: null
    },
  ];
  constructor(
    private ruleService: RuleService,
    private http: HttpClient,
    private service: AppServices,
    private modal: NzModalService,
    private router: Router,
    private storageService: StorageService,

  ) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();

    this.ruleService.getAllRules(this.user.client.id).subscribe(
      (response) => {

        this.rules = response;
        this.total = this.rules.length;
        this.updateDisplayedRules();

        this.copyRules = response;
      },
      (error) => {
        console.error(error);
      }
    );
  }
  capitalizeFirstLetter(name: string) {
    let d = name.toLowerCase()
    return d.charAt(0).toUpperCase() + d.slice(1);
  }
  capitalizeFirstLetterJoin(str: string) {
    console.log("Input String:", str);

    // Split the string by commas, and trim whitespace around words
    let aftersplit = str.split(',').map(word => word.trim()).filter(word => word !== '');

    // Capitalize the first letter of each word
    let aftersplitCapitalize = aftersplit.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    console.log("Capitalized Words:", aftersplitCapitalize);

    // Join the words back with commas and return the result
    return aftersplitCapitalize.join(', ');
  }
  routeToView(ruleId: any, creativeTypes: any): void {

    localStorage.setItem("@selectedRulecreativeType", creativeTypes)

    let cT = creativeTypes.split(",")
    if (cT.length == 1 && cT[0].includes("Video")) {
      this.router.navigate([`/custom-rules/show/${ruleId}/VIDEO`]);
    } else {
      this.router.navigate([`/custom-rules/show/${ruleId}/DISPLAY`]);
    }


  }
  routeToViewDefault(ruleId: any): void {
    this.router.navigate([`/custom-rules/show/${ruleId}/DISPLAY`]);
  }
  routeToCreateNewRule(): void {
    this.router.navigate(['/custom-rules/create']);
  }

  filter() {
    // const targetValue: rules[] = [];
    let targetValue: any = []
    this.copyRules.forEach((value: any) => {
      if (value["name"] && value["name"].toString().toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
        || value["brandNames"] && value["brandNames"].toString().toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())) {
        targetValue.push(value);
      }
    });
    this.rules = targetValue;
  }

  toggleDefault(rule: any): void {

    this.service
      .updateCustomRule(
        rule._id,
        { isDefault: !rule.isDefault }
      )
      .subscribe((response) => {
        console.log("postMediaAccount", response);
        this.resp = response;

        if (this.resp.errorCode == 1) {
          // alert(this.resp.message)
          this.modal.error({
            nzTitle: 'Error',
            nzContent: this.resp.message,
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzOnOk: () => { }
          });
        } else {
          this.user = this.storageService.getUser();

          this.ruleService.getAllRules(this.user.client.id).subscribe(
            (response) => {

              this.rules = response;
            },
            (error) => {
              console.error(error);
            }
          );
          rule.isDefault=!rule.isDefault;
        }
        // this.onAddSuccess.emit(response);
      });
  }

  onPageChange(index: number) {
    console.log("indexi",index)
    this.pageIndex = index;
    this.updateDisplayedRules();
  }

  onPageSizeChange(size: number) {
    this.pageSize = size;
    this.pageIndex = 1; // Reset to first page
    this.updateDisplayedRules();
  }

  updateDisplayedRules() {
    const start = (this.pageIndex - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.displayedRules = this.rules.slice(start, end);

    console.log("drules" , this.displayedRules)

  }

}
