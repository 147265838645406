<app-breadcrumb [labels]=breadcrumb fullBC="{{true}}"></app-breadcrumb>

<nz-page-header class="breadcrumb-container heading">
    <nz-page-header-extra>
        <nz-space>
            <!-- <button *nzSpaceItem nz-button>
                Filter
            </button> -->
            <button class="primary" *nzSpaceItem nz-button (click)="routeToCreateNewRule()">
                Add new rule
            </button>
        </nz-space>
    </nz-page-header-extra>
</nz-page-header>


<nz-page-header class="page-header-container heading">
    <nz-page-header-title>Rules({{rules.length}})
        <!-- <span class="showAll">(Showing All)</span> --></nz-page-header-title>
    <nz-page-header-extra>
        <nz-space>

            <nz-input-group [nzPrefix]="prefixIconSearch" *nzSpaceItem>
                <input type="text" nz-input [(ngModel)]="searchText" (keyup)="filter()"
                    [ngModelOptions]="{standalone: true}" placeholder="Search role name or permission" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
                <span nz-icon nzType="search" nzTheme="outline" class="search-extra"></span>
            </ng-template>

        </nz-space>
    </nz-page-header-extra>
</nz-page-header>

<!-- <nz-table
    #roleSelectionTable
    [nzData]="rules"

    class="admin-list-table"
    [nzShowPagination]="true"
> -->
<nz-table 
#roleSelectionTable
[nzData]="rules"
[nzBordered]="true"
[nzShowPagination]="true"
[nzPaginationPosition]="'bottom'"
[nzPageSize]="pageSize"
[nzTotal]="total"
[nzSize]="'middle'"
(nzPageIndexChange)="onPageChange($event)"
(nzPageSizeChange)="onPageSizeChange($event)"
class="admin-list-table"
    >
    <thead>
        <tr>
            <!-- <th
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
            ></th> -->
            <th [nzShowSort]="false">Name</th>
            <th [nzShowSort]="false">Brand</th>
            <th [nzShowSort]="false">Market</th>
            <th [nzShowSort]="false">Channel</th>
            <th [nzShowSort]="false">Default</th>
            <th [nzShowSort]="false">Created By</th>
            <!-- <th [nzShowSort]="false">Created By</th> -->
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td (click)="routeToViewDefault('66f24d691950211f1c5e6ce8')"
                style="cursor: pointer; text-decoration: underline; color: blue;">Default Rule</td>
            <td>All</td>
            <td>All</td>
            <td>All</td>
            <td>Yes</td>
            <td>Incivus</td>
            <td></td>
        </tr>
        <tr *ngFor="let rule of displayedRules">
            <!-- <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td> -->
            <td>{{ capitalizeFirstLetter(rule?.name) }}</td>
            <td>{{ capitalizeFirstLetterJoin(rule?.brandNames.join(', ')) }}</td>
            <td>{{ rule?.market.join(', ') }}</td>
            <td>{{ capitalizeFirstLetterJoin(rule?.channels.join(', ')) }}</td>
            <td>{{ rule?.isDefault ? 'Yes' : 'No' }}</td>
            <td>{{ rule?.createdByName ? rule?.createdByName?.firstName : ''}}</td>
            <td>
                <button nz-button nzNoAnimation nz-tooltip class="action-trigger" [nzTooltipTitle]="menu"
                    nzTooltipOverlayClassName="tableTooltipOverlay" [nzTooltipPlacement]="['bottomRight', 'topRight']">
                    <span nz-icon nzType="more" nzTheme="outline"
                        style="font-size: 0.87rem; vertical-align: top;"></span>
                </button>
                <ng-template #menu>
                    <ul nz-menu>
                        <li nz-menu-item (click)="routeToView(rule?._id, rule?.creativeTypes)">
                            <span nz-icon><img nz-icon src="assets/icons/view-details.svg"></span>
                            <span>View</span>
                        </li>
                        <!-- <li 
                        *ngIf="rule.isDefault == false" 
                        nz-menu-item (click)="toggleDefault(rule)">
                            <img nz-icon src="assets/icons/settings.svg">
                            <span>Mark as default</span>
                        </li> -->
                        <li nz-menu-item (click)="toggleDefault(rule)">
                            <img nz-icon src="assets/icons/settings.svg">
                            <span *ngIf="rule.isDefault == true">Remove as default</span>
                            <span *ngIf="rule.isDefault == false">Mark as default</span>
                        </li>
                    </ul>
                </ng-template>
            </td>
        </tr>
    </tbody>
</nz-table>



<!-- <table class="table text-left admin-list-table">
    <thead>
        <tr>

            <th>Name</th>
            <th>
                Brand
            </th>
            <th>Market</th>
            <th>Channel</th>
            <th>Default</th>
            <th>Show</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rule of rules">
            
            <td>{{ rule.name.toUpperCase() }}</td>
            <td>{{ rule.brandNames.join(', ').toUpperCase() }}</td>
            <td>{{ rule.market.join(', ') }}</td>
            <td>{{ rule.channels.join(', ').toUpperCase() }}</td>
            <td style="cursor:pointer;" (click)="toggleDefault(rule)">{{ rule.isDefault ? 'Yes' : 'No' }}</td>

            <td>
                <a [routerLink]="['/custom-rules/show/'+rule._id + '/DISPLAY']" class="btn btn-primary ms-3 ml-3">
                    Show
                </a>
            </td>
        </tr>
    </tbody>
</table> -->