export const MODALCOMPONENT = {
    CREATIVE_CONFIRMATION:"ADD_CAMPAIGN",
    CREATE_CAMPAIGN:"CREATE_CAMPAIGN",
    LINK_CAMPAIGN:"LINK_CAMPAIGN",
    EDIT_CAMPAIGN:"EDIT_CAMPAIGN",
    CREATE_CAMPAIGN_SUCCESS:"CREATE_CAMPAIGN_SUCCESS",
    SHARE_CAMPAIGN:"SHARE_CAMPAIGN",
    UPLOAD_CREATIVE:"UPLOAD_CREATIVE",
    CREATE_FOLDER:"CREATE_FOLDER",
    SAVE_FOLDER:"SAVE_FOLDER"
}