<form nz-form nzLayout="vertical">
    <nz-card [nzTitle]="edit=='true'?'Edit Campaign':'Create Campaign'">
        <div class="form-description role-description">
            {{edit=="true" ? "Update the following information":"Fill in the following information to create
            campaign."}}
        </div>

        <div class="row-input-container">

            <nz-form-item>
                <nz-form-label class="input-float-label">Campaign Title <span style="color:#EF4444">*</span>
                </nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <input nz-input type="text" name="title" required [(ngModel)]="title" id="title"
                            #titleModel="ngModel" placeholder="Enter Campaign Title " class="rounded-input">
                    </nz-input-group>
                    <div *ngIf="titleModel.invalid && (titleModel.dirty || titleModel.touched)"
                        class="text-danger text-erorshow mt-1" style="text-align: left;">
                        Name is required.
                    </div>
                </nz-form-control>

            </nz-form-item>

            <nz-form-item>
                <nz-form-label class="input-float-label">Select Channel <span style="color:#EF4444">*</span>
                </nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="default">
                        <nz-select [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder" [nzShowArrow]="true"
                            nzSize="default" nzDropdownClassName="custom-check-dropdown" [nzOptionOverflowSize]="8"
                            nzMode="multiple" nzPlaceHolder="Select Channels" [(ngModel)]="selectedChannels"
                            name="channels" #channels="ngModel" required>
                            <ng-container *ngIf="channelsList && channelsList.length > 0">
                                <nz-option *ngFor="let channel of channelsList" [nzValue]="channel.id"
                                    [nzLabel]="channel.title">
                                </nz-option>
                            </ng-container>
                        </nz-select>
                        <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length
                            }}</ng-template>
                    </nz-input-group>
                    <div *ngIf="channels.invalid && (channels.dirty || channels.touched)"
                        class="text-danger text-erorshow mt-1" style="text-align: left;">
                        Channel is required.
                    </div>
                </nz-form-control>

            </nz-form-item>
        </div>
        <div class="row-input-container">

            <nz-form-item>
                <nz-form-label class="input-float-label">Select Brands <span
                        style="color:#EF4444">*</span></nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder" [nzShowArrow]="true"
                            nzSize="default" [nzOptionOverflowSize]="8" nzDropdownClassName="custom-radio-dropdown"
                            nzMode="default" nzPlaceHolder="Select Brand" [(ngModel)]="selectedBrands" name="brands"
                            #brandsModel="ngModel" required>
                            <ng-container *ngIf="brands && brands.length > 0">
                                <nz-option *ngFor="let channel of brands" [nzValue]="channel" [nzLabel]="channel">
                                </nz-option>
                            </ng-container>
                        </nz-select>
                        <ng-template #tagPlaceHolder let-selectedList>+ {{
                            selectedList.length}}</ng-template>
                    </nz-input-group>
                    <div *ngIf="brandsModel.invalid && (brandsModel.dirty || brandsModel.touched)"
                        class="text-danger text-erorshow mt-1" style="text-align: left;">
                        Brand is required.
                    </div>
                </nz-form-control>

            </nz-form-item>

            <nz-form-item>
                <nz-form-label class="input-float-label">Select Budget</nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder" [nzShowArrow]="true"
                            nzSize="default" [nzOptionOverflowSize]="8" nzMode="default" nzPlaceHolder="Select Budget"
                            [(ngModel)]="selectedBuget" nzDropdownClassName="custom-radio-dropdown" name="budget"
                            #channels="ngModel" required>
                            <ng-container *ngIf="budgets && budgets.length > 0">
                                <nz-option *ngFor="let channel of budgets" [nzValue]="channel" [nzLabel]="channel">
                                </nz-option>
                            </ng-container>
                        </nz-select>
                        <ng-template #tagPlaceHolder let-selectedList>+ {{
                            selectedList.length}}</ng-template>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

        </div>
        <div class="row-input-container">

            <nz-form-item>
                <nz-form-label class="input-float-label">Select Objectives <span style="color:#EF4444">*</span>
                </nz-form-label>

                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder" [nzShowArrow]="true"
                            nzSize="default" [nzOptionOverflowSize]="8" nzMode="default"
                            nzPlaceHolder="Select Objectives" nzDropdownClassName="custom-radio-dropdown"
                            [(ngModel)]="selectedObjective" name="objective" #objectiveModel="ngModel" required>
                            <ng-container *ngIf="objectives && objectives.length > 0">
                                <nz-option *ngFor="let channel of objectives" [nzValue]="channel" [nzLabel]="channel">
                                </nz-option>
                            </ng-container>
                        </nz-select>
                        <ng-template #tagPlaceHolder let-selectedList>+ {{
                            selectedList.length}}</ng-template>
                    </nz-input-group>
                    <div *ngIf="objectiveModel.invalid && (objectiveModel.dirty || objectiveModel.touched)"
                        class="text-danger text-erorshow mt-1" style="text-align: left;">
                        Objective is required.
                    </div>
                </nz-form-control>

            </nz-form-item>
            <nz-form-item>
                <nz-form-label class="input-float-label">Status</nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <input nz-input type="text" name="status" [(ngModel)]="status" id="status"
                            placeholder="Enter status" value="Draft" disabled class="rounded-input">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

        </div>
        <div class="row-input-container">

            <nz-form-item>
                <nz-form-label class="input-float-label">Start Date</nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <input nz-input type="date" name="start_date" [(ngModel)]="start_date" id="start_date"
                            placeholder="Enter Start Date" class="rounded-input" [min]="currentDate">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label class="input-float-label">End Date</nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <input nz-input type="date" name="end_date" [(ngModel)]="end_date" id="end_date"
                            placeholder="Enter Start Date" class="rounded-input" [min]="currentDate">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

        </div>
        <div class="row-input-container">
            <nz-form-item>
                <nz-form-label class="input-float-label">Description</nz-form-label>
                <nz-form-control>
                    <nz-input-group nzSearch nzSize="large">
                        <input nz-input type="text" name="description" [(ngModel)]="description" id="description"
                            placeholder="Enter Description" (input)="onInputChange($event)" (paste)="onPaste($event)"
                            (keydown)="onKeyDown($event)" rows="5" class="rounded-input">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </div>
        <!-- {{wordCount}} -->

        <div class="form-footer">
            <div class="form-footer-wrapper">
                <!-- <app-button buttonClass="secondary" label="Cancel" [myCallback]="cancel"></app-button>
                <app-button *ngIf="roleId === 'create'" buttonClass="primary" label="Create role" [myCallback]="save"></app-button>
                <app-button *ngIf="roleId !== 'create'" buttonClass="primary" label="Update role" [myCallback]="update"></app-button> -->


                <button *ngIf="edit==''" (click)="createCampaign()" class="btn btn-primary btnCstm">Submit</button>

                <button *ngIf="edit=='true'" (click)="editCampaign()" class="btn btn-info btnCstm">Update</button>


            </div>
        </div>


        <!-- <div class="form-footer">
            <div class="form-footer-wrapper">
                <app-button buttonClass="secondary" label="Cancel" [myCallback]="cancel"></app-button>
                <app-button *ngIf="roleId === 'create'" buttonClass="primary" label="Create role" [myCallback]="save"></app-button>
                <app-button *ngIf="roleId !== 'create'" buttonClass="primary" label="Update role" [myCallback]="update"></app-button>
            </div>
        </div> -->
    </nz-card>
</form>
<!-- <section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="w-100  card card-body shadow shadow-lg">
                    <div class="w-100 text-left">
                        <p>Fill the following information</p>
                    </div>

                    <div class="w-100 text-left formcapaign">
                        <div class="row mb-4">
                            <div class="col-md-12">
                                <app-alert [message]="respMessage" [alertType]="'info'" *ngIf="respMessage"></app-alert>
                            </div>
                        </div>
                        <div class="row-input-container">

                            <nz-form-item>
                                <nz-form-label class="input-float-label">Campaign Title</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group nzSearch nzSize="large">
                                        <input nz-input type="text" name="title" [(ngModel)]="title" id="title"
                                            placeholder="Enter Campaign Title " class="rounded-input">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label class="input-float-label">Select Channel</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group nzSearch nzSize="default">
                                        <nz-select [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                            [nzShowArrow]="true" nzSize="default" [nzOptionOverflowSize]="8"
                                            nzMode="default" nzPlaceHolder="Select Channels"
                                            [(ngModel)]="selectedChannels" name="channels" #channels="ngModel" required>
                                            <ng-container *ngIf="channelsList && channelsList.length > 0">
                                                <nz-option *ngFor="let channel of channelsList" [nzValue]="channel.id"
                                                    [nzLabel]="channel.title">
                                                </nz-option>
                                            </ng-container>
                                        </nz-select>
                                        <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length
                                            }}</ng-template>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <label for="">Brand</label>
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-input-group nzSearch nzSize="large">
                                            <nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                [nzShowArrow]="true" nzSize="default" [nzOptionOverflowSize]="8"
                                                nzMode="default" nzPlaceHolder="Select Brand"
                                                [(ngModel)]="selectedBrands" name="brands" #channels="ngModel" required>
                                                <ng-container *ngIf="brands && brands.length > 0">
                                                    <nz-option *ngFor="let channel of brands" [nzValue]="channel"
                                                        [nzLabel]="channel">
                                                    </nz-option>
                                                </ng-container>
                                            </nz-select>
                                            <ng-template #tagPlaceHolder let-selectedList>+ {{
                                                selectedList.length}}</ng-template>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-md-6">
                                <label for="">Budget</label>
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-input-group nzSearch nzSize="large">
                                            <nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                [nzShowArrow]="true" nzSize="default" [nzOptionOverflowSize]="8"
                                                nzMode="default" nzPlaceHolder="Select Budget"
                                                [(ngModel)]="selectedBuget" name="budget" #channels="ngModel" required>
                                                <ng-container *ngIf="budgets && budgets.length > 0">
                                                    <nz-option *ngFor="let channel of budgets" [nzValue]="channel"
                                                        [nzLabel]="channel">
                                                    </nz-option>
                                                </ng-container>
                                            </nz-select>
                                            <ng-template #tagPlaceHolder let-selectedList>+ {{
                                                selectedList.length}}</ng-template>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-md-6">
                                <label for="">Objectives</label>
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-input-group nzSearch nzSize="large">
                                            <nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder"
                                                [nzShowArrow]="true" nzSize="default" [nzOptionOverflowSize]="8"
                                                nzMode="default" nzPlaceHolder="Select Objectives"
                                                [(ngModel)]="selectedObjective" name="objective" #channels="ngModel"
                                                required>
                                                <ng-container *ngIf="objectives && objectives.length > 0">
                                                    <nz-option *ngFor="let channel of objectives" [nzValue]="channel"
                                                        [nzLabel]="channel">
                                                    </nz-option>
                                                </ng-container>
                                            </nz-select>
                                            <ng-template #tagPlaceHolder let-selectedList>+ {{
                                                selectedList.length}}</ng-template>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-md-6">
                                <label for="">Status</label>
                                <div class="form-control disabled bg-light readonly">Draft</div>
                            </div>
                            <div class="col-md-6">
                                <label for="">Start Date</label>
                                <input type="date" name="start_date" [(ngModel)]="start_date" id=""
                                    class="form-control">
                            </div>
                            <div class="col-md-6">
                                <label for="">End Date</label>
                                <input type="date" name="end_date" [(ngModel)]="end_date" id="" class="form-control">
                            </div>
                            <div class="col-md-12 mt-4">
                                <label for="">Description</label>
                                <textarea name="" id="" [(ngModel)]="description" class="form-control"
                                    rows="4"></textarea>
                            </div>
                            <div class="col-md-12 mt-2">
                                <button (click)="createCampaign()" class="btn btn-info">Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section> -->