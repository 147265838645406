<div class="card-details-container">
    <div class="card-details-wrapper flex-compliance">
        <div class="card-wrapper">
            <nz-card class="compliance-card" [nzBordered]="false" (click)="open('get-started')">
                <div class="details-card-wrapper">
                    <div class="title">Overview</div>
                    <div class="description">
                        Understanding how Incivus platform can help marketers and advertisers to make quick decisions on which Ad to launch for achieving their marketing goals and objectives.
                    </div>
                </div>
            </nz-card>
            <nz-card class="compliance-card" [nzBordered]="false" (click)="open('creative')">
                <div class="details-card-wrapper">
                    <div class="title"> In-flight Testing</div>
                    <div class="description">
                        Test in-flight creatives and understand how Incivus tracks your organization’s in-flight data and helps you make informed decisions on the fly.                   
                    </div>
                </div>
            </nz-card>
            <nz-card class="compliance-card" [nzBordered]="false" (click)="open('support')">
                <div class="details-card-wrapper">
                    <div class="title">Pre-flight Testing</div>
                    <div class="description">
                        Test all your creatives during production and make informed decisions before launching them.
                    </div>
                </div>
            </nz-card>
            <nz-card class="compliance-card" [nzBordered]="false" (click)="open('account')">
                <div class="details-card-wrapper">
                    <div class="title">Account setup</div>
                    <div class="description">
                        Incivus is a self-serviceable platform. Understand how you can setup your Brand guidelines, users, permissions and roles to seamlessly manage your account.
                    </div>
                </div>
            </nz-card>
            <nz-card class="compliance-card" [nzBordered]="false" (click)="open('guidelines')">
                <div class="details-card-wrapper">
                    <div class="title">Guidelines and Scores</div>
                    <div class="description">
                        Understanding how incivus standard guidelines and your custom guidelines can be used for analysing creatives.
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</div>