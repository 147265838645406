<form nz-form nzLayout ="vertical" [formGroup]="uploadCreativeForm" *ngIf="!breadcrumb">
<nz-form-item>
<nz-form-label nzFor="creativeName" class="input-float-label">
    Creative name
  </nz-form-label>
   <nz-form-control style="text-align: left;" >
    <nz-input-group nzSearch nzSize="large">
       <input nz-input type="text" class="rounded-input" placeholder="Creative name" id="creativeName"  formControlName="creativeName" (Onchange)="getFormData('false')">
    </nz-input-group>
  </nz-form-control> 
</nz-form-item>
<nz-form-item>
    <nz-form-label  class="input-float-label">Select Channel <span style="color:#EF4444">*</span>
    </nz-form-label>
    <nz-form-control>
        <nz-input-group nzSearch nzSize="default">
            <nz-select  formControlName="channelsList" [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder" [nzShowArrow]="true"
                nzSize="default" 
                nzDropdownClassName="custom-check-dropdown"
                [nzOptionOverflowSize]="8" nzMode="multiple" nzPlaceHolder="Select Channels"
                [(ngModel)]="selectedChannels" name="channels" required>
                <ng-container *ngIf="channelsList && channelsList.length > 0">
                    <nz-option *ngFor="let channel of channelsList" [nzValue]="channel"
                        [nzLabel]="channel">
                    </nz-option>
                </ng-container>
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length
                }}</ng-template>
        </nz-input-group>
        
    </nz-form-control>

</nz-form-item>
</form>
<div class="upload-wrapper">
    <nz-upload [nzAccept]="fileAccept" [nzBeforeUpload]="beforeUploadLogo" nzMultiple="false" [nzShowUploadList]="uploadInfo">
        <button nz-button>
            <div class="button-content" *ngIf="!logoListPreview">
                <span>Drag & Drop here</span>
                <span>OR</span>
                <span class="browse-file">Browse files</span>
                <span class="browse-note">Note: We support only English language</span>
            </div>
            <div class="image-preview" *ngIf="logoListPreview">
                <img [src]=sanitize(logoListPreview) style="width: 100%; max-height: 9rem;" *ngIf="fileType === 'image' || type === 'image'"/>
                <video *ngIf="fileType === 'video' || type === 'video'" style="width: 100%; max-height: 9rem;">
                    <source [src]="sanitize(logoListPreview)" id="source_video"/>
                </video>
            </div>
        </button>
    </nz-upload>
    <label class="upload-label error" *ngIf="errorMsg">{{errorMsg}}</label>
    <!-- <label class="upload-label" style="width: max-content;margin-top: 1rem;">
        Aspect Ratio: 16:9, Resolution: 1080 X 1080, File size: Less than 1 GB, File types: 
        <span>.png .jpg .jpeg .mp4 .mov .avi</span>
    </label> -->

    <label class="upload-label" *ngIf="fileType === 'image'">Accepted files types : <span>.png, .jpg, .jpeg</span></label>
    <label class="upload-label" *ngIf="fileType === 'video'">Accepted files types : <span>.mp4, .avi, .wmv, .ogg, .webm, .mov</span></label>
    <label class="upload-label" *ngIf="fileType === 'any'">Accepted files types : <span>.mp4,.avi,.wmv,.ogg,.webm,.mov,.jpg, .jpeg, .png</span></label>
</div>